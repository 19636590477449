import {useCallback} from "react";
import {DataAccess} from "../../util";

export const useCompetitionDelete = () => {

    const deleteCompetition = useCallback(async (competitionId: number) => {
        await DataAccess.delete(`/api/competition/${competitionId}/delete.json`);
    }, []);

    return {
        deleteCompetition: deleteCompetition
    }
}