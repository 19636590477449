import {useState, useCallback, ChangeEvent} from "react";
import {DataAccess, DataValidation} from "../../util";
import {InstitutionChangeState} from "../../data-types";

export const useChangeInstitution = () => {

    const [institutionState, setInstitutionState] = useState<InstitutionChangeState>({
        email: "",
        institutionId: -1
    });

    const assignInstitution = useCallback(async (institutionState: InstitutionChangeState) => {
        if (!DataValidation.isValidEmailAddress(institutionState.email)) {
            throw new Error("Invalid email address.");
        }
        if (institutionState.institutionId === -1) {
            throw new Error("Please select an institution.");
        }
        await DataAccess.put("/api/user/updateUserInstitution.json", {data: institutionState});
        setInstitutionState({email: "", institutionId: -1});
    }, []);

    const handleInstitutionChange = (e: ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;
        setInstitutionState(prev =>  {
            return {
                ...prev,
                institutionId: Number.parseInt(value)
            }
        })
    }

    const handleAssignedEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;
        setInstitutionState(prev =>  {
            return {
                ...prev,
                email: value
            }
        })
    }

    return {
        institutionState: institutionState,
        handleInstitutionChange: handleInstitutionChange,
        handleAssignedEmailChange: handleAssignedEmailChange,
        assignInstitution: assignInstitution
    }
}