import {useState, useCallback, ChangeEvent} from "react";
import {DataAccess} from "../../util";

export const useScriptCopy = () => {

    const [newScriptName, setNewScriptName] = useState<string>("");

    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setNewScriptName(e.target.value);
    }, []);

    const submitCopy = useCallback(async (newScriptName: string, scriptId: number) => {
        const payload = {newScriptName: newScriptName};
        await DataAccess.post(`/api/script/${scriptId}/copy.json`, {data: payload});
    }, []);

    const setDefaultName = useCallback((defaultName: string) => {
        setNewScriptName(defaultName);
    }, []);

    return {
        newScriptName: newScriptName,
        handleChangeCopy: handleChange,
        submitCopy: submitCopy,
        setDefaultCopyName: setDefaultName
    }
}