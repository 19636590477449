import React from "react";
import {Container, Divider, Typography} from "@mui/material";
import {usePagination} from "../../hooks";
import {Video} from "../../data-types";
import {VideoLibrary} from "../../components";

export function Responses() {

    const {
        currentPageNumber,
        pages,
        totalRecords,
        changePage,
        loading
    } = usePagination<Video>("/api/response/PAGE_NUMBER/getMyResponses.json");

    return (
        <Container sx={{mb: 10}}>
            <Typography variant="h4">
                Responses
            </Typography>
            <Divider sx={{mb: 5, mt: 5}} variant="middle" />
            <VideoLibrary
                videosPerPage={6}
                totalVideos={totalRecords}
                pageNumber={currentPageNumber}
                videos={pages[currentPageNumber]}
                loading={loading}
                changePage={changePage}
            />
        </Container>
    )
}