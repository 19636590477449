import React, {useState, useEffect} from "react";
import {Competition, RequestStatus} from "../../data-types";
import {DataAccess} from "../../util";
import {parseISO, isAfter} from "date-fns";

type EventTab = "active" | "complete";

export const useMyEvents = () => {

    const [requestStatus, setRequestStatus] = useState<RequestStatus>("loading");
    const [activeEvents, setActiveEvents] = useState<Competition[]>([]);
    const [completedEvents, setCompletedEvents] = useState<Competition[]>([]);
    const [tab, setTab] = useState<EventTab>("active");

    useEffect(() => {
        const activeController = new AbortController();
        const completedController = new AbortController();

        const getActive = async () => {
            const r = await DataAccess.get("/api/competition/listUser.json", {signal: activeController.signal});
            const competitions: Competition[] = r.competitions;
            competitions.sort((i, j) => {
                const iMeta = i.competitionMeta!;
                const jMeta = j.competitionMeta!;

                const iRoundSubmitted = i.roundStatus?.roundSubmitted;
                const jRoundSubmitted = j.roundStatus?.roundSubmitted;

                // if one is eliminated, sort it last
                if (i.participantStatus && i.participantStatus.eliminated) {
                    return 1;
                }
                if (j.participantStatus && j.participantStatus.eliminated) {
                    return -1;
                }

                const {activeTime: iActive, inactiveTime: iInactive} = iMeta;
                const {activeTime: jActive, inactiveTime: jInactive} = jMeta;
                if (iActive || jActive) { // at least 1 defined
                    // if both have activeTime, then check if either has roundSubmitted.
                    // if either does, then sort it last
                    if (iActive && jActive) {
                        if (iRoundSubmitted) {
                            return 1;
                        }
                        if (jRoundSubmitted) {
                            return -1;
                        }
                    }
                    return (jActive ?? -Infinity) - (iActive ?? -Infinity);
                } else {
                    return (jInactive ?? -Infinity) - (iInactive ?? -Infinity);
                }
            })
            return competitions;
        }
        const getCompleted = async () => {
            const r = await DataAccess.get("/api/competition/listCompletedUser.json", {signal: completedController.signal});
            const competitions: Competition[] = r.competitions;
            competitions.sort((i, j) => {
                const iEnd = i.competitionMeta?.endDate;
                const jEnd = j.competitionMeta?.endDate;
                if (iEnd && jEnd) {
                    const iDate = parseISO(iEnd + "Z");
                    const jDate = parseISO(jEnd + "Z");
                    return isAfter(iDate, jDate) ? -1 : 1;
                } else {
                    if (iEnd) {
                        return -1;
                    } else if (jEnd) {
                        return 1;
                    } else {
                        return 0;
                    }
                }

            })
            return competitions;
        }

        const getAll = async () => {
            return await Promise.all([getActive(), getCompleted()]);
        }

        getAll()
            .then(([active, completed]) => {
                if (active.length === 0) {
                    setTab("complete");
                }
                setActiveEvents(active);
                setCompletedEvents(completed)
            })
            .then(_ => setRequestStatus("complete"))
            .catch(e => {
                console.log(e);
                setRequestStatus("error");
            })

        return () => {
            activeController.abort();
            completedController.abort();
        }

    }, []);

    const handleTabChange = (_: React.SyntheticEvent, newValue: string) => {
        setTab(newValue as EventTab);
    }

    const removeEvent = (competitionId: number) => {
        setActiveEvents(prev => prev.filter(c => c.competitionMeta?.competitionId !== competitionId));
    }

    return {
        requestStatus: requestStatus,
        activeEvents: activeEvents,
        completedEvents: completedEvents,
        tab: tab,
        handleTabChange: handleTabChange,
        removeEvent: removeEvent
    }
}
