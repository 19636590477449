import {useState, useEffect} from "react";
import {RequestStatus, RoundDetail} from "../../data-types";
import {DataAccess} from "../../util";
export const useRoundDetail = (roundId: string | undefined) => {

    const [requestStatus, setRequestStatus] = useState<RequestStatus>("loading");
    const [roundDetail, setRoundDetail] = useState<RoundDetail>({
        competitionName: "",
        roundNumber: -1,
        scriptId: -1,
        scriptName: "",
        numberVideos: 0,
        size: "",
        status: "standby",
        completedResponses: 0,
        dueResponses: 0,
        savedResponses: 0,
        prompts: [],
        eliminationRate: 0,
        heatSize: 6,
        responseStartDate: "",
        responseEndDate: "",
        reviewStartDate: "",
        reviewEndDate: "",
        responseReminderDays: 0,
        reviewReminderDays: 0,
        hasProxy: false,
        attributes: [],
        hasStandardAttributes: false,
        allowReduceHeatSize: true
    });

    useEffect(() => {

        const getRoundDetail = async () => {
            let r = await DataAccess.get(`/api/round/${roundId}/getDetail.json`);
            setRoundDetail(r.roundDetail);
        }

        getRoundDetail()
            .then(_ => {
                setRequestStatus("complete");
            })
            .catch(e => {
                setRequestStatus("error");
                console.log(e);
            })

    }, [roundId]);

    return {
        roundDetail: roundDetail,
        requestStatus: requestStatus
    }
}