import React from "react";
import {Paper, Grid2 as Grid, Typography} from "@mui/material";
import {ToDoTimelineProps} from "../../data-types";
import {parseISO, addDays, addMonths} from "date-fns";
import {ToDoItem} from "./ToDoItem";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export function WorklistTimeline({todos}: ToDoTimelineProps) {

    let now = new Date();
    let plusWeek = addDays(now, 7);
    let plusMonth = addMonths(now, 1);

    const dueInWeek = todos.filter(c => parseISO(c.dueDate + 'Z') <= plusWeek);
    const dueInMonth = todos.filter(c => parseISO(c.dueDate + 'Z') <= plusMonth);
    const dueLater = todos.filter(c => parseISO(c.dueDate + 'Z') > plusMonth);

    const nothingDue = (message: string) =>
        <Grid component={Paper} elevation={2} size={{xs: 12}} sx={{textAlign: "center", mb: 3, p: 5}}>
            <CheckCircleOutlineIcon />
            <Typography variant="body1">
                {message}
            </Typography>
        </Grid>;

    return (
        <Grid container>
            <Grid size={{xs: 12}}>
                <Typography variant="h6" sx={{pl: 2, mb: 3}}>
                    Due in 7 days
                </Typography>
            </Grid>
            {(dueInWeek.length === 0) ?
                nothingDue("Nothing due in one week") :
                dueInWeek.map((c, i) => <ToDoItem key={i} todo={c} />)
            }
            <Grid size={{xs: 12}}>
                <Typography variant="h6" sx={{pl: 2, mb: 3}}>
                    Due in one month
                </Typography>
            </Grid>
            {(dueInMonth.length === 0) ?
                nothingDue("Nothing due in one month") :
                dueInMonth.map((c, i) => <ToDoItem key={i} todo={c} />)
            }
            <Grid size={{xs: 12}}>
                <Typography variant="h6" sx={{pl: 2, mb: 3}}>
                    Due later
                </Typography>
            </Grid>
            {(dueLater.length === 0) ?
                nothingDue("Nothing due later") :
                dueLater.map((c, i) => <ToDoItem key={i} todo={c} />)
            }
        </Grid>
    )
}