import React, {useState} from "react";
import {Grid2 as Grid, IconButton, Typography} from "@mui/material";
import {usePromotions} from "../../hooks";
import {PromotionBanner} from "./PromotionBanner";
import CancelIcon from "@mui/icons-material/Cancel";
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

export function Promotions() {

    const [selectedPromotionIndex, setSelectedPromotionIndex] = useState<number>(0);

    const {
        requestStatus,
        promotions,
        removePromotion
    } = usePromotions();

    const handleChangePromotion = (delta: number) => {
        const numberPromotions = promotions.length;
        setSelectedPromotionIndex(prev => {
            const idx = (prev + delta) % numberPromotions;
            return idx < 0 ? idx + numberPromotions : idx;
        });
    }

    const handleDismissPromotion = () => {
        const numberPromotions = promotions.length;
        if (numberPromotions > 1) {
            const newIndex = (numberPromotions - 1 === selectedPromotionIndex) ? selectedPromotionIndex - 1 : selectedPromotionIndex;
            removePromotion(selectedPromotionIndex);
            setSelectedPromotionIndex(newIndex);
        } else {
            removePromotion(0);
        }
    }

    if (requestStatus === "complete" && promotions.length > 0) {
        return  (
            <Grid container columnSpacing={2} rowSpacing={0} alignItems="center" sx={{px: 5, pb: 3, background: "#EEEEEE"}}>
                <Grid size={{xs: 12}} sx={{textAlign: "right"}}>
                    <IconButton
                        onClick={handleDismissPromotion}
                    >
                        <CancelIcon sx={{color: "#222222"}}/>
                    </IconButton>
                </Grid>
                <PromotionBanner promotion={promotions[selectedPromotionIndex]} />
                {promotions.length > 1 ?
                    <Grid container size={{xs: 12}} justifyContent="center" alignItems="center" sx={{color: "#222222"}}>
                        <Grid>
                            <IconButton onClick={() => handleChangePromotion(-1)}>
                                <KeyboardArrowLeft sx={{color: "#222222"}} />
                            </IconButton>
                        </Grid>
                        <Grid>
                            <Typography variant="subtitle2">
                                {`${selectedPromotionIndex + 1} / ${promotions.length}`}
                            </Typography>
                        </Grid>
                        <Grid>
                            <IconButton onClick={() => handleChangePromotion(1)}>
                                <KeyboardArrowRight sx={{color: "#222222"}} />
                            </IconButton>
                        </Grid>
                    </Grid> :
                    null
                }
            </Grid>
        )
    } else {
        return null;
    }
}