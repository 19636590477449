import React from "react";
import {EditDiscreteBinsProps} from "../../data-types";
import { Box, FormControl, FormGroup, FormControlLabel, Checkbox } from "@mui/material";

export function EditDiscreteBins({bins, changeDiscreteBin, active}: EditDiscreteBinsProps) {

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, checked} = e.target;
        changeDiscreteBin(name, checked);
    }

    return (
        <Box sx={{display: "flex", flexDirection: "column", alignItems: "center"}} component="div">
            <FormControl sx={{}} component="fieldset" variant="standard">
                <FormGroup>
                    {bins.map((c, i) =>
                        <FormControlLabel
                            key={i}
                            control={<Checkbox checked={c.checked} onChange={handleChange} name={c.name} disabled={!active} />}
                            label={c.name}
                        />
                    )}
                </FormGroup>
            </FormControl>
        </Box>
    )
}
