import React from "react";
import {Box, Paper, Button, Grid2 as Grid, Badge} from "@mui/material";
import {ToggleButtonData} from "./VideoReviewConfig";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {MovieLabeledIcon, PersonLabeledIcon} from "../../icons/labeled-icons";

interface ToggleButtonProps {
    label: string;
    data: ToggleButtonData;
    toggleHandler: (id: number) => Promise<void>;
    isSelected: boolean;
}

export function ToggleButton({data, toggleHandler, isSelected, label}: ToggleButtonProps) {

    return (
        <Grid>
            <Badge badgeContent={" "} variant="dot" invisible={!data.hasModified} color="warning">
                <Paper
                    elevation={1}
                    component={Button}
                    onClick={() => toggleHandler(data.id)}
                    sx={isSelected ? {border: "2px solid green", p: 1} : {border: "2px solid rgba(0, 0, 0, 0.0)", p: 1}}
                >
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                        spacing={2}
                        sx={{p: 1}}
                    >
                        <Grid>
                            <Box component="div" sx={{display: "flex", alignItems: "center"}}>
                                {isNaN(parseInt(label)) ? <PersonLabeledIcon label={label} accessibilityText={"Responder " + label}/> : <MovieLabeledIcon label={label} accessibilityText={"Prompt " + label}/>}
                                {(data.requiredCount - data.savedCount === 0) ?
                                    <CheckCircleIcon color="primary" fontSize="small"  sx={{ml: 3}} />:
                                    null
                                }
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Badge>
        </Grid>
    )
}