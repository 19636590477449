import React, {useState, useCallback, useEffect, useRef, ChangeEvent} from "react";
import {BodySegmenter, createSegmenter, drawBokehEffect, SupportedModels} from "@tensorflow-models/body-segmentation";
import {useMediaStream, useVirtualBackground} from "../../hooks";
import {Box, Button, ButtonGroup, CircularProgress, Grid2 as Grid, Paper, Typography, Popover} from "@mui/material";
import {VERSION} from "@mediapipe/selfie_segmentation";
import StopIcon from "@mui/icons-material/Stop";
import VideocamIcon from "@mui/icons-material/Videocam";
import ReplayIcon from "@mui/icons-material/Replay";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {toast} from "react-toastify";
import {DeviceSelect} from "./DeviceSelect";
import {WebcamError} from "./WebcamError";

interface WebcamProps {
    startRecording: (stream:  MediaStream) => void;
    stopRecording: () => void;
    resetRecording: () => void;
    isRecording: boolean;
    isDataAvailable: boolean;
}

export function Webcam({startRecording, stopRecording, resetRecording, isRecording, isDataAvailable}: WebcamProps) {

    const {
        stream,
        streamStatus,
        audioDevices,
        videoDevices,
        selectedAudioDevice,
        selectedVideoDevice,
        changeDevice
    } = useMediaStream();

    const {
        backgrounds
    } = useVirtualBackground();

    const [backgroundPopoverAnchor, setBackgroundPopoverAnchor] = useState<HTMLButtonElement | null>(null);
    const [playbackHidden, setPlaybackHidden] = useState<boolean>(false);

    const videoRef = useRef<HTMLVideoElement | null>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const canvasStreamRef = useRef<MediaStream | null>(null);
    const segmenter = useRef<BodySegmenter | null>(null);
    const animationFrame = useRef<number>(NaN);
    const imageRef = useRef<HTMLImageElement | null>(null);
    const uploadedImageURL = useRef<string>("");
    const mirrorOn = useRef<boolean>(false);

    const handleBackgroundSelectClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setBackgroundPopoverAnchor(event.currentTarget);
    };

    const handleBackgroundSelectClose = () => {
        setBackgroundPopoverAnchor(null);
    };

    const noMask = useCallback(() => {
        if (canvasRef.current && videoRef.current) {
            const ctx = canvasRef.current.getContext("2d");
            ctx?.save();
            if (mirrorOn.current) {
                ctx?.translate(canvasRef.current.width, 0);
                ctx?.scale(-1, 1);
            }
            ctx?.drawImage(videoRef.current, 0, 0);
            ctx?.restore();
            animationFrame.current = requestAnimationFrame(noMask);
        }
    }, []);

    const bokehMask = useCallback(() => {
        segmenter.current!.segmentPeople(videoRef.current!)
            .then(segmentation => {
                return drawBokehEffect(
                    canvasRef.current!,
                    videoRef.current!,
                    segmentation,
                    0.5,
                    10,
                    5,
                    mirrorOn.current
                );
            })
            .then(_ => {
                animationFrame.current = requestAnimationFrame(bokehMask);
            })
            .catch(e => console.log(e));
    }, []);

    const imageMask = useCallback(() => {
        segmenter.current!.segmentPeople(videoRef.current!)
            .then(segmentation => {
                return segmentation[0].mask.toCanvasImageSource();
            })
            .then(maskedImage => {
                const ctx = canvasRef.current!.getContext("2d");
                if (ctx) {
                    ctx.save();
                    if (mirrorOn.current) {
                        ctx.save();
                        ctx.translate(canvasRef.current!.width, 0);
                        ctx.scale(-1, 1);
                    }
                    ctx.drawImage(videoRef.current!, 0, 0);
                    ctx.globalCompositeOperation = "destination-in";
                    ctx.drawImage(maskedImage, 0, 0);
                    if (mirrorOn.current) {
                        ctx.restore();
                    }
                    ctx.globalCompositeOperation = "destination-over";
                    ctx.drawImage(imageRef.current!, 0, 0);
                    ctx.restore();
                    animationFrame.current = requestAnimationFrame(imageMask);
                }
            })
            .catch(e => console.log(e));
    }, []);

    useEffect(() => {

        // react will warn that ref will have changed unless assigned to local variable
        // in the useEffect.  https://stackoverflow.com/questions/67069827/cleanup-ref-issues-in-react
        const video = videoRef.current;
        const canvas = canvasRef.current;

        if (stream !== null && video !== null && canvas !== null) {
            video.srcObject = stream;
            // get width and height of video track, assign to canvas
            const {width, height} = stream.getVideoTracks()[0].getSettings();
            if (width && height) {
                canvas.width = width;
                canvas.height = height;
            }
            video.play()
                .then(_ => {
                    animationFrame.current = requestAnimationFrame(noMask)
                })
                .catch(e => console.log(e));
        }

        return () => {
            video?.pause();
            cancelAnimationFrame(animationFrame.current);
        }
    }, [stream, noMask]);

    useEffect(() => {
        const canvasStream = canvasStreamRef.current;
        const uploadedURL = uploadedImageURL.current;

        return () => {
            if (canvasStream) {
                const tracks = canvasStream.getTracks() || [];
                for (const track of tracks) {
                    track.stop();
                    canvasStream.removeTrack(track);
                }
            }
            URL.revokeObjectURL(uploadedURL);
        }
    }, []);

    const imageOn = async (image: string) => {

        // first, stop video and canvas animation
        videoRef.current?.pause();
        cancelAnimationFrame(animationFrame.current);

        // next, get segmenter if it hasn't been set up previously
        if (segmenter.current === null) {
            segmenter.current = await createSegmenter(SupportedModels.MediaPipeSelfieSegmentation,
                {
                    runtime: "mediapipe",
                    solutionPath: `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation@${VERSION}`
                })
        }

        imageRef.current = new Image();
        imageRef.current!.src = image;
        imageRef.current.onload = () => {
            if (videoRef.current) {
                videoRef.current.play()
                    .then(_ => {
                        animationFrame.current = requestAnimationFrame(imageMask);
                    })
            }
        }

        setBackgroundPopoverAnchor(null);
    };

    const handleCustomImage = (e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            URL.revokeObjectURL(uploadedImageURL.current);
            uploadedImageURL.current = URL.createObjectURL(files[0]);
            imageOn(uploadedImageURL.current);
        }
    }

    const blurOn = async () => {
        // first, stop video and canvas animation
        videoRef.current?.pause();
        cancelAnimationFrame(animationFrame.current);

        // next, get segmenter if it hasn't been set up previously
        if (segmenter.current === null) {
            segmenter.current = await createSegmenter(SupportedModels.MediaPipeSelfieSegmentation,
                {
                    runtime: "mediapipe",
                    solutionPath: `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation@${VERSION}`
                })
        }

        // finally, update interval and animation loop
        if (videoRef.current) {
            await videoRef.current.play();
            animationFrame.current = requestAnimationFrame(bokehMask);
        }
    }

    const segmenterOff = async () => {
        videoRef.current?.pause();
        cancelAnimationFrame(animationFrame.current);

        if (videoRef.current) {
            await videoRef.current.play();
            animationFrame.current = requestAnimationFrame(noMask);
        }
    }

    const toggleMirror = () => {
        mirrorOn.current = !(mirrorOn.current);
    }

    const beginRecording = () => {
        if (canvasRef.current) {
            canvasRef.current.getContext("2d");
            const canvasStream = canvasRef.current.captureStream();
            const audioTracks = stream?.getAudioTracks() || [];
            audioTracks.forEach(t => canvasStream.addTrack(t));
            canvasStreamRef.current = canvasStream;
            startRecording(canvasStream);
        } else {
            toast.error("Recording unavailable, please refresh and try again.");
        }
    }

    return (
        <Box component="div">
            {streamStatus === "loading" ?
                <Paper elevation={3} component={Box} sx={{p: 5, display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <Typography variant="h6">
                        Loading Webcam and Microphone...
                    </Typography>
                    <Box sx={{my: 3, textAlign: "center"}} component="div">
                        <CircularProgress color="primary" />
                    </Box>
                    <Typography variant="subtitle2" sx={{textAlign: "left"}} >
                        If this is your first time using FocusRing, then you need to click "Allow" your browser to use both your webcam AND Microphone.  Please note it may take a few moments for your browser to prompt you for permissions.
                    </Typography>
                </Paper> :
                streamStatus === "error" ?
                    <WebcamError /> :
                    <>
                        <Grid container spacing={1}>
                            <Grid size={{xs: 12}}>
                                <video
                                    ref={videoRef}
                                    muted
                                    hidden
                                    playsInline
                                />
                                <canvas ref={canvasRef} style={playbackHidden ? {display: "none"} : {width: "100%"}} height={480} width={640}/>
                                {playbackHidden ?
                                    <Paper elevation={3} component={Box} sx={{px: 5, py: 10, mb: 3, display: "flex", flexDirection: "column", alignItems: "center", width: "100%"}}>
                                        <Typography variant="h6">
                                            Playback is hidden.
                                        </Typography>
                                    </Paper> :
                                    null
                                }
                            </Grid>
                            <Grid size={{xs: 12}} sx={{textAlign: "center", mb: 3}}>
                                <ButtonGroup variant="outlined">
                                    <Button
                                        size="small"
                                        onClick={blurOn}
                                    >
                                        Blur On
                                    </Button>
                                    <Button
                                        size="small"
                                        onClick={handleBackgroundSelectClick}
                                        endIcon={<ArrowDropDownIcon />}
                                    >
                                        Virtual Background
                                    </Button>
                                    <Button
                                        size="small"
                                        onClick={segmenterOff}
                                    >
                                        Effects Off
                                    </Button>
                                    <Button
                                        size="small"
                                        onClick={() => setPlaybackHidden(prev => !prev)}
                                    >
                                        {playbackHidden ? "Show Playback" : "Hide Playback"}
                                    </Button>
                                    <Button
                                        size="small"
                                        onClick={toggleMirror}
                                    >
                                        Mirror
                                    </Button>
                                </ButtonGroup>
                            </Grid>
                            <Grid size={{xs: 12}}>
                                {isRecording ?
                                    <Button
                                        onClick={stopRecording}
                                        disabled={!isRecording}
                                        color="secondary"
                                        variant="contained"
                                        startIcon={<StopIcon />}
                                        sx={{width: "50%"}}
                                    >
                                        Stop Recording
                                    </Button> :
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        startIcon={<VideocamIcon />}
                                        sx={{width: "50%"}}
                                        onClick={beginRecording}
                                        disabled={isDataAvailable || isRecording}
                                    >
                                        Start Recording
                                    </Button>
                                }
                                <Button
                                    onClick={resetRecording}
                                    disabled={!isDataAvailable || isRecording}
                                    color="secondary"
                                    variant="contained"
                                    startIcon={<ReplayIcon />}
                                    sx={{width: "50%"}}
                                >
                                    Reset Recording
                                </Button>
                            </Grid>
                            <Grid size={{xs: 12}}>
                                <DeviceSelect
                                    audioDevices={audioDevices}
                                    videoDevices={videoDevices}
                                    selectedAudioDevice={selectedAudioDevice}
                                    selectedVideoDevice={selectedVideoDevice}
                                    changeDevice={changeDevice}
                                    disabled={isRecording}
                                />
                            </Grid>
                        </Grid>
                    </>
            }
            <Popover
                open={Boolean(backgroundPopoverAnchor)}
                onClose={handleBackgroundSelectClose}
                anchorEl={backgroundPopoverAnchor}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Grid container spacing={1}>
                    {backgrounds.map((c, i) =>
                        <Grid key={i}>
                            <Button onClick={async () => {
                                await imageOn(c);
                            }}>
                                <img src={c} alt={c} width={100} height={75} />
                            </Button>
                        </Grid>
                    )}
                    <Grid>
                        <Button
                            component="label"
                            tabIndex={-1}
                            sx={{height: "100%"}}
                        >
                            Use your own
                            <input
                                type="file"
                                accept=".jpeg,.jpg"
                                onChange={handleCustomImage}
                                style={{
                                    clip: 'rect(0 0 0 0)',
                                    clipPath: 'inset(50%)',
                                    height: 1,
                                    overflow: 'hidden',
                                    position: 'absolute',
                                    bottom: 0,
                                    left: 0,
                                    whiteSpace: 'nowrap',
                                    width: 1,
                                }}
                            />
                        </Button>
                    </Grid>
                </Grid>
            </Popover>
        </Box>
    )
}