import React from 'react';
import {Grid2 as Grid, Paper, Typography, Accordion, AccordionSummary, AccordionDetails} from "@mui/material";
import {UserScoreData} from "../../data-types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface ReviewsByPromptProps {
    userScoreData: UserScoreData | undefined;
}

export function ReviewsByPrompt({userScoreData}: ReviewsByPromptProps) {

    const responseToReviewMap: Map<number, number[]> = new Map();

    userScoreData?.reviewStatistics.forEach(c => {
        const responseId = c.responseId;
        const reviews = responseToReviewMap.get(responseId) || [];
        responseToReviewMap.set(responseId, [...reviews, c.raw]);
    })

    return (
        <Grid container>
            <Grid size={{xs: 12}}>
                {Array.from(responseToReviewMap.values()).map((c, i) =>
                    <Accordion key={i} elevation={0}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            {`Response ${i + 1} Scores`}
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={1}>
                                {c.map((cc, ii) =>
                                    <Grid key={ii}>
                                        <Paper elevation={1} sx={{textAlign: "center", p: 2}}>
                                            <Typography variant="subtitle2">
                                                {parseFloat(cc.toFixed(2))}
                                            </Typography>
                                        </Paper>
                                    </Grid>
                                )}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                )}
            </Grid>
        </Grid>
    )
}