import React from "react";
import {ModalProps} from "../../data-types";
import {Box, Button, Grid2 as Grid, Modal, Typography} from "@mui/material";

export function DeactivateAccountModal({open, setOpen, action}: ModalProps) {

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {xs: "100%", sm: "100%", md: "40%"},
        bgcolor: 'background.paper',
        border: '2px solid #000',
        maxHeight: 600,
        overflowY: "auto",
        boxShadow: 24,
        p: 4,
    };

    return (
        <Modal
            open={open}
        >
            <Box component="div" sx={style}>
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                    <Grid size={{xs: 12}}>
                        <Typography variant="h4">
                            Confirm Deactivation
                        </Typography>
                    </Grid>
                    <Grid size={{xs: 12}}>
                        <Typography variant="h5" sx={{mb: 1}}>
                            Are you sure you want to do this?
                        </Typography>
                        <Typography variant="subtitle2">
                            Remember, you will be disqualified from any current events if you proceed.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center" justifyContent="space-evenly" sx={{mt: 3}}>
                    <Grid size={{xs: 12, sm: 12, md: 6}} sx={{textAlign: "center"}}>
                        <Button
                            color={"secondary"}
                            variant="contained"
                            onClick={() => {
                                action();
                            }}
                        >
                            Yes, Deactivate Now
                        </Button>
                    </Grid>
                    <Grid size={{xs: 12, sm: 12, md: 6}} sx={{textAlign: "center"}}>
                        <Button
                            color={"secondary"}
                            variant="outlined"
                            onClick={() => setOpen(false)}
                        >
                            No, Back to Safety
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}