import { createTheme, responsiveFontSizes } from "@mui/material";

//  Standout Green:  #2B871D
//  CollegeNet Blue:  #1E51A6

export const FocusRingTheme = responsiveFontSizes(createTheme({
    // colorSchemes: {
    //     dark: {
    //         palette: {
    //             primary: {
    //                 main: "#28871D",
    //                 light: "#52874c",
    //                 dark: "#206418"
    //             },
    //             secondary: {
    //                 main: "#EB0052"
    //             },
    //             info: {
    //                 main: "#dddddd"
    //             },
    //             background: {
    //                 default: "#202020",
    //                 paper:  "#252525"
    //             },
    //             success:  { // null slider color
    //                 main: "#a3c49f"
    //             }
    //         }
    //     },
    //     light: {
    //         palette: {
    //             primary: {
    //                 main: "#28871D",
    //                 light: "#52874c",
    //                 dark: "#206418"
    //             },
    //             secondary: {
    //                 main: "#EB0052"
    //             },
    //             info: {
    //                 main: "#dddddd"
    //             },
    //             background: {
    //                 default: "#F7F7F7",
    //                 paper: "#FFFFFF"
    //             },
    //             success:  { // null slider color
    //                 main: "#a3c49f"
    //             }
    //         }
    //     }
    // },

    palette: {
        mode: "dark",
        primary: {
            main: "#28871D",
            light: "#52874c",
            dark: "#206418"
        },
        secondary: {
            main: "#EB0052"
        },
        info: {
            main: "#dddddd"
        },
        background: {
            default: "#202020",
            paper:  "#252525"
        },
        success:  { // null slider color
            main: "#a3c49f"
        }
    },

    typography: {
        "fontFamily": `"Helvetica Neue","Helvetica","Arial","sans-serif"`,
        "fontSize": 14,
        "fontWeightLight": 300,
        "fontWeightRegular": 400,
        "fontWeightMedium": 500,
        button: {
            textTransform: 'none'
        }
    },

    components: {
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true
            },
            styleOverrides: {
                root: {
                    "&:focus-visible": {
                        outline: "2px solid white",
                        outlineOffset: 2
                    }
                }
            }
        },
        MuiList: {
            styleOverrides: {
                root: {
                    '&.MuiMultiSectionDigitalClockSection-root': {
                        width: 90,
                        '.MuiMenuItem-root': {
                            width: 70
                        }
                    }
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    wordWrap: "break-word"
                }
            }
        },
        MuiCardHeader : {
            styleOverrides: {
                root: {
                    "& .MuiCardHeader-content": {
                        overflow: "hidden"
                    }
                }
            }
        }
    }

}));