import React, {PropsWithChildren} from "react";
import {Box} from "@mui/material";

interface VideoPlaceholderProps extends PropsWithChildren {
    height?: number;
}
export function VideoPlaceholder({height = 175, children}: VideoPlaceholderProps) {

    return (
        <Box
            sx={{bgcolor: "black", width: "100%", height: height, display: "flex", alignItems: "center", justifyContent: "center"}}
            component="div"
        >
            {children}
        </Box>
    )
}