import React from "react";
import {useTheme, Container, Grid2 as Grid, Box} from "@mui/material";
import {useParams} from "react-router-dom";
import FocusRingLogo from "../../img/focus_ring_logo.png";
import {PasswordReset} from "../../components";

export function PasswordResetPage() {

    const theme = useTheme();
    const {resetToken} = useParams();

    return (
        <Container sx={{
            bgcolor: theme.palette.background.paper,
            width: "75%",
            minWidth: "350px",
            mt: "17%",
            pb: 3
        }}>
            <Grid container spacing={3} direction="column" justifyContent="center" alignItems="center" sx={{pb: 3, mb: 3}}>
                <Grid size={{xs: 12}} sx={{mt: 2, textAlign: "center"}} >
                    <Box
                        component="img"
                        sx={{
                            width: 190
                        }}
                        alt="FocusRing"
                        src={FocusRingLogo}
                    />
                </Grid>
            </Grid>
            <PasswordReset resetToken={resetToken} />
        </Container>
    )
}