import * as React from "react";
import { SVGProps } from "react";

const SvgSilver2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={61.92}
    height={61.92}
    xmlSpace="preserve"
    {...props}
  >
    <path
      fill="silver"
      d="m17.485 33.192 3.894 7.705 8.707 1.236-6.301 5.997 1.487 8.469-7.787-3.998-7.788 3.998 1.489-8.469-6.302-5.997 8.708-1.236zM44.435 5.81l3.894 7.705 8.706 1.235-6.3 5.998 1.489 8.469-7.789-3.999-7.788 3.999 1.488-8.469-6.3-5.998 8.706-1.235z"
    />
  </svg>
);

export default SvgSilver2;
