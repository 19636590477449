import React from "react";
import {useTheme, Container, Grid2 as Grid, Divider, Typography, Box, Chip} from "@mui/material";
import {useParams} from "react-router-dom";
import {useProxyReviewResults} from "../../hooks";
import {LoadingSpinner} from "../../components";
import {ProxyReviewStats} from "./ProxyReviewStats";
import {PromptResults} from "./PromptResults";
import {parseISO} from "date-fns";

interface AdminProxyReviewResultsProps {
    round?: string;
}

export function AdminProxyReviewResults({round}: AdminProxyReviewResultsProps) {

    const theme = useTheme();
    const {roundId} = useParams();
    const {proxyReviewResults, requestStatus} = useProxyReviewResults(round || roundId);

    if (requestStatus === "loading") {
        return <LoadingSpinner />;
    }
    if (!proxyReviewResults || requestStatus === "error") {
        return <Container />;
    } else {
        const proxyReviewCount: number = proxyReviewResults.proxyReviewData.reduce((a, c) => a + c.proxyReviews.length, 0);
        const proxyReviewSum: number = proxyReviewResults.proxyReviewData.reduce((a, c) => {
            return a + c.proxyReviews.reduce((aa, cc) => (cc.rawScore) ? aa + cc.rawScore : aa, 0);
        }, 0);

        return (
            <Container sx={{mb: 10}}>
                <Grid container rowSpacing={4} alignItems="center" justifyContent="center" sx={{p: 2}}>
                    <Grid size={{xs: 12, sm: 12, md: 6}}>
                        <Typography variant="h4">
                            {proxyReviewResults.competitionMeta.name}
                        </Typography>
                        <Typography variant="subtitle2">
                            {proxyReviewResults.competitionMeta.description}
                        </Typography>
                        <Box component="div">
                            {proxyReviewResults.competitionMeta.tags.map((c, i) => (
                                    <Chip
                                        sx={{mr: 2, bgcolor: theme.palette.primary.light}}
                                        variant="filled"
                                        label={c.name}
                                        key={i}
                                        size="small"
                                    />
                                )
                            )}
                        </Box>
                    </Grid>
                    <Grid size={{xs: 12, sm: 12, md: 6}}>
                        <Typography variant="h6">
                            {`Round:  ${proxyReviewResults.roundStatus.roundNumber}`}
                        </Typography>
                        <Typography variant="subtitle2">
                            {`Proxy Starts:  ${parseISO(proxyReviewResults.roundStatus.proxyStartDate + "Z").toLocaleString()}`}
                        </Typography>
                        <Typography variant="subtitle2">
                            {`Proxy Ends:  ${parseISO(proxyReviewResults.roundStatus.proxyEndDate + "Z").toLocaleString()}`}
                        </Typography>
                        <Typography variant="subtitle2">
                            {`Review Group:  ${proxyReviewResults.reviewGroup.groupName}`}
                        </Typography>
                        <Typography variant="subtitle2">
                            {`${proxyReviewResults.reviewGroup.countUsers} User(s)`}
                        </Typography>
                    </Grid>
                </Grid>
                <ProxyReviewStats
                    proxyPercent={proxyReviewResults.roundStatus.percentToProxy}
                    proxyReviewAverage={(proxyReviewSum / proxyReviewCount).toFixed(2)}
                    proxyReviewCount={proxyReviewCount}
                />
                <Divider sx={{mb: 5}} />
                {proxyReviewResults.proxyReviewData.map((c, i) =>
                    <PromptResults
                        key={i}
                        proxyReviewData={c}
                    />
                )}
            </Container>
        )
    }

}