// @ts-nocheck
import React, {useRef} from "react";
import {Mesh, LineSegments, Vector3, BufferGeometry } from "three";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";
import {Canvas, extend} from "@react-three/fiber";
import {OrbitControls} from "./OrbitControls";
import {
    AttributeLabelProps, ContinuousBinData, DataBarsProps,
    DataCubeData,
    DemographicLabelsProps, DiscreteBinData,
    LabelProps,
    LineGroupProps,
    LineProps,
    Vertex,
    BarProps
} from "../../data-types";
import Roboto_Regular from "../../font/Roboto_Regular.json";

export function DataCubeRendering({attributes, demographicAxes, cubeData, color}: DataCubeData) {

    const ORIGIN: Vertex = {x: 0, y: 0, z: 0};
    const UP = new Vector3(0, 0, 1);
    const FONT = new FontLoader().parse(Roboto_Regular);
    const WIDTH = 10;
    const LENGTH = 10;
    const HEIGHT = 10;

    function Bar({position, geometry}: BarProps) {
        const mesh = useRef<Mesh>(null!);
        // const [hovered, setHover] = useState(false)
        // const [active, setActive] = useState(false)
        // useFrame((state, delta) => (mesh.current.rotation.x += delta))
        return (
            <mesh
                // {...props}
                ref={mesh}
                position={position}
                // scale={active ? 1.5 : 1}
                // onClick={(_) => setActive(!active)}
                // onPointerOver={(_) => setHover(true)}
                // onPointerOut={(_) => setHover(false)}
            >
                <boxGeometry args={geometry} />
                <meshStandardMaterial color={color} transparent={true} opacity={0.9} />
            </mesh>
        )
    }

    function GridLines({xLength, yLength, zLength}: LineGroupProps) {
        const heights = ([0.2, 0.4, 0.6, 0.8, 1.0]).map((c) => c * zLength);
        return (
            <group>
                {heights.map((c, i) => {
                    return (
                        <group key={i}>
                            {/* Draws the x line then the y line*/}
                            <Line start={{x: 0, y: 0, z: c}} end={{x: xLength, y: 0, z: c}} />
                            <Line start={{x: 0, y: 0, z: c}} end={{x: 0, y: yLength, z: c}} />
                        </group>
                    )
                })}
            </group>
        )
    }

    // group the axis lines here
    function AxisLines({xLength, yLength, zLength}: LineGroupProps) {

        return (
            <group>
                <Line start={ORIGIN} end={{x: xLength, y: 0, z: 0}}/>
                <Line start={ORIGIN} end={{x: 0, y: yLength, z: 0}}/>
                <Line start={ORIGIN} end={{x: 0, y: 0, z: zLength}}/>
            </group>
        )
    }

    function Line({start, end}: LineProps) {

        const lineRef = useRef<LineSegments>(null!);
        const points = [];

        // push origin into point array
        points.push(new Vector3(start.x, start.y, start.z));
        // use length to set other vertex of line.  axes
        // point along positive
        points.push(new Vector3(end.x, end.y, end.z));

        // create geometry from point array
        const geometry = new BufferGeometry().setFromPoints(points);

        return (
            <lineSegments ref={lineRef} geometry={geometry}>
                <lineBasicMaterial color={'#FFFFFF'} />
            </lineSegments>
        )
    }

    function DataLabel({text, position, rotation}: LabelProps) {

        // textGeometry not 'a part' of three-fiber, though it is
        // extendable to be picked up by fiber via extend()
        // extend() is called here because react three fiber will
        // not register if done before Canvas.
        // https://github.com/pmndrs/react-three-fiber/discussions/1742
        extend({TextGeometry});

        return (
            <mesh rotation={rotation} position={position}>
                <textGeometry args={[text, {font: FONT, size: 0.5, height: 0.001}]} />
                <meshStandardMaterial color="white" />
            </mesh>
        )
    }

    function AttributeLabels({attributes, axisLength}: AttributeLabelProps) {
        const numAttributes = attributes.length;
        return (
            <group>
                {attributes.map((c, i) =>
                    <DataLabel
                        key={i}
                        text={c}
                        rotation={[0, 0, Math.PI / 2]}
                        position={[(axisLength / numAttributes) * i + 1, -7, 0]} // TODO: fix shifting y by constant factor
                    />
                )}
            </group>
        )
    }

    function DemographicLabels({demographicAxis, position, yLength, xLength}: DemographicLabelsProps) {
        let rotation;
        let xPositionShift;
        let yPositionShift;
        let numBins = demographicAxis.bins.length;

        switch (position) {
            case 0:
                rotation = [0, 0, Math.PI];
                xPositionShift = -1;
                yPositionShift = 0;
                break;
            case 1:
                rotation = [0, 0, Math.PI / 2]
                xPositionShift = -1;
                yPositionShift = yLength + 1;
                break;
            default:
                rotation = [0, 0, Math.PI]
                xPositionShift = xLength;
                yPositionShift = 0;
                break;
        }

        if (position % 2 === 0) { // labels sequentially along y axis
            return (
                <group>
                    {demographicAxis.bins.map((c, i) =>
                        <DataLabel
                            key={i}
                            text={binToString(c)}
                            rotation={rotation}
                            position={[xPositionShift, (yLength / numBins) * i + 1, 0]}
                        />
                    )}
                </group>
            )
        } else { // labels sequentially along x axis
            return (
                <group>
                    {demographicAxis.bins.map((c, i) =>
                        <DataLabel
                            key={i}
                            text={binToString(c)}
                            rotation={rotation}
                            position={[(xLength / numBins) * i + 1, yPositionShift, 0]}
                        />
                    )}
                </group>
            )
        }
    }

    function binToString(b: ContinuousBinData | DiscreteBinData): string {
        if ("from" in b) {
            return `${b.from} - ${b.to}`;
        } else {
            return b.name;
        }
    }

    function DataBars({matrix, xLength, yLength}: DataBarsProps) {
        const padding = 0.5;

        if (matrix.length === 0) {
            return null;
        } else {
            const numAttributes = attributes.length;
            const numFirstDemographic = demographicAxes[0]!.bins.length;
            const xBounding = ((xLength - 1) / numAttributes);
            const yBounding = ((yLength - 1) / numFirstDemographic);
            const xGeometry = xBounding - (2 * padding);
            const yGeometry = yBounding - (2 * padding);

            return matrix.map((c, i) => {
                // need to shift position by half of xSize, ySize, and height (matrix value)
                const position = [1 + (xBounding / 2) + ((i % numAttributes) * xBounding), 1 + (yBounding / 2) + (Math.floor(i / numAttributes) * yBounding), c / 2];
                return <Bar key={i} position={position} geometry={[xGeometry, yGeometry, c]} />
            })
        }
    }

    return (
        <Canvas orthographic={true} camera={{zoom: 30, position:  [50, 50, 50], up: UP}}>
            <ambientLight />
            <pointLight position={[10, 10, 10]} />
            <pointLight position={[-5, -5, 5]} />
            <AxisLines xLength={WIDTH} yLength={LENGTH} zLength={HEIGHT} />
            <GridLines xLength={WIDTH} yLength={LENGTH} zLength={HEIGHT} />
            <AttributeLabels attributes={attributes} axisLength={WIDTH} />
            {demographicAxes.map((c, i) =>
                <DemographicLabels
                    key={i}
                    demographicAxis={c}
                    position={i}
                    xLength={WIDTH}
                    yLength={LENGTH}
                />
            )}
            <DataBars matrix={cubeData.matrix} xLength={WIDTH} yLength={LENGTH} />
            <OrbitControls />
        </Canvas>
    )
}
