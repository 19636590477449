import React from "react";
import {Paper, Grid2 as Grid, Typography, Container, Button, LinearProgress} from "@mui/material";
import {Link} from "react-router-dom";
import {addSeconds, format} from "date-fns";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {Competition, RequestStatus} from "../../data-types";
import {LoadingSpinner} from "../../components";

interface ActiveEventsProps {
    activeEvents: Competition[];
    requestStatus: RequestStatus;
}

export function ActiveEvents({activeEvents, requestStatus}: ActiveEventsProps) {

    const activeEventsWithTodos = activeEvents.filter(c => {
        return (c.roundStatus && c.roundStatus.todo && (c.roundStatus.todo.required - c.roundStatus.todo.submitted > 0));
    })

    return (
        <Paper component={Container} elevation={0} sx={{pb: 4, minHeight: 230}}>
            <Grid container spacing={2} alignItems="center" justifyContent="space-between" sx={{pb: 4, pt: 2}}>
                <Grid size={{xs: 12}}>
                    <Typography variant="h6" component="h2">
                        To Do
                    </Typography>
                </Grid>
            </Grid>
            {requestStatus === "loading" ?
                <LoadingSpinner /> :
                requestStatus === "error" ?
                    null :
                    <Grid container>
                        {activeEventsWithTodos.length === 0 ?
                            <Grid size={{xs: 12}}>
                                <Paper elevation={1} sx={{p: 3}}>
                                    <Typography variant="subtitle2">
                                        You have nothing to do for active events currently.
                                    </Typography>
                                </Paper>
                            </Grid> :
                            null
                        }
                        {activeEventsWithTodos.map((c, i) => {
                            const required = c.roundStatus?.todo?.required || 0;
                            const submitted = c.roundStatus?.todo?.submitted || 0;
                            const phase = c.roundStatus?.phase || "";
                            const competitionId = c.competitionMeta?.competitionId;
                            const activeTime = c.competitionMeta?.activeTime;

                            if (required - submitted === 0 || !competitionId || !activeTime) {
                                return <></>
                            }

                            if (!(phase === "response" || phase === "review")) {
                                return <></>
                            }

                            const now = new Date();
                            const end = addSeconds(now, -1 * activeTime);

                            return (
                                <Grid size={{xs: 12}} key={i} sx={{mb: 2}}>
                                    <Paper elevation={1} sx={{p: 3}}>
                                        <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
                                            <Grid size={{xs: 12, sm: 6}}>
                                                <Typography variant="subtitle1">
                                                    {c.competitionMeta?.name}
                                                </Typography>
                                                <Typography variant="subtitle2" color="grey">
                                                    {`Ends:  ${format(end, "Pp")}`}
                                                </Typography>
                                            </Grid>
                                            <Grid size={{xs: 12, sm: 6}}>
                                                <Button
                                                    component={Link}
                                                    to={(phase === "response") ? `/video-response/${competitionId}` : `video-review/${competitionId}`}
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    sx={{mb: 2}}
                                                    fullWidth
                                                    startIcon={<OpenInNewIcon />}
                                                >
                                                    {`${required - submitted} ${(phase === "response" ? "Responses" : "Reviews")} Due`}
                                                </Button>
                                                <LinearProgress
                                                    variant="determinate"
                                                    value={100 * (submitted / required)}
                                                />
                                                <Typography variant="subtitle2" sx={{textAlign: "right"}}>
                                                    {`(${submitted} / ${required})`}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            )
                        })}
                    </Grid>
            }
        </Paper>
    )
}