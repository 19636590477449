import React, {ChangeEvent, useState} from "react"
import {Button, Typography, Box, Dialog, DialogTitle, DialogContent, TextField} from "@mui/material";
import {AddEntryDialogProps} from "../../data-types";

type TimeState = {fromMinutes: number, fromSeconds: number, fromMilliseconds: number, toMinutes: number, toSeconds: number, toMilliseconds: number};
export function AddEntryDialog({open, handleClose, handleAddEntry}: AddEntryDialogProps) {

    const [entryTime, setEntryTime] = useState<TimeState>({
        fromMinutes: 0,
        fromSeconds: 0,
        fromMilliseconds: 0,
        toMinutes: 0,
        toSeconds: 0,
        toMilliseconds: 0
    })
    const [text, setText] = useState<string>("");

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const {name, valueAsNumber} = e.target;
        setEntryTime(prev => {
            return {
                ...prev,
                [name]: valueAsNumber
            }
        })
    }

    const handleSubmit = () => {
        const fromInMs = (entryTime.fromMinutes * 60000) + (entryTime.fromSeconds * 1000) + entryTime.fromMilliseconds;
        const toInMs = (entryTime.toMinutes * 60000) + (entryTime.toSeconds * 1000) + entryTime.toMilliseconds;

        try {
            handleAddEntry(fromInMs, toInMs, text);
            handleClose();
        } catch (e) {
            alert(e);
        }
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>
                Add Transcript Entry
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1">
                    From:
                </Typography>
                <Box sx={{display: "inline"}} component="div">
                    <TextField
                        margin="dense"
                        id="fromMinutes"
                        name="fromMinutes"
                        label="Min"
                        type="number"
                        size="small"
                        value={entryTime.fromMinutes}
                        onChange={handleChange}
                        slotProps={{
                            htmlInput: {
                                min: 0,
                                max: 59,
                                maxLength: 2
                            }
                        }}
                        sx={{width: 70}}
                    />
                    <TextField
                        margin="dense"
                        id="fromSeconds"
                        name="fromSeconds"
                        label="Sec"
                        type="number"
                        size="small"
                        value={entryTime.fromSeconds}
                        onChange={handleChange}
                        slotProps={{
                            htmlInput: {
                                min: 0,
                                max: 59,
                                maxLength: 2
                            }
                        }}
                        sx={{width: 70}}
                    />
                    <TextField
                        margin="dense"
                        id="fromMilliseconds"
                        name="fromMilliseconds"
                        label="Ms"
                        type="number"
                        size="small"
                        value={entryTime.fromMilliseconds}
                        onChange={handleChange}
                        slotProps={{
                            htmlInput: {
                                min: 0,
                                max: 999,
                                maxLength: 3
                            }
                        }}
                        sx={{width: 100}}
                    />
                </Box>
                <Typography variant="body1" sx={{mt: 2}}>
                    To:
                </Typography>
                <Box sx={{display: "inline"}} component="div">
                    <TextField
                        margin="dense"
                        id="toMinutes"
                        name="toMinutes"
                        label="Min"
                        type="number"
                        size="small"
                        value={entryTime.toMinutes}
                        onChange={handleChange}
                        slotProps={{
                            htmlInput: {
                                min: 0,
                                max: 59
                            }
                        }}
                    />
                    <TextField
                        margin="dense"
                        id="toSeconds"
                        name="toSeconds"
                        label="Sec"
                        type="number"
                        size="small"
                        value={entryTime.toSeconds}
                        onChange={handleChange}
                        slotProps={{
                            htmlInput: {
                                min: 0,
                                max: 59
                            }
                        }}
                    />
                    <TextField
                        margin="dense"
                        id="toMilliseconds"
                        name="toMilliseconds"
                        label="Ms"
                        type="number"
                        size="small"
                        value={entryTime.toMilliseconds}
                        onChange={handleChange}
                        slotProps={{
                            htmlInput: {
                                min: 0,
                                max: 999
                            }
                        }}
                    />
                </Box>
                <Box sx={{mt: 3}} component="div">
                    <Typography variant="body1" sx={{mt: 2}}>
                        Text:
                    </Typography>
                    <TextField
                        margin="dense"
                        id="text"
                        name="text"
                        label="Transcript Text"
                        fullWidth
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                    />

                </Box>
                <Box sx={{mt: 3, textAlign: "center"}} component="div">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                    >
                        Add
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    )
}