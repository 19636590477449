import * as React from "react";
import { SVGProps } from "react";

const SvgGold1 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={61.92}
    height={61.92}
    xmlSpace="preserve"
    {...props}
  >
    <path
      fill="#FED700"
      d="m30.96 13.072 5.377 10.897 12.025 1.745-8.7 8.482 2.054 11.977-10.756-5.656-10.756 5.656 2.054-11.977-8.7-8.482 12.025-1.745z"
    />
  </svg>
);

export default SvgGold1;
