import {ChangeEvent, useCallback, useState, useContext} from "react";
import {LoginState, UserProfileState} from "../../data-types";
import {DataAccess, DataValidation} from "../../util";
import qs from "qs";
import {MarketingContext, UserContext, BrandingContext} from "../../context";
import {useSearchParams} from "react-router-dom";

export const useLogin = () => {
    const [searchParams] = useSearchParams();
    const {setUser} = useContext(UserContext);
    const {setMarketingId} = useContext(MarketingContext);
    const {setLogoUrl} = useContext(BrandingContext);
    const [loginState, setLoginState] = useState<LoginState>({
        username: "",
        password: ""
    });

    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setLoginState(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }, []);

    const login = useCallback(async (loginForm: LoginState) => {
        // this call sets the cookie in the browser that ties this to a user session
        // in the middleware
        await DataAccess.postFormString("/login", qs.stringify(loginForm));

        // this call gets the profile info with newly-set cookie and assigns it
        // to top-level user context in this app
        const {userProfile}: {userProfile: UserProfileState} = await DataAccess.get("/api/user/profile.json");

        // if this user does not have email confirmed, DON'T set user context
        if (!userProfile.isEmailConfirmed) {
            return `/confirmEmail/reminder/${userProfile.email}`;
        }

        setUser(userProfile);

        if (userProfile.institutionLogo) {
            setLogoUrl(userProfile.institutionLogo);
        }

        // parse search params for eventId and email.  if exists, redirect to
        // myEvents page
        const params = qs.parse(searchParams.toString(), {ignoreQueryPrefix: true});
        if (params.marketingId) {
            setMarketingId(params.marketingId as string);
        }
        if (params.eventId) {
            return `/enroll?eventId=${params.eventId}${params.marketingId ? `&marketingId=${params.marketingId}` : ""}`;
        }

        // return the link to navigate to based on the user's profile data
        if (userProfile.role === "admin") {
            return "/admin";
        } else if (userProfile.isReviewer) {
            return "/reviewer";
        } else if (DataValidation.isUserProfileIncomplete(userProfile)) {
            return "/user/profile?src=login";
        } else {
            return "/";
        }
    }, [setUser, searchParams, setMarketingId, setLogoUrl]);

    return {
        loginState: loginState,
        handleChange: handleChange,
        login: login
    }
}