import {ChangeEvent, useCallback, useState} from "react";
import {DataAccess} from "../../util";

export const useVideoPromptShare = () => {

    const [toShareWith, setToShareWith] = useState<string>("");
    const [canShare, setCanShare] = useState<boolean>(true);

    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const {value, name, checked} = e.target;
        if (name === "canShare") {
            setCanShare(checked);
        } else {
            setToShareWith(value);
        }
    }, []);

    const submitShare = useCallback(async (toShareWith: string, videoId: number, canShare: boolean) => {
        const payload = {emailToShareWith: toShareWith, canShare: canShare};
        await DataAccess.post(`/api/videoPrompt/${videoId}/shareVideo.json`, {data: payload});
    }, []);

    const reset = useCallback(() => {
        setCanShare(true);
        setToShareWith("");
    }, []);

    return {
        toShareWith: toShareWith,
        canShare: canShare,
        handleChange: handleChange,
        submitShare: submitShare,
        reset: reset
    }
}