import {ListItem, ListItemText, ListItemAvatar, ListItemIcon, Avatar, Paper, Typography} from "@mui/material";
import {Report} from "../../data-types";
import ErrorIcon from '@mui/icons-material/Error';
import {parseISO} from "date-fns";

type ReportCommentListItemProps = {report: Report};
export function ReportCommentListItem({report}: ReportCommentListItemProps) {

    return (
        <ListItem component={Paper} elevation={report.isReportHandled ? 0 : 1} sx={{mb: 1}}>
            <ListItemAvatar>
                <Avatar alt={`${report.reviewerUsername}-avatar`} src={report.reviewerAvatar ? `data:image/jpeg;base64,${report.reviewerAvatar}` : ""} />
            </ListItemAvatar>
            <ListItemText
                disableTypography={true}
                primary={
                    <Typography variant="h6">
                        {report.reviewerUsername}
                    </Typography>
                }
                secondary={
                    <>
                        <Typography variant="subtitle2" sx={{mb: 2}}>
                            {!report.comments || report.comments.length === 0 ? "No comments provided" : report.comments}
                        </Typography>
                        <Typography variant="subtitle2" sx={{fontStyle: "italic"}}>
                            {`Type:  ${report.isProxy ? "proxy" : "peer"}`}
                        </Typography>
                        <Typography variant="subtitle2" sx={{fontStyle: "italic"}}>
                            {`Published:  ${parseISO(report.publishedDate + "Z").toLocaleString()}`}
                        </Typography>
                    </>
                }
            />
            <ListItemIcon>
                {report.isReportHandled ?
                    null :
                    <ErrorIcon color="secondary" />
                }
            </ListItemIcon>
        </ListItem>
    )
}