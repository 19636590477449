import React from "react";
import {Container, Grid2 as Grid, Button, Typography, TextField} from "@mui/material";
import {useFeedback} from "./useFeedback";
import {toast} from "react-toastify";
import {useNavigate, useLocation} from "react-router-dom";
import {formatError} from "../../util";

export function Feedback() {

    const navigate = useNavigate();
    const {state} = useLocation();
    const {
        feedback,
        handleTextChange,
        submitFeedback
    } = useFeedback();

    const submit = () => {
        submitFeedback(feedback, state.prevLocation)
            .then(_ => {
                toast.success("Thank you for your feedback!");
                navigate(-1);
            })
            .catch(e => {
                console.log(e);
                toast.error(formatError(e));
            })
    }

    return (
        <Container sx={{mb: 10}}>
            <Grid container spacing={2}>
                <Grid size={{xs: 12}} sx={{textAlign: "center", mb: 3}}>
                    <Typography variant="h6">
                        Give Us Feedback
                    </Typography>
                </Grid>
                <Grid size={{xs: 12}}>
                    <TextField
                        required
                        label="Type your feedback here"
                        name="feedback"
                        fullWidth={true}
                        multiline
                        rows={4}
                        value={feedback}
                        onChange={handleTextChange}
                    />
                </Grid>
                <Grid size={{xs: 12}}>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={feedback.length === 0}
                        onClick={submit}
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </Container>
    )
}