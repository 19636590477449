import React, {useState, useCallback} from "react";
import {Button, TextField, Grid2 as Grid, Box, Container, Typography} from "@mui/material";
import {CreateReviewGroupState} from "../../data-types";
import {EmailEntry} from "../../components";
import {DataAccess} from "../../util";
import {useNavigate} from "react-router-dom";

export function AdminReviewGroupsCreate() {
    const [groupName, setGroupName] = useState<string>("");
    const [emails, setEmails] = useState<string[]>([]);
    const navigate = useNavigate();

    const handleSubmit = () => {
        const reviewGroupCreate: CreateReviewGroupState = {emailsToAdd: emails, groupName: groupName};
        DataAccess.post('/api/reviewGroup/create.json', {data: reviewGroupCreate})
            .then(_ => {
                navigate("/admin/reviewGroups");
            })
            .catch(e => {
                alert(e);
            })
    }

    const handleAddEmails = useCallback((emails: string[]) => {
        setEmails(prev => [...prev, ...emails]);
    }, []);

    const handleRemoveEmail = useCallback((email: string) => {
        setEmails(prev => prev.filter(s => s !== email));
    }, []);

    return (
        <Container sx={{
            width: "100%",
            p:5
        }}>
            <Box component="div" sx={{mb: 12}}>
                <Typography
                    align="center"
                    variant="h4"
                    sx={{mb: 2}}
                >
                    {(groupName === "") ? "Create Review Group" : groupName}
                </Typography>
            </Box>
            <Typography
                variant="h5"
                sx={{mb: 2}}
            >
                1.)  Give this group a name
            </Typography>
            <Grid container spacing={2}>
                <Grid size={{xs: 12}}>
                    <TextField
                        required
                        label="Group Name"
                        fullWidth
                        name="groupName"
                        value={groupName}
                        onChange={(e) => setGroupName(e.target.value)}
                        sx={{mb: 3}}
                    />
                </Grid>
            </Grid>
            <Typography
                variant="h5"
                sx={{mb: 2}}
            >
                2.)  Add users to the review group
            </Typography>
            <Grid container spacing={2} sx={{mb: 12}}>
                <Grid size={{xs: 12}}>
                    <EmailEntry
                        emails={emails}
                        addEmails={handleAddEmails}
                        removeEmail={handleRemoveEmail}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{mb: 12}}>
                <Grid size={{xs: 12}} sx={{textAlign: "center"}}>
                    <Button
                        variant="contained"
                        component="label"
                        color="primary"
                        onClick={handleSubmit}
                    >
                        Create Group
                    </Button>
                </Grid>
            </Grid>
        </Container>
    )
}