import * as React from "react";
import { SVGProps } from "react";

const SvgSilver4 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={61.92}
    height={61.92}
    xmlSpace="preserve"
    {...props}
  >
    <path
      fill="silver"
      d="m15.39 2.447 4.134 8.379 9.247 1.343-6.691 6.522 1.579 9.209-8.269-4.349L7.118 27.9l1.58-9.209-6.69-6.522 9.247-1.343zM15.39 34.021l4.134 8.378 9.247 1.344-6.691 6.522 1.579 9.209-8.269-4.35-8.272 4.35 1.58-9.209-6.69-6.522 9.247-1.344zM46.531 2.447l4.135 8.379 9.247 1.343-6.691 6.522 1.58 9.209-8.271-4.349-8.27 4.349 1.58-9.209-6.692-6.522 9.247-1.343zM46.531 34.021l4.135 8.378 9.247 1.344-6.691 6.522 1.58 9.209-8.271-4.35-8.27 4.35 1.58-9.209-6.692-6.522 9.247-1.344z"
    />
  </svg>
);

export default SvgSilver4;
