import {styled} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid"

export const StyledGrid = styled(DataGrid)(() => ({
   '& .highlighted-row': {
       backgroundColor: "#333333",
       fontStyle: "italic"
   },
    '& .MuiDataGrid-row:hover': {
       backgroundColor: "#282828"
    }
}))