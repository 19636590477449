import React from "react";
import {Paper} from "@mui/material";
import {Copyright} from "../";

export function Footer() {

    return (
        <Paper sx={{width: "100%", position: "absolute", bottom: 0}} elevation={1}>
            <Copyright />
        </Paper>
    )
}