import React, {useState} from "react";
import {
    Box, Button,
    Checkbox, Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel, MenuItem,
    Paper,
    Select, SelectChangeEvent,
    Typography
} from "@mui/material";
import {
    DemographicSelectProps,
    ContinuousBinData,
    DiscreteBinData,
    ContinuousBinConfig,
    DemographicAxis
} from "../../data-types";
import {EditContinuousBins} from "./EditContinuousBins";
import {EditDiscreteBins} from "./EditDiscreteBins";
import UndoIcon from '@mui/icons-material/Undo';

export function DemographicSelect({demographics, submit, undo, active}: DemographicSelectProps) {

    // true for continuous, false for discrete
    const [demographicType, setDemographicType] = useState<boolean>(true);
    const [demographicName, setDemographicName] = useState<string>("");
    const [continuousBins, setContinuousBins] = useState<ContinuousBinData[]>([]);
    const [discreteBins, setDiscreteBins] = useState<DiscreteBinData[]>([]);
    const [continuousBinConfig, setContinuousBinConfig] = useState<ContinuousBinConfig>({
        min: 0,
        max: 1,
        minStep: 1
    });

    const handleDemographicTypeChange = (b: boolean) => {
        setDemographicName("");
        setDemographicType(b);
    }

    const handleDemographicChange = (e: SelectChangeEvent) => {
        const name = e.target.value;

        if (name === "") {
            setDemographicName("");
            setContinuousBins([]);
            setContinuousBinConfig({min: 0, max: 1, minStep: 1});
            setDiscreteBins([]);
            return;
        }

        // if continuous variable selected
        if (demographicType) {
            // initialize bins using recommended bin step
            const demographicData = demographics.continuous.find(o => o.name === name);
            const bins = [];
            const demoMin = demographicData!.rangeMin;
            const demoMax = demographicData!.rangeMax;
            const demoStep = demographicData!.recommendedBinSize;
            const demoMinStep = demographicData!.binStep;

            for (let i = demoMin; i < demoMax; i += demoStep) {
                bins.push({from: i, to: Math.min(i + demoStep, demoMax)})
            }
            setDemographicName(name);
            setContinuousBinConfig({min: demoMin, max: demoMax, minStep: demoMinStep});
            setContinuousBins(bins);
        } else {
            // set discrete bins
            const demographicData = demographics.discrete.find(o => o.name === name);
            const bins = demographicData!.bins.map((c):DiscreteBinData => {
                return {
                    name: c,
                    checked: true
                }
            });

            setDemographicName(name);
            setDiscreteBins(bins);
        }
    }

    const addContinuousBin = () => {
        const demographic = demographics.continuous.find(o => o.name === demographicName);
        const min = demographic!.rangeMin;
        const max = demographic!.rangeMax;
        setContinuousBins(prev => [...prev, {from: min, to: max}]);
    }

    const removeContinuousBin = (index: number) => {
        setContinuousBins(prev => prev.filter((_, i) => i !== index));
    }

    const onChangeContinuousBin = (index: number, bin: ContinuousBinData) => {
        setContinuousBins(prev => prev.map((c, i) => {
            if (i === index) {
                return bin;
            }
            else {
                return c;
            }
        }));
    }

    const changeDiscreteBin = (name: string, checked: boolean) => {
        setDiscreteBins(prev => prev.map((c) => {
            if (c.name === name) {
                return {...c, checked: checked}
            } else {
                return c
            }
        }))
    }

    const handleSubmit = () => {

        const bins: DemographicAxis = (demographicType) ?
            {name: demographicName, bins: continuousBins} :
            {name: demographicName, bins: discreteBins.filter(b => b.checked)};

        submit(bins);
    }

    return (
            <Paper sx={{pt: 3, pb: 3, textAlign: 'center'}}>
                <Typography variant="h5" sx={{mb: 3}}>
                    Demographic
                </Typography>
                <Typography variant="body1" sx={{mb: 3}}>
                    Select a Continuous or Discrete Variable
                </Typography>
                <Box component="div" sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", mb: 3}}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    inputProps={{
                                        'aria-label': 'Continuous Demographic Select'
                                    }}
                                    checked={demographicType}
                                    onChange={() => handleDemographicTypeChange(true)}
                                />
                            }
                            label="Continuous"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    inputProps={{
                                        'aria-label': 'Discrete Demographic Select'
                                    }}
                                    checked={!demographicType}
                                    onChange={() => handleDemographicTypeChange(false)}
                                />
                            }
                            label="Discrete"
                        />
                    </FormGroup>
                </Box>
                <Typography variant="body1" sx={{mb: 3}}>
                    Select a demographic variable
                </Typography>
                <Box sx={{mb: 3}} component="div">
                    <FormControl sx={{m: 1,  width: "90%"}}>
                        <InputLabel id="demographic-name-label">Demographic</InputLabel>
                        <Select
                            labelId="demographic-name-label"
                            id="demographic-name"
                            value={demographicName}
                            variant="outlined"
                            onChange={handleDemographicChange}
                        >
                            <MenuItem value="">
                               None
                            </MenuItem>
                            {(demographicType)
                                ? demographics.continuous.map((c, i) => <MenuItem key={i} value={c.name}>{c.name}</MenuItem>)
                                : demographics.discrete.map((c, i) => <MenuItem key={i} value={c.name}>{c.name}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                </Box>
                {(demographicName !== "") ?
                    <>
                        <Typography variant="body1" sx={{mb: 3}}>
                            Modify bins
                        </Typography>
                        {demographicType ?
                            <EditContinuousBins
                                active={active}
                                bins={continuousBins}
                                binConfig={continuousBinConfig}
                                addContinuousBin={addContinuousBin}
                                removeContinuousBin={removeContinuousBin}
                                onChangeContinuousBin={onChangeContinuousBin}
                            /> :
                            <EditDiscreteBins
                                bins={discreteBins}
                                changeDiscreteBin={changeDiscreteBin}
                                active={active}
                            />
                        }
                    </> :
                    null
                }
                <Divider sx={{m: 2}} />
                <Button
                    sx={{width: "90%", mb: 2}}
                    variant="contained"
                    color="primary"
                    disabled={demographicName === "" || !active}
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
                <Button
                    sx={{width: "90%"}}
                    variant="contained"
                    color="secondary"
                    onClick={() => undo()}
                    startIcon={<UndoIcon />}
                    // disabled={!active}
                >
                    Go back
                </Button>
            </Paper>
        )
}