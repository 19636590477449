import {useState, useCallback, ChangeEvent} from "react";
import {DataAccess} from "../../util";

export const useCompetitionShare = () => {

    const [toShareWith, setToShareWith] = useState<string>("");

    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setToShareWith(e.target.value);
    }, []);

    const submitShare = useCallback(async (toShareWith: string, competitionId: number) => {
        const payload = {emailToShareWith: toShareWith};
        await DataAccess.post(`/api/competition/${competitionId}/share.json`, {data: payload});
    }, []);

    const reset = useCallback(() => {
        setToShareWith("");
    }, []);

    return {
        submitShare: submitShare,
        handleChange: handleChange,
        toShareWith: toShareWith,
        reset: reset
    }
}