import {useState, useEffect} from "react";
import {ProxyReviewResults, RequestStatus} from "../../data-types";
import {DataAccess} from "../../util";

export const useProxyReviewResults = (roundId: string | undefined) => {

    const [, setLoading] = useState<boolean>(true);
    const [requestStatus, setRequestStatus] = useState<RequestStatus>("loading");
    const [proxyReviewResults, setProxyReviewResults] = useState<ProxyReviewResults | null>(null);

    useEffect(() => {

        const controller = new AbortController();
        const getProxyReviewResults = async () => {
            if (roundId) {
                const r = await DataAccess.get(`/api/proxyReview/${roundId}/getResults.json`, {signal: controller.signal});
                setProxyReviewResults(r.proxyReviewResults);
                setLoading(false);
            }
        };

        getProxyReviewResults()
            .then(_ => setRequestStatus("complete"))
            .catch(e => {
                console.log(e);
                setRequestStatus("error");
            })

        return () => {
            controller.abort();
        }
    }, [roundId]);

    return {
        requestStatus: requestStatus,
        proxyReviewResults: proxyReviewResults
    }
}