import React, {useState} from "react";
import {Container, Grid2 as Grid, TextField, MenuItem, Typography, Slider} from "@mui/material";
import {useWordcloudService} from "../../hooks";
import {Wordcloud} from "@visx/wordcloud";
import {Text} from "@visx/text";
import {Tooltip, useTooltip} from "@visx/tooltip";
import {scaleLog} from "@visx/scale";
import {BaseDatum} from "@visx/wordcloud/lib/types";
import {useParams} from "react-router-dom";

interface WordcloudDatum extends BaseDatum {
    value: number;
}

export function WordcloudPage() {

    const {competitionId} = useParams();
    const {showTooltip, tooltipTop, tooltipData, tooltipLeft, tooltipOpen, hideTooltip} = useTooltip<string>();
    const {
        selectedUsers,
        selectedRounds,
        selectedPrompts,
        selectedQuintile,
        meta,
        handleSelectionChange,
        words
    } = useWordcloudService(competitionId);

    // arbitrarily instantiate wordCount to a high number so many words appear on the
    // page on load
    const [wordCount, setWordCount] = useState<number>(1000);
    const [frequency, setFrequency] = useState<number>(1);

    const handleSliderChange = (name: string, v: number | number[]) => {
        if (name === "wordCount") {
            setWordCount(v as number);
        } else {
            setFrequency(v as number);
        }
    }

    const fontSizeSetter = (d: WordcloudDatum) => fontScale(d.value);

    const maxWordCount: number = words.length;
    const maxFrequency: number = Math.max(...words.map(c => c.value));

    const filteredWords = words.filter(c => c.value >= frequency).slice(0, wordCount);

    const fontScale = scaleLog({
        domain: [Math.min(...filteredWords.map(c => c.value)), Math.max(...filteredWords.map(c => c.value))],
        range: [10, 60]
    });

    const fixedValueGenerator = () => 0.5;

    const handleMouseOver = (event: React.MouseEvent<SVGTextElement>, datum: string | undefined) => {
        const x = event.pageX;
        const y = event.pageY;
        showTooltip({
            tooltipLeft: x,
            tooltipTop: y,
            tooltipData: datum
        });
    };

    const getTooltipValue = (word?: string) => {
        const found = words.find(c => c.text === word);
        return found ? `Count:  ${found.value}` :  "";
    }

    return (
        <Container sx={{mb: 10, mt: 2}}>
            <Grid container spacing={3}>
                <Grid size={{xs: 12, sm: 6, md: 3}}>
                    <TextField
                        label="Select Rounds"
                        fullWidth
                        name="roundSelect"
                        select
                        value={selectedRounds}
                        onChange={handleSelectionChange}
                        slotProps={{
                            select: {
                                multiple: true
                            }
                        }}
                    >
                        {meta.roundIds.map(c => (
                            <MenuItem key={c.roundId} value={c.roundId}>
                                {`Round ${c.roundNumber}`}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid size={{xs: 12, sm: 6, md: 3}}>
                    <TextField
                        label="Select Prompts"
                        fullWidth
                        name="promptSelect"
                        select
                        value={selectedPrompts}
                        onChange={handleSelectionChange}
                        slotProps={{
                            select: {
                                multiple: true
                            }
                        }}
                    >
                        {meta.promptIds.map(c => (
                            <MenuItem key={c.videoId} value={c.videoId}>
                                {c.promptName}
                            </MenuItem>
                        ))}
                    </TextField>

                </Grid>
                <Grid size={{xs: 12, sm: 6, md: 3}}>
                    <TextField
                        label="Select Users"
                        fullWidth
                        name="userSelect"
                        select
                        value={selectedUsers}
                        onChange={handleSelectionChange}
                        slotProps={{
                            select: {
                                multiple: true
                            }
                        }}
                    >
                        {meta.userIds.map(c => (
                            <MenuItem key={c.id} value={c.id}>
                                {`${c.firstName} ${c.lastName}`}
                            </MenuItem>
                        ))}
                    </TextField>

                </Grid>
                <Grid size={{xs: 12, sm: 6, md: 3}}>
                    <TextField
                        label="Select Quintile"
                        fullWidth
                        name="quintileSelect"
                        select
                        value={selectedQuintile}
                        onChange={handleSelectionChange}
                        slotProps={{
                            select: {
                                multiple: true
                            }
                        }}
                    >
                        {["Bottom 20% (0 - 20%)", "20 - 40%", "40 - 60%", "60 - 80%", "Top 20% (80 - 100%)"].map((c, i) => (
                            <MenuItem key={c} value={i}>
                                {c}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid size={{xs: 12, sm: 6}}>
                    <Typography variant="subtitle2">
                        Number of Words
                    </Typography>
                    <Slider
                        valueLabelDisplay="auto"
                        min={1}
                        max={Math.max(maxWordCount, 1)}
                        value={wordCount}
                        onChange={(_, v) => handleSliderChange("wordCount", v)}
                    />
                </Grid>
                <Grid size={{xs: 12, sm: 6}}>
                    <Typography variant="subtitle2">
                        Word Frequency
                    </Typography>
                    <Slider
                        valueLabelDisplay="auto"
                        min={1}
                        max={Math.max(maxFrequency, 1)}
                        value={frequency}
                        onChange={(_, v) => handleSliderChange("frequency", v)}
                    />
                </Grid>
                <Grid size={{xs: 12}} sx={{textAlign:"center"}}>
                    <Wordcloud
                        width={600}
                        height={600}
                        words={filteredWords}
                        fontSize={fontSizeSetter}
                        rotate={0}
                        spiral="archimedean"
                        padding={10}
                        random={fixedValueGenerator}
                    >
                        {words =>
                            words.map(c => (
                                <Text
                                    key={c.text}
                                    textAnchor="middle"
                                    fill="white"
                                    transform={`translate(${c.x}, ${c.y}) rotate(${c.rotate})`}
                                    fontSize={c.size}
                                    onMouseOver={e => handleMouseOver(e, c.text)}
                                    onMouseOut={hideTooltip}
                                >
                                    {c.text}
                                </Text>
                            ))
                        }
                    </Wordcloud>
                    {tooltipOpen && (
                        <Tooltip
                            key={Math.random()}
                            top={tooltipTop}
                            left={tooltipLeft}
                        >
                            {getTooltipValue(tooltipData)}
                        </Tooltip>
                    )}
                </Grid>
            </Grid>
        </Container>
    )
}