import React, {useState} from "react";
import {ReviewGroupCard, CopyReviewGroupModal, DeleteReviewGroupModal} from "../../components";
import {ReviewGroup, ReviewGroupListProps} from "../../data-types";
import {Grid2 as Grid} from "@mui/material";

export function ReviewGroupList({reviewGroups}: ReviewGroupListProps) {

    const [copyGroupModal, setCopyGroupModal] = useState<ReviewGroup | null>(null);
    const [deleteGroupModal, setDeleteGroupModal] = useState<ReviewGroup | null>(null);

    const handleCopyClick = (r: ReviewGroup) => setCopyGroupModal(r);
    const handleDeleteClick = (r: ReviewGroup) => setDeleteGroupModal(r);


    return (
        <React.Fragment>
            {reviewGroups.map((c, i) =>
                <Grid size={{xs: 12, sm: 4}} key={i}>
                    <ReviewGroupCard
                        group={c}
                        handleCopyClick={handleCopyClick}
                        handleDeleteClick={handleDeleteClick}
                    />
                </Grid>
            )}
            <CopyReviewGroupModal reviewGroup={copyGroupModal} setReviewGroup={setCopyGroupModal} />
            <DeleteReviewGroupModal reviewGroup={deleteGroupModal} setReviewGroup={setDeleteGroupModal} />
        </React.Fragment>
    )
}