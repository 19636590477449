import React from "react";
import {Divider, Box, Chip, Container, Grid2 as Grid, Paper, Typography, useTheme} from "@mui/material";
import {useAdminCompetitionResults} from "../../hooks";
import {useParams} from "react-router-dom";
import {NoDataCard, LoadingSpinner} from "../../components";
import {RoundStatistics} from "./RoundStatistics";
import {DataFormatting} from "../../util";

export function AdminCompetitionResults() {

    const {competitionId} = useParams();
    const {competitionResults, requestStatus} = useAdminCompetitionResults(competitionId);
    const theme = useTheme();

    if (requestStatus === "loading") {
        return <LoadingSpinner />
    } else if (requestStatus === "error") {
        return <Container />
    } else {

        const rounds = competitionResults ? DataFormatting.zip(competitionResults.roundMeta, competitionResults.roundStatistics) : [];

        return (
            <Container sx={{mb: 10}}>
                <Grid container sx={{mt: 2, mb: 5}}>
                    <Grid size={{xs: 12}}>
                        <Typography variant="h4">
                            Admin Reporting
                        </Typography>
                        <Divider sx={{mt: 3}} />
                    </Grid>
                </Grid>
                <Grid container sx={{mb: 5}}>
                    <Grid size={{xs: 12, sm: 12, md: 6}}>
                        <Typography variant="h5" sx={{mb: 2}}>
                            {competitionResults?.competitionMeta.name}
                        </Typography>
                        <Typography variant="subtitle2" sx={{mb: 2}}>
                            {competitionResults?.competitionMeta.description}
                        </Typography>
                        <Box component="div">
                            {competitionResults?.competitionMeta.tags.map((c, i) => (
                                    <Chip
                                        sx={{mr: 2, bgcolor: theme.palette.primary.light}}
                                        variant="filled"
                                        label={c.name}
                                        key={i}
                                        size="small"
                                    />
                                )
                            )}
                        </Box>
                    </Grid>
                    <Grid size={{xs: 12, sm: 12, md: 6}} />
                </Grid>
                <Grid container>
                    <Grid size={{xs: 12}}>
                        <Typography variant="h6">
                            Overall Statistics
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container justifyContent="space-evenly" alignItems="center" sx={{p: 3 ,textAlign: "center"}}>
                    <Grid size={{xs: 12, sm: 4}}>
                        <Paper elevation={1} sx={{p: 2, m: 2}}>
                            <Typography variant="h4">
                                {competitionResults?.overallStatistics.totalUsers}
                            </Typography>
                            <Typography variant="subtitle2">
                                Total Users
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid size={{xs: 12, sm: 4}}>
                        <Paper elevation={1} sx={{p: 2, m: 2}}>
                            <Typography variant="h4">
                                {competitionResults?.overallStatistics.totalResponses}
                            </Typography>
                            <Typography variant="subtitle2">
                                Total Responses
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid size={{xs: 12, sm: 4}}>
                        <Paper elevation={1} sx={{p: 2, m: 2}}>
                            <Typography variant="h4">
                                {competitionResults?.overallStatistics.totalReviews}
                            </Typography>
                            <Typography variant="subtitle2">
                                Total Reviews
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid size={{xs: 12}}>
                        <Typography variant="h6">
                            Round Statistics
                        </Typography>
                        <Divider sx={{mt: 3, mb: 3}} />
                    </Grid>
                    {rounds.map((c, i) =>
                        <RoundStatistics
                            key={i}
                            roundStatus={c[0]}
                            roundStatistics={c[1]}
                        />
                    )}
                    {rounds.length === 0 ?
                        <Grid size={{xs: 12}}>
                            <NoDataCard message={"There are no round scheduled for this event yet"} />
                        </Grid> :
                        null
                    }
                </Grid>
            </Container>
        )
    }
}