import {
    Bronze1, Silver1, Silver2, Silver3, Silver4,
    Gold1, Gold2, Gold3, Gold4, Gold5
} from "./icon-components";
import {SvgIcon} from "@mui/material";
import {RankIconProps} from "../data-types";

export function RankIcon({rank}: RankIconProps) {
    if (rank < 0.5) {
        return <SvgIcon viewBox="0 0 62 62" titleAccess="Bronze" component={Bronze1} aria-label="Bronze" />
    } else if (rank < 0.6) {
        return <SvgIcon viewBox="0 0 62 62" titleAccess="Silver 1" component={Silver1} aria-label="Silver 1" />
    } else if (rank < 0.7) {
        return <SvgIcon viewBox="0 0 62 62" titleAccess="Silver2" component={Silver2} aria-label="Silver 2" />
    } else if (rank < 0.8) {
        return <SvgIcon viewBox="0 0 62 62" titleAccess="Silver 3" component={Silver3} aria-label="Silver 3" />
    } else if (rank < 0.9) {
        return <SvgIcon viewBox="0 0 62 62" titleAccess="Silver 4" component={Silver4} aria-label="Silver 4" />
    } else if (rank < 0.92) {
        return <SvgIcon viewBox="0 0 62 62" titleAccess="Gold 1" component={Gold1} aria-label="Gold 1" />
    } else if (rank < 0.94) {
        return <SvgIcon viewBox="0 0 62 62" titleAccess="Gold 2" component={Gold2} aria-label="Gold 2" />
    } else if (rank < 0.96) {
        return <SvgIcon viewBox="0 0 62 62" titleAccess="Gold 3" component={Gold3} aria-label="Gold 3" />
    } else if (rank < 0.98) {
        return <SvgIcon viewBox="0 0 62 62" titleAccess="Gold 4" component={Gold4} aria-label="Gold 4" />
    } else {
        return <SvgIcon viewBox="0 0 62 62" titleAccess="Gold 5" component={Gold5} aria-label="Gold 5" />
    }
}