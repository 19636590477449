import {useState, useEffect} from "react";
import {ReviewGroup} from "../../data-types";
import {DataAccess} from "../../util";

export const useReviewGroups = () => {
    const [reviewGroups, setReviewGroups] = useState<ReviewGroup[]>([]);
    const [totalUserCount, setTotalUserCount] = useState<number>(0);
    const [totalCandidateCount, setTotalCandidateCount] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {

        const getReviewGroups = async () => {
            let r = await DataAccess.get("/api/reviewGroup/getList.json")
            setReviewGroups(r.reviewGroups);
            setTotalUserCount(r.totalUsers);
            setTotalCandidateCount(r.totalCandidates);
            setLoading(false);
        }

        getReviewGroups();
    }, []);

    return {
        loading: loading,
        reviewGroups: reviewGroups,
        totalUserCount: totalUserCount,
        totalCandidateCount: totalCandidateCount
    }
}