import React from "react";
import {Box, Typography} from "@mui/material";
import {EditScriptBuilderProps, EditScriptStepState} from "../../data-types";
import {
    Timeline,
    TimelineItem,
    TimelineSeparator,
    TimelineContent,
    TimelineDot,
    TimelineOppositeContent,
} from "@mui/lab";
import {EditScriptBuilderStep} from "./EditScriptBuilderStep";

export function EditScriptBuilder({sequence, swapPosition, removeByPosition, toggleStepSkippable}: EditScriptBuilderProps) {

    // current script length is number of objects in sequence with newPosition !==  null
    // and NOT the total length of the sequence
    const currentScriptLength = sequence.reduce((p, c) => c.newPosition !== null ? p + 1 : p, 0)
    const currentRequired = sequence.reduce((p, c) => c.newPosition !== null && !c.isSkippable ? p + 1 : p, 0);

    const sortFunc = (a: EditScriptStepState, b: EditScriptStepState): number => {
        const aPos = a.newPosition ?? NaN;
        const bPos = b.newPosition ?? NaN;
        return (aPos < bPos) ? -1 : 1;
    }

    const sortedSequence = sequence.sort(sortFunc);

    return (
        <>
            <Typography variant="body1">
                {`${currentScriptLength} prompts`}
            </Typography>
            <Typography variant="subtitle2">
                {`${currentRequired} required`}
            </Typography>
            {currentScriptLength === 0 ? (
                <Box
                    component="div"
                    sx={{
                        height: 400,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        pt: 5,
                        pb: 5,
                        mt: 5,
                        border: "1px dashed white"
                    }}
                >
                    <Typography variant="h6">
                        No Prompts Added
                    </Typography>
                    <Typography variant="body1">
                        Add a video prompt from the library below.
                    </Typography>
                </Box>
            ) : (
                <Box
                    component="div"
                    sx={{
                        mt: 5,
                        height: 400,
                        maxHeight: 400,
                        overflow: 'auto',
                        border: "1px dashed white"
                    }}
                >
                    <Timeline sx={{mt: 5}}>
                        {sortedSequence.map((c, i) =>
                            <EditScriptBuilderStep
                                key={i}
                                step={c}
                                removeByPosition={removeByPosition}
                                totalSteps={currentScriptLength}
                                swapPosition={swapPosition}
                                toggleStepSkippable={toggleStepSkippable}
                            />
                        )}
                    </Timeline>
                    <TimelineItem position={currentScriptLength % 2 === 0 ? "left" : "right"}>
                        <TimelineOppositeContent>
                            <Typography variant="h6">
                                End
                            </Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot />
                        </TimelineSeparator>
                        <TimelineContent />
                    </TimelineItem>
                </Box>
            )}
        </>
    )
}
