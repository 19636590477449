import {useCallback, useEffect, useRef, useState} from "react";
import {RequestStatus} from "../../data-types";

const DEFAULT_VIDEO_TRACK_CONSTRAINTS: MediaTrackConstraints = {
    facingMode: "user",
    width: {
        min: 480,
        ideal: 640,
        max: 800
    },
    height: {
        min: 360,
        ideal: 480,
        max: 600
    },
    aspectRatio: (4 / 3)
};

export const useMediaStream = () => {

    // STREAM AND STATUS
    const stream = useRef<MediaStream | null>(null);
    const [streamStatus, setStreamStatus] = useState<RequestStatus>("loading");

    // ALL DEVICES
    const [audioDevices, setAudioDevices] = useState<MediaDeviceInfo[]>([]);
    const [videoDevices, setVideoDevices] = useState<MediaDeviceInfo[]>([]);

    // CURRENTLY SELECTED DEVICES
    const [selectedAudioDevice, setSelectedAudioDevice] = useState<string | null>(null);
    const [selectedVideoDevice, setSelectedVideoDevice] = useState<string | null>(null);

    const cleanupStreams = useCallback(() => {
        const playbackTracks = stream.current?.getTracks() || [];
        for (const track of playbackTracks) {
            track.stop();
            stream.current?.removeTrack(track);
        }
    }, []);

    const getDevices = useCallback(async () => {
        const devices = await navigator.mediaDevices.enumerateDevices();
        setAudioDevices(devices.filter(c => c.kind === "audioinput"));
        setVideoDevices(devices.filter(c => c.kind === "videoinput"));
    }, []);

    const getStream = useCallback(async (audioConstraints?: MediaTrackConstraints, videoConstraints?: MediaTrackConstraints) => {

        console.log(audioConstraints ? audioConstraints : true);
        stream.current = await navigator.mediaDevices.getUserMedia({
            audio: audioConstraints ? audioConstraints : true,
            video: videoConstraints ? videoConstraints : DEFAULT_VIDEO_TRACK_CONSTRAINTS
        });
    }, []);

    // run on mount
    useEffect(() => {
        getStream()
            .then(_ => {
                return getDevices()
            })
            .then(_ => {
                setStreamStatus("complete");
            })
            .catch(e => {
                setStreamStatus("error");
                console.log(e);
            });

        return cleanupStreams;
    }, [getStream, getDevices, cleanupStreams]);

    const changeDevice = useCallback((audioDeviceId: string | null, videoDeviceId: string | null) => {

        setStreamStatus("loading");

        // new constraints
        const newVideoConstraints: MediaTrackConstraints = videoDeviceId ?
            {...DEFAULT_VIDEO_TRACK_CONSTRAINTS, deviceId: videoDeviceId} :
            {...DEFAULT_VIDEO_TRACK_CONSTRAINTS};

        const newAudioConstraints: MediaTrackConstraints | undefined = audioDeviceId ? {deviceId: audioDeviceId} : undefined;

        // clean up old streams
        cleanupStreams();

        // create new ones
        getStream(newAudioConstraints, newVideoConstraints)
            .then(_ => {
                // set device state
                setSelectedAudioDevice(audioDeviceId);
                setSelectedVideoDevice(videoDeviceId);
                setStreamStatus("complete");
            })
            .catch(e => {
                console.log(e);
                setStreamStatus("error");
            })
    }, [cleanupStreams, getStream]);

    return {
        stream: stream.current,
        streamStatus: streamStatus,
        audioDevices: audioDevices,
        videoDevices: videoDevices,
        selectedAudioDevice: selectedAudioDevice,
        selectedVideoDevice: selectedVideoDevice,
        changeDevice: changeDevice
    }
}
