import React from "react";
import { ReviewerCompetitionCardProps } from "../../data-types";
import {
    Card, CardContent, CardHeader, Box, Avatar,
    Grid2 as Grid, Typography, Button, List, ListItemIcon,
    ListItem, ListItemText, Paper, Chip, useTheme
} from "@mui/material";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import PollIcon from "@mui/icons-material/Poll";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import {parseISO, formatDistance, isAfter} from "date-fns";
import competition_placeholder from "../../img/competition_placeholder.jpg";
import {Link} from "react-router-dom";

export function ReviewerCompetitionCard({competitionMeta, roundStatus, roundMeta, renderResults}: ReviewerCompetitionCardProps) {
    const theme = useTheme();

    let phaseIcon, phaseTextTop, phaseTextBottom, actionButton;

    if (roundStatus) {

        switch (roundStatus.phase) {
            case "review": {
                phaseIcon = <PollIcon />;
                phaseTextTop = "The current round is in review phase.";
                phaseTextBottom = "You don't have anything to do at the moment.";
                break;
            }
            case "response": {
                phaseIcon = <KeyboardVoiceIcon />;
                phaseTextTop = "The current round is in response phase.";
                phaseTextBottom = "You don't have anything to do at the moment.";
                break;
            }
            case "proxy": {
                phaseIcon = <PollIcon />;
                if (roundStatus.roundSubmitted) {
                    phaseTextTop = "All done!";
                    phaseTextBottom = "Thank you for submitting your reviews.";
                } else {
                    // if submitted is not defined, then assignment hasn't occurred.  Indicate 0 reviews completed.
                    // after assignment, indicate how many they have left to do.
                    // phaseTextTop = `${roundStatus.todo!.submitted} / ${roundStatus.todo!.required}  reviews completed.`;
                    phaseTextTop = (roundStatus.todo?.required !== undefined ?
                            `${roundStatus.todo!.submitted} / ${roundStatus.todo!.required} reviews completed.` :
                            "0 reviews completed."
                    )
                    phaseTextBottom = `Due:  ${parseISO(roundStatus.proxyEndDate + "Z").toLocaleString()} (${formatDistance(parseISO(roundStatus.proxyEndDate + "Z"), new Date())})`;
                }
                actionButton =
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={roundStatus.roundSubmitted}
                        sx={{width: "100%"}}
                        component={Link}
                        to={`/reviewer/video-proxy-review/${competitionMeta.competitionId}`}
                    >
                        Review
                    </Button>
                break;
            }
            case "standby": {
                phaseIcon = <HourglassTopIcon />;
                phaseTextTop = "The current round is in standby.";
                phaseTextBottom = "You don't have anything to do at the moment.";
                break;
            }
            case "error": {
                phaseIcon = <ErrorIcon />;
                phaseTextTop = `There is an error with the current event`;
                phaseTextBottom = `Please contact the event organizer`;
                actionButton = null;
                break;
            }
            default: { // finished
                phaseIcon = <CheckCircleIcon />;
                phaseTextTop = `The current round is complete!`;
                phaseTextBottom = `There's nothing left to do at the moment.`;
            }
        }
    }
    else {
        phaseIcon = <ErrorIcon />;
        phaseTextTop = `There are no rounds currently available`;
        phaseTextBottom = `Please wait until the first round is scheduled`;
        actionButton = null;
    }

    return (
        <Card>
            <CardHeader
                avatar={
                    <Avatar
                        src={competition_placeholder}
                        imgProps={{alt: "event-avatar"}}
                    />
                }
                title={competitionMeta.name}
                titleTypographyProps={{
                    variant: "h4"
                }}
            />
            <CardContent sx={{pt: 0}}>
                <Grid container spacing={2}>
                    <Grid size={{xs: 12, md: 7}}>
                        <Grid container spacing={2} justifyContent="center" alignItems="center">
                            <Grid size={{xs: 12}}>
                                <Typography variant="body2" sx={{pl: 7}}>{competitionMeta.description}</Typography>
                            </Grid>
                            <Grid size={{xs: 12}}>
                                <Box sx={{pl: 7}} component="div">
                                    {competitionMeta.tags.map((c, i) => (
                                            <Chip
                                                sx={{mr: 2, bgcolor: theme.palette.primary.light}}
                                                variant="filled"
                                                label={c.name}
                                                key={i}
                                                size="small"
                                            />
                                        )
                                    )}
                                </Box>
                            </Grid>
                            {renderResults ?
                                <Grid size={{xs: 12}}>
                                    <Typography variant="body2" sx={{mt: 4, mb: 3}}>
                                        Proxy Results:
                                    </Typography>
                                    {roundMeta.map((c, i) => (
                                        <Button
                                            key={i}
                                            component={Link}
                                            to={`/reviewer/proxyReviewResults/${c.roundId}`}
                                            variant={(roundStatus.roundNumber === c.roundNumber) ? "contained" : "outlined"}
                                            color="primary"
                                            disabled={!c.hasProxy || isAfter(parseISO(c.proxyStartDate + "Z"), new Date())}
                                            size="small"
                                        >
                                            {`Round ${c.roundNumber}`}
                                        </Button>
                                    ))}
                                </Grid> :
                                null
                            }
                        </Grid>
                    </Grid>
                    <Grid size={{xs: 12, sm: 12, md: 5}} order={{xs: 3, md: 2}}>
                        <List sx={{width: "100%"}}>
                            <Paper elevation={3}>
                                <ListItem
                                    sx={{mb: 1, borderRadius: 1}}
                                    dense={true}
                                >
                                    <ListItemIcon>
                                        {phaseIcon}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={phaseTextTop}
                                        secondary={phaseTextBottom}
                                    />
                                </ListItem>
                            </Paper>
                        </List>
                        {actionButton}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}