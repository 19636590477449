import React from "react";
import {Container, Box, Typography, Button, Paper} from "@mui/material";
import {Link} from "react-router-dom";
import CancelIcon from '@mui/icons-material/Cancel';

export function ErrorComponent() {

    return (
        <Container sx={{mb: 10}} component={Paper} elevation={1}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    p: 5
                }}
                component="div"
            >
                <CancelIcon color="secondary" fontSize="large" />
                <Typography variant="h4" sx={{fontWeight: 'bold', textAlign: 'center', my: 2}}>
                    We encountered an error when loading this content.
                </Typography>
                <Typography variant="subtitle1" sx={{textAlign: 'center'}}>
                    We're sorry for the inconvenience.  If the error persists, please contact support.
                </Typography>
                <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    component={Link}
                    to={"/"}
                    sx={{mt: 5}}
                >
                    Return to Home
                </Button>
            </Box>
        </Container>
    )
}