import React from "react";
import {Grid2 as Grid, Typography, Chip} from "@mui/material";

interface ResultsRoundSelectProps {
    selectedRoundIndex: number;
    roundsAvailableForSelection: number[];
    handleRoundChange: (roundIndex: number) => void;
}

export function ResultsRoundSelect({selectedRoundIndex, roundsAvailableForSelection, handleRoundChange}: ResultsRoundSelectProps) {

    return (
        <Grid container spacing={1} alignItems="center">
            <Grid>
                <Typography variant="subtitle2">
                    Select Round:
                </Typography>
            </Grid>
            {roundsAvailableForSelection.map((c, i) =>
                <Grid key={i}>
                    <Chip
                        variant={i === selectedRoundIndex ? "filled" : "outlined"}
                        label={c}
                        onClick={() => handleRoundChange(i)}
                        color="secondary"
                    />
                </Grid>
            )}
        </Grid>
    )
}
