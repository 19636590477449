import {useEffect, useState} from "react";
import {OptionalScriptStepDetail, RequestStatus} from "../../data-types";
import {DataAccess} from "../../util";

export const useAdminOptionalScriptDetail = (roundId: string | undefined, scriptStepId: string | undefined) => {

    const [requestStatus, setRequestStatus] = useState<RequestStatus>("loading");
    const [optionalScriptStepDetail, setOptionalScriptStepDetail] = useState<OptionalScriptStepDetail>({
        competitionName: "",
        roundNumber: NaN,
        scriptId: NaN,
        scriptName: "",
        isSkippable: false,
        countParticipants: 0,
        completedResponses: 0,
        dueResponses: 0,
        prompts: []
    });

    useEffect(() => {

        const controller = new AbortController();

        const getDetail = async () => {
            if (roundId && scriptStepId) {
                const r = await DataAccess.get(`/api/scriptStep/${roundId}/${scriptStepId}/getOptionalDetail.json`, {signal: controller.signal});
                setOptionalScriptStepDetail(r.optionalScriptStepDetail);
            }
        }

        getDetail()
            .then(_ => {
                setRequestStatus("complete");
            })
            .catch(e => {
                console.log(e);
                setRequestStatus("error");
            })

        return () => {
            controller.abort();
        }
    }, [roundId, scriptStepId])

    return {
        optionalScriptStepDetail: optionalScriptStepDetail,
        requestStatus: requestStatus
    }
}