import React, {ChangeEvent, useState} from "react";
import {Modal, Grid2 as Grid, Box, Typography, List, ListItem, ListItemText, TextField, Divider, Button} from "@mui/material";
import {ReportVideoModalProps} from "../../data-types";
import ErrorIcon from '@mui/icons-material/Error';

export function ReportVideoModal({open, setOpen, reportAction}: ReportVideoModalProps) {

    const [comments, setComments] = useState<string>("");

    const handleCommentChange = (e: ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;
        setComments(value);
    };

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {xs: "100%", sm: "100%", md: "40%"},
        bgcolor: 'background.paper',
        border: '2px solid #000',
        maxHeight: 600,
        overflowY: "auto",
        boxShadow: 24,
        p: 4,
    };

    return (
        <Modal
            open={open}
        >
            <Box component="div" sx={style}>
                <Grid container>
                    <Grid size={{xs: 12}}>
                        <Typography variant="h4">
                            Report Video
                        </Typography>
                        <Divider sx={{my: 2}} />
                    </Grid>
                    <Grid size={{xs: 12}}>
                        <Typography variant="subtitle2">
                            We want our users to feel comfortable when recording and reviewing videos in FocusRing.  However, if you believe this user-submitted video contains any of the following, then you can report it here.
                        </Typography>
                        <List dense={true} disablePadding={true}>
                            <ListItem>
                                <ListItemText primary="- Self-Harm" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="- Violence" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="- Sexual Content" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="- Abusive Behavior" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="- Disclosure of Private Information" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="- Hateful Conduct (use of slurs, tropes, dehumanizing language)" />
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid size={{xs: 12}}>
                        <Typography variant="subtitle2" sx={{mt: 2}}>
                            Please use the box below to enter details as to why you're reporting this video.  You report will be kept anonymous to the user your are reporting.
                        </Typography>
                    </Grid>
                    <Grid size={{xs: 12}} sx={{mt: 2}}>
                        <TextField
                            label="Comments"
                            name="comments"
                            required
                            fullWidth
                            multiline
                            rows={4}
                            value={comments}
                            onChange={handleCommentChange}
                            sx={{mt: 2}}
                        />
                    </Grid>
                    <Grid container size={{xs: 12}} justifyContent="center" alignItems="center" sx={{mt: 4}}>
                        <Grid size={{xs: 12, sm: 12, md: 6}} sx={{textAlign: "center"}}>
                            <Button
                                variant="contained"
                                color="secondary"
                                disabled={comments.length === 0}
                                startIcon={<ErrorIcon />}
                                onClick={() => {
                                    reportAction(comments);
                                    setOpen(false);
                                }}
                            >
                                Submit Report
                            </Button>
                        </Grid>
                        <Grid size={{xs: 12, sm: 12, md: 6}} sx={{textAlign: "center"}}>
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={() => setOpen(false)}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}