import React from "react";
import {NavBar, Footer, FeedbackButton} from "../../components";
import {Outlet} from "react-router";
import {Box} from "@mui/material";

export const WithNav = () => {

    return (
        <Box component="div" sx={{position: "relative", minHeight: "100vh", pb: 10}}>
            <NavBar />
            <FeedbackButton />
            <Box id="mainContent" component="div">
                <Outlet />
            </Box>
            <Footer />
        </Box>
    )
}