import React from "react";
import {Typography, Grid2 as Grid, Box} from "@mui/material";
import ErrorIcon from '@mui/icons-material/Error';

interface SchedulingErrorProps {
    message: string;
}

export function SchedulingError({message}: SchedulingErrorProps) {

    return (
        <Grid
            size={{xs: 12}}
            sx={{
                border: "1px solid #EB0052",
                borderRadius: 2
            }}
        >
            <Box component="div" sx={{display: "flex", alignItems: "center", p: 2}}>
                <ErrorIcon color="secondary" sx={{mr: 2}} />
                <Typography variant="subtitle2">
                    {message}
                </Typography>
            </Box>
        </Grid>
    )
}