import React from "react";
import {Button, Paper, Container, Grid2 as Grid, Typography, Card, CardContent, CircularProgress} from "@mui/material";
import {useEnroll} from "../enroll/useEnroll";
import {OpenCompetitionResult} from "../../data-types";
import {Link} from "react-router-dom";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export function FindCompetitionsHomepage() {
    const {
        requestStatus,
        openCompetitions,
        invitations
    } = useEnroll();

    const content = (o: OpenCompetitionResult[]) => {
        if (requestStatus === "loading") {
            return <CircularProgress color="primary" />;
        } else if (requestStatus === "error") {
            return (
                <Typography variant="subtitle2">
                    There was an error retrieving available enrollments.
                </Typography>
            );
        } else {
            const countInvitations = invitations.current.length;
            const countOpenEnroll = o.length - o.reduce((p, c) => (c.isEnrolled) ? p + 1 : p, 0)
            const countSum = countInvitations + countOpenEnroll;
            if (countOpenEnroll === 0 && countInvitations === 0) {
                return (
                    <Typography variant="h6" component="span">
                        No events are available to join currently.
                    </Typography>
                );
            } else {
                return (
                    <Grid container justifyContent="center" alignItems="center" spacing={2}>
                        <Grid size={{xs: 12, sm: 6}}>
                            {countInvitations > 0 ?
                                <Typography variant="subtitle2" sx={{mb: 1}}>
                                    {`${countInvitations} event invitation${countInvitations > 1 ? "s" : ""}.`}
                                </Typography> :
                                null
                            }
                            {countOpenEnroll > 0 ?
                                <Typography variant="subtitle2">
                                    {`${countOpenEnroll} open event${countOpenEnroll > 1 ? "s" : ""} available.`}
                                </Typography> :
                                null
                            }
                        </Grid>
                        <Grid size={{xs: 12, sm: 6}} sx={{textAlign: "center"}}>
                            <Button
                                component={Link}
                                to="/enroll"
                                variant="contained"
                                color="primary"
                                size="small"
                                fullWidth
                                startIcon={<OpenInNewIcon />}
                            >
                                {`Select Events ${countSum > 0 ? `(${countSum})` : ""}`}
                            </Button>
                        </Grid>
                    </Grid>
                )
            }
        }
    }

    return (
        <Paper component={Container} elevation={0} sx={{pb: 4, minHeight: 230, mb: 5}}>
            <Grid container spacing={2} alignItems="center" justifyContent="space-between" sx={{pb: 4, pt: 2}}>
                <Grid>
                    <Typography variant="h6" component="h2">
                        Join an Event
                    </Typography>
                </Grid>
            </Grid>
            <Card>
                <CardContent>
                    {content(openCompetitions)}
                </CardContent>
            </Card>
        </Paper>
    )
}