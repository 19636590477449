import {useEffect, ChangeEvent, useCallback, useState} from "react";
import {CreateScriptState, Video, CreateScriptStepState} from "../../data-types";
import {DataAccess} from "../../util";

export const useScriptCreate = (scriptId: string | undefined) => {

    const [scriptState, setScriptState] = useState<CreateScriptState>({
        scriptName: "",
        isFinalized: false,
        isPublic: false,
        makePublic: false
    });
    const [scriptSequence, setScriptSequence] = useState<CreateScriptStepState[]>([]);

    useEffect(() => {

        const getExistingScript = async () => {
            if (scriptId) {
                const response = await DataAccess.get(`/api/script/${scriptId}/getDetail.json`);
                let newDate = new Date();
                let date = newDate.getDate();
                let month = newDate.getMonth() + 1;
                let year = newDate.getFullYear();
                setScriptState(prev => {
                    return {...prev, scriptName: `${response.script.scriptName} + (copy) + ${month}/${date}/${year}`}
                });
                // setSelected(response.script.scriptSteps);
            }
        }

        getExistingScript();
    }, [scriptId]);

    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const {value, name, checked} = e.target;
        if (name === "scriptName") {
            setScriptState(prev => {
                return {
                    ...prev,
                    [name]: value
                }
            })
        } else {
            setScriptState(prev => {
                return {
                    ...prev,
                    [name]: checked
                }
            })
        }
    }, []);

    const toggleVideoSelection = useCallback((t: Video) => {
        const exists = scriptSequence.some(c => c.video?.videoId === t.videoId);
        (exists) ?
            setScriptSequence(prev => {
                return prev.filter(p => p.video?.videoId !== t.videoId);
            }) :
            setScriptSequence(prev => {
                // return [...prev, {videoId: t.videoId, isSkippable: false, isOptional: false}];
                return [...prev, {video: t, isSkippable: false, isOptional: false}];
            })
    }, [scriptSequence]);

    // position is 0-based
    const removeStepByIndex = useCallback((index: number) => {
        setScriptSequence(prev => {
            return [...prev.slice(0, index), ...prev.slice(index + 1)];
        })
    }, []);

    const addOptionalStep = useCallback(() => {
        const optionalStep: CreateScriptStepState = {
            isOptional: true,
            isSkippable: false
        };
        setScriptSequence(prev => {
            return [...prev, optionalStep];
        });
    }, []);

    const moveByIndex = useCallback((index: number, delta: number) => {
        setScriptSequence(prev => {
            // noop if oob
            const idx = index + delta;
            if (idx < 0 || idx > prev.length) {
                return prev;
            }

            // swap
            return prev.map((c, i) => {
                if (i === index) {
                    return prev[idx];
                } else if (i === idx) {
                    return prev[index];
                } else {
                    return c;
                }
            })
        })
    }, []);

    const toggleStepSkippable = useCallback((index: number) => {
        setScriptSequence(prev => prev.map(((c, i) => {
            if (index === i) {
                return {...c, isSkippable: !c.isSkippable}
            } else {
                return c;
            }
        })))
    }, []);

    const createScript = useCallback(async (scriptState: CreateScriptState, sequence: CreateScriptStepState[]) => {
        const isScriptValid = async () => {
            if (sequence.length === 0) {
                throw new Error("Please add video prompts to the script");
            }
            if (scriptState.scriptName === "") {
                throw new Error("Please give the script a name.");
            }
            if (sequence.every(c => c.isOptional)) {
                throw new Error("Please add at least 1 non-optional script step.");
            }
        };

        await isScriptValid();

        // map sequence in CreateScriptState to array of:
        // {videoId: id, position: 1, isSkippable: bool}, ... etc
        const scriptData = {
            script: {
                scriptName: scriptState.scriptName,
                sequence: sequence.map((c, i) => {
                    return {
                        videoId: c.video?.videoId ?? -1,
                        position: i + 1,
                        isSkippable: c.isSkippable
                    }
                }),
                isFinalized: scriptState.isFinalized,
                isPublic: scriptState.isPublic,
                makePublic: scriptState.makePublic
            }
        };

        await DataAccess.post("/api/script/create.json", {data: scriptData})
    }, []);

    return {
        scriptState: scriptState,
        handleChange: handleChange,
        createScript: createScript,
        toggleStepSkippable: toggleStepSkippable,
        toggleVideoSelection: toggleVideoSelection,
        scriptSequence: scriptSequence,
        addOptionalStep: addOptionalStep,
        removeStepByIndex: removeStepByIndex,
        moveByIndex: moveByIndex
    }

}