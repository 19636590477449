import React, {useContext, useState} from "react";
import {Checkbox, Box, Button, Divider, Grid2 as Grid, IconButton, List, Modal, Typography, ListItem} from "@mui/material";
import {ModalBox} from "../modal-box";
import {SharedUserNode} from "../../data-types";
import {AccessNode} from "./AccessNode";
import CancelIcon from '@mui/icons-material/Cancel';
import {UserContext} from "../../context";

interface ShareAccessModalProps {
    open: boolean;
    sharedUserNodes: SharedUserNode[];
    onClose: () => void;
    handleRevoke?: (userIds: number[]) => void;
}

export function ShareAccessModal({open, sharedUserNodes, onClose, handleRevoke}: ShareAccessModalProps) {

    const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
    const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
    const {user} = useContext(UserContext);
    const userId = user ? user.userId : -1;

    // get SharedUserNode corresponding to logged-in user (userId).  Database does not return the creator
    // in share access call; if not found we assume this is the creator.  If creator, no need to include
    // firstName, lastName, etc.  just set it arbitrarily to appease type checker.
    const userNode = sharedUserNodes.find(c => c.userId === userId) ??
        {
            userId: userId,
            children: [],
            username: "",
            firstName: "",
            lastName: "",
            email: "",
            roleId: -1,
            sharedBy: -1,
            canShare: true,
            countUsersSharedWith: 0
        }

    const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, checked} = event.target;
        const checkedUserId = parseInt(name);
        if (checked) {
            setSelectedUsers(prev => [...prev, checkedUserId]);
        } else {
            // remove this user and any parents
            let toRemove: number[] = [];
            let sharedBy: number = checkedUserId;
            while (sharedBy !== -1 && sharedBy !== userId) {
                const shared = sharedBy
                toRemove.push(shared);
                sharedBy = sharedUserNodes.find(c => c.userId === shared)?.sharedBy ?? -1;
            }

            // filter parents and checked
            setSelectedUsers(prev => prev.filter(c => !toRemove.includes(c)));
        }
    }

    const handleSelectAll = () => {
        // selected will be all immediate children of user
        const immediateChildren = sharedUserNodes.filter(c => c.sharedBy === userId).map(c => c.userId);
        setSelectedUsers(immediateChildren);
    }

    const handleDeselectAll = () => {
        setSelectedUsers([]);
    }

    // build a tree with currently logged-in user id as root.
    const buildTree = (root: SharedUserNode): SharedUserNode => {
        const sharedUsers: SharedUserNode[] = sharedUserNodes.filter(c => c.sharedBy === root.userId);
        if (!sharedUsers || sharedUsers.length === 0) {
            return root;
        } else {
            const nodes = [];
            for (const n of sharedUsers) {
                nodes.push(buildTree(n));
            }
            return {...root, children: nodes};
        }
    }

    const accessTree: SharedUserNode = buildTree(userNode);

    let selected = [...selectedUsers];
    let i = 0;
    while (i < selected.length) {
        const index = selected[i];
        selected.push(...sharedUserNodes.filter(c => c.sharedBy === index).map(c => c.userId));
        i += 1;
    }

    const allSelected = selected.length === sharedUserNodes.length;

    return (
        <>
            <Modal open={open} onClose={onClose}>
                <ModalBox>
                    <Grid container>
                        <Grid container spacing={2} size={{xs: 12}} justifyContent="space-between" alignItems="center">
                            <Grid>
                                <Typography variant="h6">
                                    Share Access
                                </Typography>
                            </Grid>
                            <Grid>
                                <IconButton
                                    onClick={onClose}
                                >
                                    <CancelIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Grid size={{xs: 12}}>
                            <Divider sx={{my: 2}} />
                        </Grid>
                        <Grid size={{xs: 12}}>
                            {accessTree.children.length === 0 ?
                                <Typography variant="h6">
                                    You have not shared this with anyone.
                                </Typography> :
                                <List dense>
                                    <ListItem
                                        secondaryAction={
                                            <Box component="div" sx={{textAlign: "right"}}>
                                                <Typography variant="subtitle2">
                                                    Toggle All
                                                </Typography>
                                                <Checkbox
                                                    name="toggle-all"
                                                    inputProps={{'aria-label': "Toggle All"}}
                                                    checked={allSelected}
                                                    onChange={allSelected ? handleDeselectAll : handleSelectAll}
                                                />
                                            </Box>
                                        }
                                        sx={{my: 2}}
                                    />
                                    {accessTree.children.map(c =>
                                        <AccessNode
                                            key={c.userId}
                                            node={c}
                                            depth={0}
                                            handleSelect={handleSelect}
                                            selectedUsers={selectedUsers}
                                            parentChecked={false}
                                        />
                                    )}
                                </List>
                            }
                        </Grid>
                        {accessTree.children.length === 0 ?
                            null :
                            <Grid size={{xs: 12}} sx={{textAlign: "right"}}>
                                <Button
                                    disabled={selectedUsers.length === 0}
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<CancelIcon />}
                                    onClick={() => setConfirmModalOpen(true)}
                                >
                                    Revoke Access
                                </Button>
                            </Grid>
                        }
                    </Grid>
                </ModalBox>
            </Modal>
            <Modal open={confirmModalOpen} onClose={() => setConfirmModalOpen(false)}>
                <ModalBox>
                    <Grid container spacing={4}>
                        <Grid size={{xs: 12}}>
                            <Typography variant="h6">
                                Are you sure you want to revoke access?
                            </Typography>
                            <Typography variant="subtitle2">
                                {`This will revoke access for ${selected.length} user(s).`}
                            </Typography>
                        </Grid>
                        <Grid size={{xs: 12, sm: 6}}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                    handleRevoke?.(selectedUsers);
                                    setConfirmModalOpen(false);
                                }}
                            >
                                Revoke
                            </Button>
                        </Grid>
                        <Grid size={{xs: 12, sm: 6}}>
                            <Button
                                fullWidth
                                variant="outlined"
                                color="secondary"
                                onClick={() => setConfirmModalOpen(false)}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </ModalBox>
            </Modal>
        </>
    )
}