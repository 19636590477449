import React from "react";
import {Container, Divider, Grid2 as Grid, Typography, Paper} from "@mui/material";
import {useParams} from "react-router-dom";
import {useScriptStepDetail, usePagination} from "../../hooks";
import {VideoJsPlayerOptions} from "video.js";
import {VideoPlayer, LoadingSpinner, ErrorComponent} from "../../components";
import {ResponseLibrary} from './ResponseLibrary';
import {ResponseCard} from "./ResponseCard";
import {ResponseCardProps} from "../../data-types";

export function AdminScriptStepDetail() {

    const {roundId, scriptStepId} = useParams();
    const {
        requestStatus,
        scriptStepDetail
    } = useScriptStepDetail(roundId, scriptStepId);

    const {
        currentPageNumber,
        pages,
        totalRecords,
        changePage,
        loading,
        requestStatus: pagingRequestStatus
    } = usePagination<ResponseCardProps>(`/api/scriptStep/${roundId}/${scriptStepId}/PAGE_NUMBER/getDetailBody.json`);

    const videoOptions: VideoJsPlayerOptions = {
        autoplay: false,
        controls: true,
        userActions: {hotkeys: true},
        sources: scriptStepDetail.promptSources,
        fill: true,
        html5: {
            nativeTextTracks: false
        }
    }

    if (requestStatus === "loading") {
        return <LoadingSpinner />;
    } else if (requestStatus === "error" || pagingRequestStatus === "error") {
        return <ErrorComponent />;
    } else {
        return (
            <Container sx={{mb: 10}}>
                <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{mb: 5}}>
                    <Grid size={{xs: 12}}>
                        <Typography variant="h4">
                            Script Prompt Detail
                        </Typography>
                        <Divider sx={{my: 3}} />
                    </Grid>
                    <Grid size={{xs: 12, sm: 12, md: 6}}>
                        <Paper elevation={1} sx={{p: 3}}>
                            {
                                scriptStepDetail.competitionName ?
                                    <>
                                        <Typography variant="h6" sx={{mb: 3}}>
                                            {/* Competition name */}
                                            {scriptStepDetail.competitionName}
                                        </Typography>
                                        <Typography variant="subtitle2">
                                            {/* Round number */}
                                            {`Round #${scriptStepDetail.roundNumber}`}
                                        </Typography>
                                        <Typography variant="subtitle2">
                                            {/* Script + link */}
                                            {`Script:  ${scriptStepDetail.scriptName}`}
                                        </Typography>
                                        <Typography variant="subtitle2">
                                            {/* Heat Size */}
                                            {`Heat size:  ${scriptStepDetail.heatSize}`}
                                        </Typography>
                                    </> :
                                    <Typography variant="subtitle2">
                                        This Script is not currently in use.
                                    </Typography>
                            }
                        </Paper>
                    </Grid>
                    <Grid size={{xs: 12, sm: 12, md: 6}}>
                        <VideoPlayer
                            options={videoOptions}
                        />
                    </Grid>
                    <Grid size={{xs: 12}}>
                        <Typography variant="h4">
                            Responses
                        </Typography>
                        <Divider sx={{my: 3}} />
                    </Grid>
                </Grid>
                <ResponseLibrary
                    loading={loading}
                    totalRecords={totalRecords}
                    changePage={changePage}
                    pageNumber={currentPageNumber}
                    recordsPerPage={6}
                >
                    {pages[currentPageNumber]?.map((c, i) =>
                        <ResponseCard {...c}  key={i} />
                    )}
                </ResponseLibrary>
            </Container>
        )
    }
}