import React from "react";
import {EditContinuousBinsProps} from "../../data-types";
import {Box, Button} from "@mui/material";
import {ContinuousBinEditor} from "./ContinuousBinEditor";
import AddCircleIcon from '@mui/icons-material/AddCircle';

export function EditContinuousBins({active, bins, binConfig, addContinuousBin, removeContinuousBin, onChangeContinuousBin}: EditContinuousBinsProps) {

    return (
        <Box sx={{pl: 2}} component="div">
            {bins.map((c, i) =>
                <ContinuousBinEditor
                    key={i}
                    binIndex={i}
                    bin={c}
                    binConfig={binConfig}
                    onChange={onChangeContinuousBin}
                    remove={removeContinuousBin}
                    active={active}
                />
            )}
            <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => addContinuousBin()}
                startIcon={<AddCircleIcon />}
                disabled={!active}
                sx={{mt: 3}}
            >
                Add Bin
            </Button>
        </Box>
    )
}