import React from "react";
import {Container, Grid2 as Grid, Typography, Button} from "@mui/material";
import {useReceipt} from "./useReceipt";
import {useParams} from "react-router-dom";
import {ErrorComponent, LoadingSpinner} from "../../components";
import {parseISO} from "date-fns";

export function Receipt() {

    const {competitionId} = useParams();
    const {
        requestStatus,
        receiptData
    } = useReceipt(competitionId);

    if (requestStatus === "loading") {
        return <LoadingSpinner />;
    } else if (requestStatus === "error") {
        return <ErrorComponent/>;
    } else {

        return (
            <Container sx={{mb: 10}}>
                <Grid container spacing={2}>
                    <Grid size={{xs: 12}}>
                        <Typography variant="h4">
                            Receipt
                        </Typography>
                    </Grid>
                    <Grid size={{xs: 12}}>
                        <Typography variant="subtitle2">
                            Purchase Date:  {parseISO(receiptData.purchaseDate + "Z").toLocaleString()}
                        </Typography>
                    </Grid>
                    <Grid size={{xs: 12}}>
                        <Typography variant="subtitle2">
                            {receiptData.firstName} {receiptData.lastName}
                        </Typography>
                    </Grid>
                    <Grid size={{xs: 12}}>
                        <Typography variant="subtitle2">
                            Card:  XXXX-XXXX-XXXX-{receiptData.lastFourDigits}
                        </Typography>
                    </Grid>
                    <Grid size={{xs: 12}}>
                        <Typography variant="subtitle2">
                            Amount:  ${(receiptData.amount / 100).toFixed(2)}
                        </Typography>
                    </Grid>
                    <Grid size={{xs: 12}}>
                        <Typography variant="subtitle2">
                            Confirmation Id:  {receiptData.confirmationId}
                        </Typography>
                    </Grid>
                    <Grid size={{xs: 12}}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => window.print()}
                        >
                            Print or Save
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        )
    }
}