import {UserProfileState} from "../../data-types";

export const DataValidation = {

    zipCodeRegex: /^\d{5}(?:[-\s]\d{4})?$/,

    emailRegex: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,

    webAddressRegex: /^https?:\/\/[^\s/$.?#].[^\s]*$/,

    // eslint-disable-next-line
    passwordRegex: /^(?=.*[A-Z])(?=.*[!^%()_\-+={}\[\]<>,./?:;"'@#$&*])(?=.*[0-9])(?=.*[a-z]).{12,}$/,

    isUserProfileIncomplete: function(user: UserProfileState): boolean {
        return user.ageBracket === null ||
            !user.regionOrCountry ||
            !user.primaryLanguage ||
            !user.mailingZip ||
            !user.highSchoolZip ||
            user.usResident === null
    },

    isValidZipCode: function(zipCode: string): boolean {
        return this.zipCodeRegex.test(zipCode);
    },

    isValidEmailAddress:  function(email: string): boolean {
        return this.emailRegex.test(email);
    },

    isValidWebAddress: function(webAddress: string): boolean {
        return this.webAddressRegex.test(webAddress);
    },

    isValidPassword: function(s: string): boolean {
        return this.passwordRegex.test(s);
    }
}