import {useState, useCallback} from "react";
import {DataAccess} from "../../util";
import {SharedUser, SharedResourceType, SharedUserNode} from "../../data-types";

export const useShareAccess = (resourceType: SharedResourceType, resourceId?: string) => {

    const [sharedUserNodes, setSharedUserNodes] = useState<SharedUserNode[]>([]);

    const getSharedUsers = useCallback(async () => {
        if (resourceId) {
            const route = (resourceType === "EVENT") ?
                "/api/competition/" :
                resourceType === "VIDEO_PROMPT" ?
                    "/api/videoPrompt/" :
                    "/api/script/";
                const r = await DataAccess.get(route + resourceId + "/getAdmins.json");
                const sharedUsers: SharedUser[] = r.sharedUsers;
                const sharedUserNodes = sharedUsers.map(c => {return {...c, children: []}});
                setSharedUserNodes(sharedUserNodes);
        }
    }, [resourceId, resourceType]);

    const removeSharedUser = useCallback((userIds: number[]) => {
        // remove userIds and all children of userIds
        let toRemove = [...userIds];
        let i = 0;
        while (i < toRemove.length) {
            const index = toRemove[i];
            toRemove.push(...sharedUserNodes.filter(c => c.sharedBy === index).map(c => c.userId))
            i += 1;
        }
        setSharedUserNodes(prev => prev.filter(u => !userIds.includes(u.userId)));
    }, [sharedUserNodes]);

    return {
        sharedUserNodes: sharedUserNodes,
        getSharedUsers: getSharedUsers,
        removeSharedUser: removeSharedUser
    }
}