import {useState, useEffect, useCallback} from "react";
import {Competition, RequestStatus} from "../../data-types";
import {DataAccess} from "../../util";

export const useResults = (competitionId: string | undefined, userId: string | undefined) => {

    const [requestStatus, setRequestStatus] = useState<RequestStatus>("loading");
    const [competition, setCompetition] = useState<Competition>({});
    const [selectedRoundIndex, setSelectedRoundIndex] = useState<number>(NaN);

    useEffect(() => {

        const controller = new AbortController();

        const getResults = async () => {
            const query = (userId) ? `/api/results/${competitionId}/${userId}/results.json` : `/api/results/${competitionId}/results.json`;
            const r: Competition = await DataAccess.get(query, {signal: controller.signal});
            const userScoreData = r.userScoreData;
            setCompetition(r);
            // default to first round if available.  NaN signals no data
            setSelectedRoundIndex((userScoreData && userScoreData.length > 0) ? 0 : NaN);
        }

        getResults()
            .then(_ => setRequestStatus("complete"))
            .catch(e => {
                console.log(e);
                setRequestStatus("error");
            });

        return () => {
            controller.abort();
        }
    }, [competitionId, userId]);

    const handleRoundChange = useCallback((roundIndex: number) => {
        setSelectedRoundIndex(roundIndex);
    }, []);

    return {
        requestStatus: requestStatus,
        competition: competition,
        selectedRoundIndex: selectedRoundIndex,
        handleRoundChange: handleRoundChange
    }
}