import axios from 'axios';

// simple error formatter that handles both api/axios errors
// and client-side errors (i.e. form validation) and provides
// a fall-back message in case where error reason is not expressed
export const formatError = (error: any): string => {
    if (axios.isAxiosError(error)) {
        return (error.response?.data) ? error.response.data as string : "There was an error with your request.";
    } else {
        return error.message || "Something went wrong";
    }
}