import {useParams} from "react-router-dom";
import React, {ChangeEvent, useState} from "react";
import {useEventUserList} from "../../hooks";
import {Container, Grid2 as Grid, MenuItem, TextField, Typography} from "@mui/material";
import {Reviews} from "../reviews";
import {LoadingSpinner, ErrorComponent} from "../../components";

export function AdminUserReviews() {

    const {competitionId} = useParams();
    const [selectedUser, setSelectedUser] = useState<number>(NaN);
    const {
        eventUserList,
        requestStatus
    } = useEventUserList(competitionId);

    const handleUserChange = (e: ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;
        const user = eventUserList.find(c => value === c.username);
        setSelectedUser(user ? user.userId : NaN);
    };

    const selectValue = isNaN(selectedUser) ? "" : eventUserList.find(c => c.userId === selectedUser)!.username;

    if (requestStatus === "loading") {
        return <LoadingSpinner />;
    } else if (requestStatus === "error") {
        return <ErrorComponent />;
    } else {
        return (
            <>
                <Container sx={{mb: 10}}>
                    <Grid container alignItems="center" justifyContent="center" spacing={2}>
                        <Grid>
                            <Typography variant="subtitle2">
                                Select a User
                            </Typography>
                        </Grid>
                        <Grid>
                            <TextField
                                select
                                sx={{width: 275}}
                                label="User"
                                name="user"
                                id="user"
                                onChange={handleUserChange}
                                value={selectValue}
                            >
                                {eventUserList.map((c) =>
                                    <MenuItem key={c.userId} value={c.username} dense>
                                        {`${c.firstName} ${c.lastName} - ${c.username}`}
                                    </MenuItem>
                                )}
                            </TextField>
                        </Grid>
                    </Grid>
                </Container>
                {isNaN(selectedUser) ?
                    null :
                    <Reviews userId={isNaN(selectedUser) ? undefined : selectedUser.toString()} />
                }
            </>
        )
    }
}
