import React, {useState, useCallback, useEffect, useContext} from "react";
import {Box, Button, Container, Grid2 as Grid, Paper, Typography} from "@mui/material";
import {useRecord, useVideoResponseConfig, useVideoResponseSave} from "../../hooks";
import {useNavigate, useParams} from "react-router-dom";
import {ErrorComponent, LoadingSpinner, Webcam,
    RecordingHelpModal, SubmitResponseModal, ViewVideoModal,
    ConfirmActionModal, VideoUploadingModal} from "../../components";
import {ResponseMeta} from "./ResponseMeta";
import {ResponseContent} from "./ResponseContent";
import {VideoSource, RequestStatus} from "../../data-types";
import AlarmIcon from "@mui/icons-material/Alarm";
import {DataFormatting, formatError} from "../../util";
import PublishIcon from "@mui/icons-material/Publish";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {toast} from "react-toastify";
import {UserContext, BrandingContext} from "../../context";

export function VideoResponse() {
    const {competitionId} = useParams();
    const navigate = useNavigate();
    const {setLogoUrl} = useContext(BrandingContext);
    const {user} = useContext(UserContext);
    const {
        time,
        startRecording,
        stopRecording,
        resetRecording,
        isRecording,
        isDataAvailable,
        recordingData
    } = useRecord(60);

    // configures page with all required responses for this round
    const {
        responseRequestStatus,
        metaRequestStatus,
        responseContent,
        responseMeta,
        selectedResponse,
        handleSwitchPrompt,
        handleSaveSuccess
    } = useVideoResponseConfig(competitionId);
    const {saveResponse, submitResponses, uploadProgress, createTranscript} = useVideoResponseSave();
    const [recordedVideoSource, setRecordedVideoSource] = useState<VideoSource>({src: "", type: ""});

    const brandingCallback = useCallback(() => {
        if (user && user.institutionLogo) {
            setLogoUrl(user.institutionLogo);
        } else {
            setLogoUrl(null);
        }
    }, [user, setLogoUrl]);

    // change the navbar logo back to what it was before loading video response page
    useEffect(() => {
        return brandingCallback;
    }, [brandingCallback]);

    // useBeforeUnload(brandingCallback);

    const setRecordingSource = () => {
        if (recordedVideoSource.src !== "") {
            URL.revokeObjectURL(recordedVideoSource.src);
        }
        const mimeType = DataFormatting.getRecordingMimeType();
        const video = new Blob(recordingData.current, {type: mimeType});
        const url = URL.createObjectURL(video);
        setRecordedVideoSource({src: url, type: mimeType.split(";")[0]});
    };

    const submitResponsesAction = () => {
        submitResponses(competitionId)
            .then(_ => {
                toast.success("Responses Submitted Successfully");
                navigate("/myEvents");
            })
            .catch(e => {
                setConfirmSubmitOpen(false);
                toast.error(formatError(e));
            })
    }

    const saveAction = () => {
        // setSubmitLoading(true);
        setSubmitModal(false);
        setUploadingModalOpen(true);
        const mimeType = DataFormatting.getRecordingMimeType();
        saveResponse(selectedResponse, {
            recordingData: recordingData.current,
            isRecording: false,
            isStopped: true,
            time: time,
            mimeType: mimeType
        })
            .then(_ => {
                setUploadStatus("complete");
                setRecordedVideoSource({src: "", type: ""});
                handleSaveSuccess(selectedResponse, recordedVideoSource);
                toast.success("Video Response Saved Successfully");
                resetRecording();
            })
            .then(_ => {
                createTranscript(selectedResponse)
                    .then(_ => {
                        setTranscriptStatus("complete");
                    })
                    .catch((e) => {
                        console.log(e);
                        toast.error("Transcript could not be created on upload.");
                        setTranscriptStatus("error");
                    })
            })
            .catch(e => {
                console.log(e);
                toast.error("There was a problem saving your response, please try again.");
                setUploadingModalOpen(false);
            });
    }

    const openConfirmSubmit = () => {
        setConfirmSubmitOpen(true);
    }

    // modal toggles
    const [submitOpen, setSubmitModal] = useState<boolean>(false);
    const [recordingHelpOpen, setRecordingHelpOpen] = useState<boolean>(false);
    const [viewVideoOpen, setViewVideoOpen] = useState<boolean>(false);
    const [confirmSubmitOpen, setConfirmSubmitOpen] = useState<boolean>(false);

    const [uploadStatus, setUploadStatus] = useState<RequestStatus>("loading");
    const [uploadingModalOpen, setUploadingModalOpen] = useState<boolean>(false);

    const [transcriptStatus, setTranscriptStatus] = useState<RequestStatus>("loading");

    const handleUploadModalClose = useCallback(() => {
        setUploadingModalOpen(false);
        setUploadStatus("loading");
        setTranscriptStatus("loading");
    }, []);

    const retryTranscript = () => {
        setTranscriptStatus("loading");
        createTranscript(selectedResponse)
            .then(_ => {
                setTranscriptStatus("complete");
            })
            .catch(e => {
                console.log(e);
                toast.error("Transcript could not be created on upload.");
            })
    }

    if (metaRequestStatus === "loading") {
        return <LoadingSpinner />;
    } else if (metaRequestStatus === "error") {
        return <ErrorComponent />;
    } else {

        const content = responseContent[selectedResponse];

        return (
            <Container sx={{mb: 10}}>
                <ResponseMeta
                    selectedResponse={selectedResponse}
                    responseMeta={responseMeta}
                    handleSwitchPrompt={handleSwitchPrompt}
                    disableAll={isRecording}
                    resetRecording={resetRecording}
                    submit={openConfirmSubmit}
                />
                <Grid container>
                    <ResponseContent
                        responseRequestStatus={responseRequestStatus}
                        content={content}
                    />
                    {responseRequestStatus === "loading" || responseRequestStatus === "error" ?
                        null :
                        <Grid container size={{xs: 12, sm: 12, md: 6}}>
                            <Paper elevation={0} sx={{p: 2, width: "100%"}}>
                                <Grid size={{xs: 12}}>
                                    <Box component="div" sx={{display: "flex", alignItems: "center", justifyContent: "space-between", m: 2}}>
                                        <Box component="div" sx={{display: "flex", alignItems: "center"}}>
                                            {(content?.videoResponse) ?
                                                <CheckCircleIcon color="primary" /> :
                                                null
                                            }
                                            <Typography variant="h6" sx={{ml: 2}}>
                                                Your Response
                                            </Typography>
                                        </Box>
                                        {(content?.videoResponse) ?
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                onClick={() => {
                                                    setViewVideoOpen(true);
                                                }}
                                            >
                                                See Saved Response
                                            </Button> :
                                            null
                                        }
                                    </Box>
                                </Grid>
                                <Grid size={{xs: 12}}>
                                    <Box component="div" sx={{justifyContent: "center", alignItems: "center", display: "flex", mb: 2}}>
                                        <AlarmIcon sx={{mr: 1}} />
                                        <Typography display="inline" variant="subtitle2" sx={{mr: 1}}>
                                            Time Remaining:
                                        </Typography>
                                        <Typography display="inline" variant="subtitle2" color={(time <= 10) ? "secondary" : "" }>
                                            {DataFormatting.secondsToTimeString(time)}
                                        </Typography>
                                    </Box>
                                    <Webcam
                                        startRecording={startRecording}
                                        stopRecording={stopRecording}
                                        resetRecording={resetRecording}
                                        isRecording={isRecording}
                                        isDataAvailable={isDataAvailable}
                                    />
                                </Grid>
                                <Grid size={{xs: 12}}>
                                    <Box component="div" sx={{mt: 3, height: '100%'}}>
                                        <Box component="div" sx={{textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center", p: 3, mt: 3}}>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                disabled={isRecording || !isDataAvailable}
                                                startIcon={<PublishIcon />}
                                                onClick={() => {
                                                    setRecordingSource();
                                                    setSubmitModal(true)
                                                }}
                                                sx={{width: "50%"}}
                                            >
                                                Review and Save
                                            </Button>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Paper>
                        </Grid>
                    }
                </Grid>
                <SubmitResponseModal
                    videoSource={recordedVideoSource}
                    open={submitOpen}
                    setOpen={setSubmitModal}
                    action={saveAction}
                    modalText="This will overwrite any previous submissions for this prompt."
                    loading={false}
                />
                <RecordingHelpModal open={recordingHelpOpen} setOpen={setRecordingHelpOpen} action={() => {}} />
                <ViewVideoModal
                    videoOptions={(content) ? content.videoResponse : null}
                    open={viewVideoOpen}
                    setOpen={setViewVideoOpen}
                    action={() => {}}
                />
                <ConfirmActionModal
                    open={confirmSubmitOpen}
                    setOpen={setConfirmSubmitOpen}
                    action={submitResponsesAction}
                    confirmActionText="Submit"
                >
                    <Grid container>
                        <Grid size={{xs: 12}}>
                            <Typography variant="h6" sx={{mb: 2}}>
                                Submit Responses
                            </Typography>
                            <Typography variant="subtitle2">
                                Are you sure you want to submit responses?  You will not be able to change your submissions once you confirm.
                            </Typography>
                        </Grid>
                    </Grid>
                </ConfirmActionModal>
                <VideoUploadingModal
                    open={uploadingModalOpen}
                    handleClose={handleUploadModalClose}
                    uploadStatus={uploadStatus}
                    videoUploadProgress={uploadProgress}
                    transcriptStatus={transcriptStatus}
                    retryTranscript={retryTranscript}
                    link={`/responses/${selectedResponse}`}
                />
            </Container>
        )
    }
}