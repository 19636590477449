import {useState, useEffect} from "react";
import {RequestStatus, Review} from "../../data-types";
import {DataAccess} from "../../util";

export const useReviewsOfMe = (competitionId: string | undefined, userId: string | undefined) => {

    const [requestStatus, setRequestStatus] = useState<RequestStatus>("loading");
    const [reviews, setReviews] = useState<Review[]>([]);
    const [competitionName, setCompetitionName] = useState<string>("");

    useEffect(() => {

        const controller = new AbortController();

        const getReviews = async () => {
            const query = (userId) ? `/api/review/${competitionId}/${userId}/getList.json` : `/api/review/${competitionId}/getList.json`;
            const r = await DataAccess.get(query, {signal: controller.signal});
            const reviews: Review[] = r.reviewList.reviews;
            const name = reviews.length > 0 ? reviews[0].competitionName : "";
            setReviews(reviews);
            setCompetitionName(name);
        }

        getReviews()
            .then(_ => setRequestStatus("complete"))
            .catch(e => {
                console.log(e);
                setRequestStatus("error");
            })

        return () => {
            controller.abort();
        }
    }, [competitionId, userId]);

    return {
        requestStatus: requestStatus,
        reviews: reviews,
        competitionName: competitionName
    }
}