import {useState, useEffect} from "react";
import {RequestStatus, AdminResponseDetail} from "../../data-types";
import {DataAccess} from "../../util";

export const useAdminResponseDetail = (responseId: string | undefined) => {

    const [requestStatus, setRequestStatus] = useState<RequestStatus>("loading");
    const [responseDetail, setResponseDetail] = useState<AdminResponseDetail>({
        promptVideoName: "",
        promptVideoUuid: "",
        promptSources: [],
        scriptStepId: -1,
        roundId: -1,
        responderId: -1,
        responderUsername: "",
        responseVideoUuid: "",
        responseSources: []
    });

    useEffect(() => {

        const controller = new AbortController();

        const getDetail = async () => {
            if (responseId) {
                const r = await DataAccess.get(`/api/response/${responseId}/getDetail.json`, {signal: controller.signal});
                setResponseDetail(r.responseDetail);
            }
        }

        getDetail()
            .then(_ => setRequestStatus("complete"))
            .catch(e => {
                console.log(e);
                setRequestStatus("error");
            })

        return () => {
            controller.abort();
        }
    }, [responseId]);

    return {
        requestStatus: requestStatus,
        responseDetail: responseDetail
    }
}