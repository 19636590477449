import * as React from "react";
import { SVGProps } from "react";

const SvgGold5 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={61.92}
    height={61.92}
    xmlSpace="preserve"
    {...props}
  >
    <path
      fill="#FED700"
      d="m50.231 39.694 3.61 7.314 8.072 1.173-5.841 5.693 1.379 8.038-7.22-3.796-7.218 3.796 1.379-8.038-5.84-5.693 8.07-1.173zM11.688 39.694l3.61 7.314 8.071 1.173-5.841 5.693 1.379 8.038-7.219-3.796-7.218 3.796 1.379-8.038-5.841-5.693 8.071-1.173zM11.688.007l3.61 7.314 8.071 1.173-5.841 5.693 1.379 8.039-7.219-3.796-7.218 3.796 1.379-8.039L.008 8.494l8.071-1.173zM50.231.007l3.61 7.314 8.072 1.173-5.841 5.693 1.379 8.039-7.22-3.796-7.218 3.796 1.379-8.039-5.84-5.693 8.07-1.173zM30.96 19.851l3.609 7.314 8.073 1.172-5.841 5.693 1.379 8.039-7.22-3.797-7.219 3.797 1.379-8.039-5.84-5.693 8.071-1.172z"
    />
  </svg>
);

export default SvgGold5;
