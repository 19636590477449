import React from "react";
import {useTheme, Grid2 as Grid, Divider, Paper, Card, CardHeader,
    CardContent, Typography, Box, Chip, Button, Avatar,
    List, ListItem, ListItemIcon, ListItemText, Tooltip
    } from "@mui/material";
import {Link} from "react-router-dom";
import {OpenCompetitionResult} from "../../data-types";
import {TypographyInlineLinks} from "../../components";
import competition_placeholder from "../../img/competition_placeholder.jpg";
import {parseISO, formatDistance, isAfter, format, subHours} from "date-fns";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import InfoIcon from '@mui/icons-material/Info';
import ErrorIcon from '@mui/icons-material/Error';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import NotesIcon from '@mui/icons-material/Notes';

interface OpenCompetitionCardProps {
    competition: OpenCompetitionResult;
    initEnroll: (competitionId: number) => void;
}

export function OpenCompetitionCard({competition, initEnroll}: OpenCompetitionCardProps) {

    const theme = useTheme();

    let now = new Date();
    let enrollUntil: string = "";
    let firstRoundStartDate: Date;
    let firstRoundEndDate: Date;
    let firstRoundStatus: string;
    let enrollmentWarning: boolean = false;
    let enrollmentUnavailable = competition.firstRoundResponseEnd !== null &&
        isAfter(now, subHours(parseISO(competition.firstRoundResponseEnd + "Z"), 3)) &&
        !!competition.paymentParticipant;
    if (competition.firstRoundStart !== null) {
        firstRoundStartDate = parseISO(competition.firstRoundStart + "Z");
        // if first round start is after now, then tell users it will be available in N time.
        // Else they can respond now.
        firstRoundStatus = (isAfter(firstRoundStartDate, now)) ?
            `Enrollment available!  The event begins in ${formatDistance(firstRoundStartDate, now)}.` :
            `Enroll and record your responses now!`;
    } else {
        // if firstRoundStart is null, then indicate that users will receive alert when responses available
        firstRoundStatus = "Enrollment available!  We will notify you when the first round begins."
    }

    if (enrollmentUnavailable) {
        firstRoundStatus = "Enrollment unavailable";
    }

    if (competition.firstRoundResponseEnd !== null) {
        firstRoundEndDate = parseISO(competition.firstRoundResponseEnd + "Z");
        // if there is payment for this event, then users are not allowed to enroll 3 hours before
        // end of first round response start.  They should also be warned 24 hours before the end
        // of the first round response phase
        if (competition.paymentParticipant) {
            enrollUntil = `Enroll until:  ${format(subHours(firstRoundEndDate, 3), "Pp")}`;
            if (isAfter(now, subHours(firstRoundEndDate, 3))) {
                enrollUntil = "";
            } else if (isAfter(now, subHours(firstRoundEndDate, 24))) {
                enrollmentWarning = true;
            }
        } else {
            enrollUntil = `Enroll until:  ${format(firstRoundEndDate, "Pp")}`;
        }
    }

    if (competition.isEnrolled) {
        firstRoundStatus = "You are enrolled in this event.";
    }

    return (
        <Grid size={{xs: 12}}>
            <Card>
                <CardHeader
                    avatar={
                        <Avatar
                            src={competition_placeholder}
                            alt="event-avatar"
                        />
                    }
                    disableTypography
                    title={
                        <Typography variant="h5">
                            {competition.name}
                        </Typography>
                    }
                    subheader={competition.paymentParticipant ?
                        <Box component="div" sx={{display: "flex", alignItems: "center", mt: 1}}>
                            {enrollmentWarning ?
                                <Tooltip
                                    enterTouchDelay={300}
                                    title="Please note, responses are due in less than 24 hours.  You will be ineligible for a refund if you enroll and don't complete the responses."
                                >
                                    <ErrorIcon color="warning" sx={{mr: 1}} />
                                </Tooltip> :
                                null
                            }
                            <Typography variant="subtitle2">
                                {`Event fee: $${(competition.paymentParticipant / 100).toFixed(2)}`}
                            </Typography>
                        </Box> :
                        null
                    }
                />
                <CardContent>
                    <Grid container justifyContent="space-between" alignItems="center" columnSpacing={3} >
                        <Grid size={{xs: 12, sm: 12, md: 7}}>
                            <List sx={{pl: {xs: 0, sm: 0, md: 5}}}>
                                {(competition.accessibility === "email-whitelist") ?
                                    <Paper elevation={2} sx={{mb: 1}}>
                                        <ListItem dense>
                                            <ListItemIcon>
                                                <CheckCircleIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={`Open for email addresses ending in: ${competition.whitelist.join(', ')}`}/>
                                        </ListItem>
                                    </Paper> :
                                    null
                                }
                                {competition.description ?
                                    <Paper elevation={2} sx={{mb: 1}}>
                                        <ListItem dense>
                                            <ListItemIcon>
                                                <NotesIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={competition.description}
                                                primaryTypographyProps={{variant: "subtitle2"}}
                                            />
                                        </ListItem>
                                    </Paper> :
                                    null
                                }
                                {competition.prizeDescription ?
                                    <Paper elevation={2} sx={{mb: 1}}>
                                        <ListItem dense>
                                            <ListItemIcon>
                                                <EmojiEventsIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                disableTypography
                                                sx={{whiteSpace: "pre-wrap"}}
                                                primary={
                                                    <TypographyInlineLinks text={competition.prizeDescription} variant="subtitle2" />
                                                }
                                            />
                                        </ListItem>
                                    </Paper> :
                                    null
                                }
                            </List>
                        </Grid>
                        <Grid size={{xs: 12, sm: 12, md: 5}} sx={{textAlign: "center"}}>
                            <List>
                                <Paper elevation={3}>
                                    <ListItem
                                        sx={{mb: 1, borderRadius: 1}}
                                        dense={true}
                                    >
                                        <ListItemIcon>
                                            {competition.isEnrolled ?
                                                <CheckCircleIcon /> :
                                                <InfoIcon />
                                            }
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={firstRoundStatus}
                                            secondary={enrollUntil}
                                        />
                                    </ListItem>
                                </Paper>
                            </List>
                            {competition.isEnrolled ?
                                <Button
                                    component={Link}
                                    to="/myEvents"
                                    color="primary"
                                    variant="contained"
                                    fullWidth
                                    startIcon={<OpenInNewIcon />}
                                >
                                    Go To Events
                                </Button> :
                                <Button
                                    color="primary"
                                    variant="contained"
                                    fullWidth
                                    disabled={enrollmentUnavailable}
                                    onClick={() => initEnroll(competition.competitionId)}
                                    startIcon={<AddCircleIcon />}
                                >
                                    Enroll
                                </Button>
                            }
                        </Grid>
                        {competition.tags.length ?
                            <Grid size={{xs: 12}}>
                                <Divider sx={{my: 3}} />
                                <Box component="div" sx={{pl: {xs: 0, sm: 0, md: 7}}}>
                                    <Typography variant="subtitle2" sx={{mb: 1}}>
                                        Tags:
                                    </Typography>
                                    {competition.tags.map((c, i) => (
                                            <Chip
                                                sx={{mr: 2, bgcolor: theme.palette.primary.light}}
                                                variant="filled"
                                                label={c.name}
                                                key={i}
                                                size="small"
                                            />
                                        )
                                    )}
                                </Box>
                            </Grid> :
                            null
                        }
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    )
}