import {ChangeEvent, useCallback, useState} from "react";
import {DataAccess} from "../../util";

export const useScriptShare = () => {

    const [toShareWith, setToShareWith] = useState<string>("");
    const [canShare, setCanShare] = useState<boolean>(true);
    const [shareVideos, setShareVideos] = useState<boolean>(true);

    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const {value, name, checked} = e.target;
        if (name === "canShare") {
            setCanShare(checked);
        } 
        else if (name === "shareVideos") {
            if (!checked) {
                // if shareVideos set to false, then also set canShare to false
                setCanShare(false);
            }
            setShareVideos(checked);            
        }
        else {
            setToShareWith(value);
        }
    }, []);

    const submitShare = useCallback(async (toShareWith: string, scriptId: number, canShare: boolean, shareVideos: boolean) => {
        const payload = {emailToShareWith: toShareWith, canShare: canShare, shareVideos: shareVideos};
        await DataAccess.post(`/api/script/${scriptId}/share.json`, {data: payload});
    }, []);

    const reset = useCallback(() => {
        setCanShare(true);
        setShareVideos(false);
        setToShareWith("");
    }, []);

    return {
        toShareWith: toShareWith,
        canShare: canShare,
        shareVideos: shareVideos,
        handleChangeShare: handleChange,
        submitShare: submitShare,
        resetShare: reset
    }
}