import React from "react"
import {MessageDrawerProps} from "../../data-types";
import {useTheme, Grid2 as Grid, Button, Box, Drawer, List, ListItem, ListItemText, ListItemIcon} from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import {NotificationItem} from "../.";

export function MessageDrawer({notifications, open, setClose, handleRead, handleDelete, handleReadAll, handleDeleteAll}: MessageDrawerProps) {
    const theme = useTheme();

    const disableAllRead = notifications.every(n => n.isRead);

    return (
            <Drawer
                anchor="right"
                open={open}
                onClose={setClose}
                elevation={0}
            >
                <Box
                    sx={{width: 325}}
                    role="presentation"
                    component="div"
                >
                    <List disablePadding={true}>
                        <ListItem
                            divider
                            sx={{
                                bgcolor: theme.palette.background.default,
                                pt: 3,
                                pb:3,
                            }}
                            secondaryAction={
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="small"
                                    onClick={setClose}
                                >
                                    Close
                                </Button>
                            }
                        >
                            <ListItemIcon sx={{mr: 3}}>
                                <MailIcon />
                            </ListItemIcon>
                            <ListItemText primary="Notifications"/>
                        </ListItem>
                        <ListItem
                            divider
                            sx={{
                                bgcolor: theme.palette.background.default,
                                pt: 3,
                                pb:3,
                            }}
                        >
                            <Grid container spacing={1} justifyContent="space-around" alignItems="center">
                                <Grid>
                                    <Button
                                        onClick={handleReadAll}
                                        variant="outlined"
                                        size="small"
                                        disabled={disableAllRead}
                                    >
                                        Read All
                                    </Button>
                                </Grid>
                                <Grid>
                                    <Button
                                        onClick={handleDeleteAll}
                                        variant="outlined"
                                        color="secondary"
                                        size="small"
                                        disabled={notifications.length === 0}
                                    >
                                        Delete All
                                    </Button>
                                </Grid>
                            </Grid>
                        </ListItem>
                        {notifications.map((c, i) => (
                            <NotificationItem
                                key={i}
                                data={c}
                                handleRead={handleRead}
                                handleDelete={handleDelete}
                            />
                        ))}
                    </List>
                </Box>
            </Drawer>
    )
}