import React from "react";
import {Typography, Box, Button} from "@mui/material";
import {Link} from "react-router-dom";

export const NotFound = () => {

    return (
        <Box component="div"
             sx={{
                 height: "50%",
                 width: "100%",
                 zIndex: 3,
                 backgroundColor: "#202020",
                 display: "flex",
                 flexDirection: "column",
                 alignItems: "center",
                 justifyContent: "center",
                 position: "absolute"
             }}
        >
            <Typography variant="h3" sx={{fontWeight: 'bold', textAlign: 'center'}}>
                404: Not Found.
            </Typography>
            <Typography variant="subtitle1" sx={{textAlign: 'center'}}>
                Sorry, this page doesn't exist.
            </Typography>
            <Button
                variant="contained"
                color="secondary"
                size="small"
                component={Link}
                to={"/"}
                sx={{mt: 5}}
            >
                Return to Home
            </Button>
        </Box>
    )
}
