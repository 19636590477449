import React from "react";
import {Modal, Box, Grid2 as Grid, Typography, Button, Divider, TextField, FormGroup, FormControlLabel, Checkbox} from "@mui/material";
import {ShareableScriptModalProps} from "../../data-types";

export function ShareScriptModal({open, setOpen, action, value, handleChange, canShare, shareVideos, isFinalized}: ShareableScriptModalProps) {

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {xs: "100%", sm: "100%", md: "50%"},
        maxHeight: 500,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflowY: 'auto'
    }

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
        >
            <Box sx={style} component="div">
                <Grid container>
                    <Grid size={{xs: 12}}>
                        <Typography variant="h6">
                            Share this script
                        </Typography>
                        {!isFinalized ?
                            <Typography variant="subtitle2" display="block">
                                Warning: User cannot use this script (in an event) until it is marked 'closed to modifications'.
                            </Typography> :
                            null
                        }
                        <Typography variant="subtitle2">
                            Enter an email address of an admin to share with.
                        </Typography>
                        <Divider sx={{my: 3}} />
                    </Grid>
                    <Grid size={{xs: 12}}>
                        <TextField
                            required
                            label="Share with admin"
                            fullWidth
                            name="emailToShareWith"
                            value={value}
                            onChange={handleChange}
                            sx={{mb: 3}}
                        />
                        <FormGroup>
                            <FormControlLabel
                                sx={{mb: 0}}
                                control={
                                    <Checkbox
                                        checked={canShare}
                                        onChange={handleChange}
                                        disabled={!shareVideos}
                                        name="canShare"
                                    />
                                }
                                label="Allow this user to share with others?"
                            />
                            <FormControlLabel
                                sx={{mb: 3}}
                                control={
                                    <Checkbox
                                        checked={shareVideos}
                                        onChange={handleChange}
                                        name="shareVideos"
                                    />
                                }
                                label="Share all the videos in this script that the user doesn't have access to with them?"
                            />
                        </FormGroup>
                    </Grid>
                    <Grid size={{xs: 12, sm: 6}} sx={{textAlign: "center"}}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => action()}
                        >
                            Share
                        </Button>
                    </Grid>
                    <Grid size={{xs: 12, sm: 6}} sx={{textAlign: "center"}}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => setOpen(false)}
                        >
                            Cancel
                        </Button>
                    </Grid>
                </Grid>

            </Box>
        </Modal>
    )
}