import React from "react";
import {Box, Typography} from "@mui/material";
import {ScriptBuilderProps} from "../../data-types";
import {
    Timeline,
    TimelineItem,
    TimelineSeparator,
    TimelineContent,
    TimelineDot,
    TimelineOppositeContent,
} from "@mui/lab";
import {ScriptBuilderStep} from "./ScriptBuilderStep";

export function ScriptBuilder({sequence, removeStep, toggleStepSkippable, moveByIndex}: ScriptBuilderProps) {

    return (
        <>
            <Typography variant="body1">
                {sequence.length === 1 ? "1 prompt" : `${sequence.length} prompts`}
            </Typography>
            <Typography variant="subtitle2">
                {`${sequence.reduce((p, c) => c.isSkippable ? p : p + 1, 0)} required`}
            </Typography>
            {sequence.length === 0 ? (
                <Box
                    component="div"
                    sx={{
                        height: 400,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        pt: 5,
                        pb: 5,
                        mt: 5,
                        border: "1px dashed white"
                    }}
                >
                    <Typography variant="h6">
                        No Prompts Added
                    </Typography>
                    <Typography variant="body1">
                        Add a video prompt from the library below.
                    </Typography>
                </Box>
            ) : (
                <Box
                    component="div"
                    sx={{
                        mt: 5,
                        height: 400,
                        maxHeight: 400,
                        overflow: 'auto',
                        border: "1px dashed white"
                    }}
                >
                    <Timeline sx={{mt: 5}}>
                        {sequence.map((c, i) =>
                            <ScriptBuilderStep
                                step={c}
                                totalSteps={sequence.length}
                                key={i}
                                index={i}
                                remove={removeStep}
                                toggleSkippable={toggleStepSkippable}
                                moveByIndex={moveByIndex}
                            />
                        )}
                    </Timeline>
                    <TimelineItem position={sequence.length % 2 === 0 ? "left" : "right"}>
                        <TimelineOppositeContent>
                            <Typography variant="h6">
                                End
                            </Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot />
                        </TimelineSeparator>
                        <TimelineContent />
                    </TimelineItem>
                </Box>
            )}
        </>
    )
}
