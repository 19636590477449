import axios, {AxiosRequestConfig} from 'axios';
import {PostData, PutData} from "../../data-types";
import {UrlConfig} from "../";

export const DataAccess = {

    requestConfig: function(): AxiosRequestConfig {
        return {
            headers: {
                "App-Base-Url": UrlConfig.getAppBaseUrl()
            }
        }
    },

    get: async function(url: string, config?: AxiosRequestConfig) {
        let response = await axios.get(UrlConfig.buildUrl(url), {...config, ...this.requestConfig()});
        return response.data;
    },

    post: async function(url: string, {data}: PostData, config?: AxiosRequestConfig) {
        let response = await axios.post(UrlConfig.buildUrl(url), data, {...config, ...this.requestConfig()});
        return response.data;
    },

    put: async function(url: string, {data}: PutData, config?: AxiosRequestConfig) {
        let response = await axios.put(UrlConfig.buildUrl(url), data, {...config, ...this.requestConfig()});
        return response.data;
    },

    // post method for form data encoded as a string
    // i.e. "username=user&password=pw"
    postFormString: async function(url: string, formString: string) {
        let response = await axios.post(UrlConfig.buildUrl(url), formString);
        return response.data;
    },

    delete: async function(url: string) {
        let response = await axios.delete(UrlConfig.buildUrl(url));
        return response.data;
    },

    handleError: function(error: any): string {
        if (axios.isAxiosError(error)) {
            return (error.response?.data) ? error.response.data as string : "There was an error with your request.";
        } else {
            return error.message || "Something went wrong";
        }
    }
}