import {useState, useEffect} from "react";
import {RequestStatus} from "../../data-types";
import {DataAccess} from "../../util";

interface LegalAgreement {
    legalAgreementUuid: string;
    legalAgreementFilename: string;
}

export const useLegalAgreements = () => {

    const [requestStatus, setRequestStatus] = useState<RequestStatus>("loading");
    const [legalAgreements, setLegalAgreements] = useState<LegalAgreement[]>([]);

    useEffect(() => {

        const controller = new AbortController();

        const getLegalAgreements = async () => {
            const r = await DataAccess.get("/api/competition/getMyLegalAgreements.json", {signal: controller.signal});
            setLegalAgreements(r.legalAgreements);
        }

        getLegalAgreements()
            .then(_ => setRequestStatus("complete"))
            .catch(e => {
                console.log(e);
                setRequestStatus("error");
            })

        return () => {
            controller.abort();
        }
    }, []);

    return {
        legalAgreements: legalAgreements,
        requestStatus: requestStatus
    }
}