import {createContext} from "react";

interface IMarketingContext {
    marketingId: string | null;
    setMarketingId: (m: string | null) => void;
}
export const MarketingContext = createContext<IMarketingContext>({
    marketingId: null,
    setMarketingId: (m: string | null) => {}
});
