import {useEffect, ChangeEvent, useCallback, useState} from "react";
import {
    Video,
    ScriptStep,
    RequestStatus,
    EditScriptStepState,
} from "../../data-types";
import {DataAccess} from "../../util";

export const useScriptEdit = (scriptId: string | undefined) => {

    const [isPublic, setIsPublic] = useState<boolean>(false);
    const [makePublic, setMakePublic] = useState<boolean>(false);
    const [shareVideos, setShareVideos] = useState<boolean>(true);
    const [requestStatus, setRequestStatus] = useState<RequestStatus>("loading");
    const [editedSequence, setEditedSequence] = useState<EditScriptStepState[]>([]);

    useEffect(() => {

        const controller = new AbortController();

        const getExistingScript = async () => {
            if (scriptId) {
                const response = await DataAccess.get(`/api/script/${scriptId}/getDetail.json`, {signal: controller.signal});
                // take scriptSteps and map to EditScriptStepState
                const scriptSteps: ScriptStep[] = response.script.scriptSteps;
                const stepStates: EditScriptStepState[] = scriptSteps.map<EditScriptStepState>(c => {
                    return {
                        isDecisionScriptStep: c.isDecisionScriptStep,
                        isSkippable: c.isSkippable,
                        videoId: c.videoId ? c.videoId : -1,
                        position: c.position,
                        newPosition: c.position,
                        // stuff ScriptStep as Video
                        video: c.videoId ? c as Video : undefined
                    }
                })

                setIsPublic(response.script.isPublic);
                setEditedSequence(stepStates);
            }
        }

        getExistingScript()
            .then(_ => {
                setRequestStatus("complete");
            })
            .catch(e => {
                setRequestStatus("error");
                console.log(e);
            });

        return () => {
            controller.abort();
        }
    }, [scriptId]);

    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === 'makePublic') {
            setMakePublic(e.target.checked);
        } else {
            setShareVideos(e.target.checked);
        }
    }, []);

    // this is only called in the VideoLibrary component.  So we can be sure
    // input t is a video the user has access to and is NOT an optional step
    const toggleVideoSelection = useCallback((t: Video) => {
        const exists = editedSequence.some(c => c.videoId === t.videoId);
        const length = editedSequence.reduce((p, c) => c.newPosition !== null ? p + 1 : p, 0);
        (exists) ?
            setEditedSequence(prev => {
                return prev.map(c => {
                    if (c.videoId === t.videoId) {
                        return {
                            ...c,
                            newPosition: (c.newPosition === null) ? length + 1 : null
                        }
                    } else {
                        return c;
                    }
                })
            }) :
            setEditedSequence(prev => {
                return [
                    ...prev,
                    {videoId: t.videoId, isSkippable: false, isDecisionScriptStep: false, position: null, newPosition: length + 1, video: t}];
            })
    }, [editedSequence]);


    // adds a new one to the end
    const addOptionalStep = useCallback(() => {
        setEditedSequence(prev => {
            const length = prev.reduce((p, c) => c.newPosition !== null ? p + 1 : p, 0);
            return [...prev,
                {videoId: -1, isSkippable: false, isDecisionScriptStep: true, position: null, newPosition: length + 1}
            ]
        })
    }, []);

    const toggleStepSkippable = useCallback((newPosition: number) => {
        setEditedSequence(prev => prev.map(((c, i) => {
            if (newPosition === c.newPosition) {
                return {...c, isSkippable: !c.isSkippable}
            } else {
                return c;
            }
        })))
    }, []);

    const removeByPosition = useCallback((newPosition: number) => {
        setEditedSequence(prev => {
            return prev.map(c => {
                if (c.newPosition === null) {
                    return c;
                }
                if (c.newPosition === newPosition) {
                    return {...c, newPosition: null}
                }
                if (c.newPosition > newPosition) {
                    return {...c, newPosition: c.newPosition - 1}
                } else {
                    return c;
                }
            })
        })
    }, []);

    const swapPosition = useCallback((oldPosition: number, delta: number) => {
        setEditedSequence(prev => {
            // current length of sequence with newPosition not null
            const length = prev.reduce((p, c) => c.newPosition !== null ? p + 1 : p, 0);
            // noop if oob
            const targetPosition = oldPosition + delta;
            if (targetPosition <= 0 || targetPosition > length) {
                return prev;
            } else {
                // swap
                return prev.map(c => {
                    if (c.newPosition === targetPosition) {
                        return {...c, newPosition: oldPosition}
                    }
                    if (c.newPosition === oldPosition) {
                        return {...c, newPosition: targetPosition}
                    }
                    return c;
                })
            }
        })
    }, []);

    const editScript = useCallback(async (scriptId: number, makePublic: boolean, shareVideos: boolean, sequence: EditScriptStepState[]) => {
        const isScriptValid = async () => {
            const allWithNewPosition = sequence.filter(c => c.newPosition !== null);
            if (allWithNewPosition.length === 0) {
                throw new Error("Please add video prompts to the script");
            }
            // are there any with isDecisionScriptStep === null
            if (allWithNewPosition.every(c => c.isDecisionScriptStep)) {
                throw new Error("All scripts are optional.  Please add non-optional steps.");
            }
        };

        await isScriptValid();

        const scriptData = {
            script: {
                sequence: sequence,
                makePublic: makePublic,
                shareVideos: shareVideos,
            }
        };

        await DataAccess.post(`/api/script/${scriptId}/update.json`, {data: scriptData})
    }, []);

    return {
        isPublic: isPublic,
        makePublic: makePublic,
        shareVideos: shareVideos,
        handleChange: handleChange,
        editScript: editScript,
        toggleVideoSelection: toggleVideoSelection,
        requestStatus: requestStatus,
        editedSequence: editedSequence,
        toggleStepSkippable: toggleStepSkippable,
        removeByPosition: removeByPosition,
        addOptionalStep: addOptionalStep,
        swapPosition: swapPosition
    }

}