import {useState, useEffect} from "react";
import {DataAccess} from "../../util";
import {RequestStatus, Institution} from "../../data-types";

export const useInstitutions = () => {

    const [requestStatus, setRequestStatus] = useState<RequestStatus>("loading");
    const [institutions, setInstitutions] = useState<Institution[]>([]);

    useEffect(() => {
        const controller = new AbortController();

        const getInstitutions = async () => {
            const r = await DataAccess.get("/api/institution/list.json");
            setInstitutions(r.institutionList.institutions);
        }

        getInstitutions()
            .then(_ => {
                setRequestStatus("complete");
            })
            .catch(e => {
                console.log(e);
                setRequestStatus("error");
            })

        return () => {
            controller.abort();
        }
    }, []);

    return {
        requestStatus: requestStatus,
        institutions: institutions
    }
}