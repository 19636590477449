import {useState, useEffect} from "react";
import {RequestStatus, ScriptType, Script} from "../../data-types";
import {DataAccess} from "../../util";


export const useScripts = (scriptType: ScriptType) => {

    const [scripts, setScripts] = useState<Script[]>([]);
    const [requestStatus, setRequestStatus] = useState<RequestStatus>("loading");

    useEffect(() => {

        const controller = new AbortController();
        const getScripts = async () => {
            let scriptRoute: string;

            if (scriptType === "created") {
                scriptRoute = "/api/script/getList.json";
            } else if (scriptType === "shared") {
                scriptRoute = "/api/script/getSharedList.json";
            } else {
                scriptRoute = "/api/script/getPublicList.json";
            }

            let r = await DataAccess.get(scriptRoute);
            setScripts(r.scripts);
        }

        getScripts()
            .then(_ => setRequestStatus("complete"))
            .catch(e => {
                console.log(e);
                setRequestStatus("error");
            })

        return () => {
            controller.abort();
        }
    }, [scriptType]);

    return {
        scripts: scripts,
        requestStatus: requestStatus
    }
}