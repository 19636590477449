import React from "react";
import {Modal, Box, Grid2 as Grid, Typography, Button} from "@mui/material";
import {ReviewGroupModalProps} from "../../data-types";
import {DataAccess} from "../../util";
import {useNavigate} from "react-router-dom";

export function DeleteReviewGroupModal({reviewGroup, setReviewGroup}: ReviewGroupModalProps) {

    const navigate = useNavigate();
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const submit = () => {
        if (reviewGroup) {
            DataAccess.delete(`/api/reviewGroup/${reviewGroup.groupId}/delete.json`)
                .then(_ => {
                    setReviewGroup(null);
                    navigate(0);
                })
                .catch(e => {
                    console.log(e);
                })
        }
    }

    return (
        <Modal
            open={!!reviewGroup}
            onClose={() => setReviewGroup(null)}
        >
            <Box sx={style} component="div">
                <Grid container spacing={2} direction="column">
                    <Grid size={{xs: 12}}>
                        <Typography variant="h5">
                            {reviewGroup ? `Delete:  ${reviewGroup.groupName}` : ``}
                        </Typography>
                    </Grid>
                    <Grid size={{xs: 12}}>
                        <Typography variant="body1">
                            Are you sure you want to delete this group?  Reviewers will not be deleted; they will only be disassociated from this group.
                        </Typography>
                    </Grid>
                    <Grid size={{xs: 6}} sx={{textAlign: 'center'}}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => submit()}
                        >
                            Submit
                        </Button>
                    </Grid>
                    <Grid size={{xs: 6}} sx={{textAlign: 'center'}}>
                        <Button
                            color="secondary"
                            variant="outlined"
                            onClick={() => setReviewGroup(null)}
                        >
                           Cancel
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}