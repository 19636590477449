import React, {useContext, useState} from "react";
import {Button, Divider, Typography, Grid2 as Grid, Container, TextField, MenuItem} from "@mui/material";
import {Link} from "react-router-dom";
import {
    NoDataCard,
    AdminCompetitionCard,
    LoadingSpinner,
    ShareModal,
    ConfirmActionModal,
    ErrorComponent
} from "../../components";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {useCompetitionDelete, useCompetitions, useCompetitionShare} from "../../hooks";
import {toast} from "react-toastify";
import {formatError} from "../../util";
import {parseISO, isBefore, isAfter} from "date-fns";
import {AdminCompetitionMeta, Competition} from "../../data-types";
import {UserContext} from "../../context";

type AdminEventFilter = "response" | "review" | "proxy" | "none" | "startingSoon" | "finished" | "active";
type AdminEventSort = "none" | "alphabetical" | "countUsers";

export function AdminCompetitions() {

    const {user} = useContext(UserContext);
    const {competitions, requestStatus, removeCompetition} = useCompetitions(true);
    const [selectedCompetition, setSelectedCompetition] = useState<number | null>(null);
    const [shareModalOpen, setShareModalOpen] = useState<boolean>(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const {
        handleChange,
        submitShare,
        toShareWith
    } = useCompetitionShare();
    const {deleteCompetition} = useCompetitionDelete();
    const now = new Date();

    const [eventFilter, setEventFilter] = useState<AdminEventFilter>("none");
    const [eventSort, setEventSort] = useState<AdminEventSort>("none");

    const shareModalAction = () => {
        if (selectedCompetition !== null) {
            submitShare(toShareWith, selectedCompetition)
                .then(_ => {
                    setShareModalOpen(false);
                    toast.success("Event Successfully Shared.");
                })
                .catch(e => {
                    console.log(e);
                    toast.error(formatError(e));
                })
        }
    }

    const deleteModalAction = () => {
        if (selectedCompetition !== null) {
            deleteCompetition(selectedCompetition)
                .then(_ => {
                    setDeleteModalOpen(false);
                    removeCompetition(selectedCompetition);
                    toast.success("Event Successfully Deleted.");
                })
                .catch(e => {
                    console.log(e);
                    toast.error(formatError(e));
                })
        }
    }

    // wrapper around ModalProps.setOpen so we can nullify selected competition
    const shareModalClose = (open: boolean) => {
        setSelectedCompetition(null);
        setShareModalOpen(open);
    }

    const deleteModalClose = (open: boolean) => {
        setSelectedCompetition(null);
        setDeleteModalOpen(open);
    }

    const selectCompetitionShare = (id: number) => {
        setSelectedCompetition(id);
        setShareModalOpen(true);
    }

    const filterEvents = (filter: AdminEventFilter, events: Competition[]): Competition[] => {
        if (filter === "response") {
            return events.filter(c => c.roundStatus && c.roundStatus.phase === "response");
        } else if (filter === "review") {
            return events.filter(c => c.roundStatus && c.roundStatus.phase === "review");
        } else if (filter === "proxy") {
            return events.filter(c => c.roundStatus && c.roundStatus.phase === "proxy");
        } else if (filter === "active") {
            return events.filter(c => {
                const visibility = parseISO(c.competitionMeta!.visibilityDate + "Z");
                const end = parseISO(c.competitionMeta!.endDate + "Z");
                return isBefore(now, end) && isAfter(now, visibility);
            })
        } else if (filter === "startingSoon") {
            return events.filter(c => {
                const visibility = parseISO(c.competitionMeta!.visibilityDate + "Z");
                return isBefore(now, visibility);
            });
        } else if (filter === "finished") {
            return events.filter(c => {
                const end = parseISO(c.competitionMeta!.endDate + "Z");
                return isAfter(now, end);
            });
        } else { // none
            return events;
        }
    }

    const sortEvents = (sort: AdminEventSort, events: Competition[]): Competition[] => {
        if (sort === "none") {
            return events;
        } else if (sort === "alphabetical") {
            return [...events].sort((a, b) => {
                const aName = a.competitionMeta!.name.toLowerCase();
                const bName = b.competitionMeta!.name.toLowerCase();
                if (aName < bName) {
                    return -1;
                } else if (aName > bName) {
                    return 1;
                } else {
                    return 0;
                }
            })
        } else { // countUsers
            return [...events].sort((a, b) => {
                const aCount = a.participantStatus!.numParticipants;
                const bCount = b.participantStatus!.numParticipants;
                return bCount - aCount;
            })
        }
    }

    if (requestStatus === "loading") {
        return <LoadingSpinner />
    } else if (requestStatus === "error") {
        return <ErrorComponent />
    } else {

        let events = filterEvents(eventFilter, competitions);
        events = sortEvents(eventSort, events);

        const canCopy = !!user && user.role === "admin";

        return (
            <Container sx={{mb: 10}}>
                <Grid container spacing={3}>
                    <Grid container size={{xs: 12}} spacing={2} alignItems="center" justifyContent="center">
                        <Grid size={{xs: 12, sm: 8, md: 9}}>
                            <Typography variant="h4">
                                Admin Events
                            </Typography>
                        </Grid>
                        <Grid size={{xs: 12, sm: 4, md: 3}} sx={{textAlign: "center"}}>
                            {user && user.role === "admin" ?
                                <Button
                                    sx={{mb: 2}}
                                    component={Link}
                                    to={"/admin/events/create"}
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    startIcon={<AddCircleIcon />}
                                >
                                    Create New Event
                                </Button> :
                                null
                            }
                        </Grid>
                        <Grid size={{xs: 12}}>
                            <Divider sx={{mt: 3, mb: 3}} />
                        </Grid>
                    </Grid>
                    <Grid container size={{xs: 12}} spacing={2} justifyContent="space-between">
                        <Grid size={{xs: 12, sm: 6}}>
                            <TextField
                                label="Filter By"
                                name="filterBy"
                                id="filterBy"
                                select
                                value={eventFilter}
                                onChange={(e) => setEventFilter(e.target.value as AdminEventFilter)}
                                sx={{width: 250}}
                            >
                                <MenuItem value="none">
                                    No Filter
                                </MenuItem>
                                <MenuItem value="active">
                                    Active
                                </MenuItem>
                                <MenuItem value="finished">
                                    Finished
                                </MenuItem>
                                <MenuItem value="startingSoon">
                                    Starting Soon
                                </MenuItem>
                                <MenuItem value="response">
                                    Response Phase
                                </MenuItem>
                                <MenuItem value="review">
                                    Review Phase
                                </MenuItem>
                                <MenuItem value="proxy">
                                    Proxy Phase
                                </MenuItem>
                            </TextField>
                        </Grid>
                        <Grid size={{xs: 12, sm: 6}}>
                            <TextField
                                label="Sort By"
                                name="sortBy"
                                id="sortBy"
                                select
                                value={eventSort}
                                onChange={(e) => setEventSort(e.target.value as AdminEventSort)}
                                sx={{width: 250}}
                            >
                                <MenuItem value="none">
                                    Create Date
                                </MenuItem>
                                <MenuItem value="alphabetical">
                                    Alphabetical
                                </MenuItem>
                                <MenuItem value="countUsers">
                                    Count Users
                                </MenuItem>
                            </TextField>

                        </Grid>
                    </Grid>
                    <Grid size={{xs: 12}}>
                        {events.length === 0 ?
                            <NoDataCard message={"No events available."} hideHomeButton /> :
                            null
                        }
                    </Grid>
                    {events.map((c, i) => {
                        return (
                            <Grid size={{xs: 12, sm: 6}} key={i}>
                                <AdminCompetitionCard
                                    competitionMeta={c.competitionMeta! as AdminCompetitionMeta}
                                    roundStatus={c.roundStatus!}
                                    participantStatus={c.participantStatus!}
                                    selectShare={selectCompetitionShare}
                                    canCopy={canCopy}
                                />
                            </Grid>
                        )
                    })}
                </Grid>
                <ShareModal
                    open={shareModalOpen}
                    setOpen={shareModalClose}
                    action={shareModalAction}
                    value={toShareWith}
                    handleChange={handleChange}
                />
                <ConfirmActionModal
                    open={deleteModalOpen}
                    setOpen={deleteModalClose}
                    action={deleteModalAction}
                    confirmActionText="Delete"
                >
                    <Grid container>
                        <Grid size={{xs: 12}}>
                            <Typography variant="h6">
                                Delete Event
                            </Typography>
                            <Typography variant="subtitle2">
                                Are you sure you want to delete this Event?  This cannot be undone.  Users who have enrolled will be notified via email and/or notification.
                            </Typography>
                        </Grid>
                    </Grid>
                </ConfirmActionModal>
            </Container>
        )
    }
}
