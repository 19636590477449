import {useState, useCallback} from "react";

export const useTimer = (duration?: number) => {

    // if duration is not defined (or 0), this timer increments up
    // duration is in seconds
    const [isIncrement, setIsIncrement] = useState<boolean>(!duration);
    // const isIncrement: boolean = !duration;
    const [time, setTime] = useState<number>(duration || 0);
    // const [intervalId, setIntervalId] = useState<number>(NaN);
    const [intervalTimer, setIntervalTimer] = useState<NodeJS.Timer>();

    const increment = (t: number) => t + 1;
    const decrement = (t: number) => t - 1;

    const resetTimer = useCallback(() => {
        clearInterval(intervalTimer);
        setIntervalTimer(undefined);
        isIncrement ? setTime(0) : setTime(duration!);
    }, [intervalTimer, duration, isIncrement]);

    const stopTimer = useCallback(() => {
        clearInterval(intervalTimer);
        setIntervalTimer(undefined);
    }, [intervalTimer]);

    const modifyTimer = useCallback((d?: number) => {
        if (d) {
            setIsIncrement(false);
            setTime(d);
        } else {
            setIsIncrement(true);
            setTime(0);
        }
    }, []);

    const startTimer = useCallback(() => {
        if (isIncrement) {
            const timerObj = setInterval(() => setTime(increment), 1000);
            setIntervalTimer(timerObj);
        } else {
            const timerObj = setInterval(() => setTime(decrement), 1000);
            setIntervalTimer(timerObj);
        }
    }, [isIncrement]);

    return {
        time: time,
        startTimer: startTimer,
        resetTimer: resetTimer,
        stopTimer: stopTimer,
        modifyTimer: modifyTimer
    }
}