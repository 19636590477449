import {createContext} from "react";

interface IBrandingContext {
    logoUrl: string | null;
    setLogoUrl: (url: string | null) => void;
}

export const BrandingContext = createContext<IBrandingContext>({
    logoUrl: null,
    setLogoUrl: (url: string | null) => {}
})