import {useState, useCallback, useEffect} from "react";
import {RequestStatus, Video} from "../../data-types";
import {VideoJsPlayerOptions} from "video.js";
import {DataAccess} from "../../util";
export const useVideoDetail = (videoId: string | undefined, prompt: boolean) => {

    const [video, setVideo] = useState<Video | null>(null);
    const [videoOptions, setVideoOptions] = useState<VideoJsPlayerOptions | null>(null);
    const [transcriptUrl, setTranscriptUrl] = useState<string>("");
    const [requestStatus, setRequestStatus] = useState<RequestStatus>("loading");

    useEffect(() => {

        const controller = new AbortController();
        const getVideoDetail = async () => {
            if (videoId) {
                const queryUrl = (prompt) ? `/api/videoPrompt/${videoId}/get.json` : `/api/response/${videoId}/getMyResponse.json`;
                const r = await DataAccess.get(queryUrl, {signal: controller.signal});
                const v = (r.videoResponse) ? r.videoResponse : r.videoPrompt;
                setVideo(v);

                // transcript
                const url = window.URL.createObjectURL(new Blob([v.transcript], {type: 'text/vtt'}));
                setTranscriptUrl(url);

                // videoOptions
                setVideoOptions({
                    autoplay: false,
                    controls: true,
                    userActions: {hotkeys: true},
                    sources: v.sources,
                    fill: true,
                    aspectRatio: "4:3",
                    tracks: [{
                        src: url,
                        kind: "captions",
                        srclang: "en",
                        label: "English",
                        default: true
                    }],
                    html5: {
                        nativeTextTracks: false
                    },
                })
            }
        }

        getVideoDetail()
            .then(_ => setRequestStatus("complete"))
            .catch(e => {
                console.log(e);
                setRequestStatus("error");
            });

        return () => {
            controller.abort();
        }

    }, [videoId, prompt]);


    useEffect(() => {
        return () => {
            window.URL.revokeObjectURL(transcriptUrl);
        }
    }, [transcriptUrl]);

    const updateLocalTranscript = useCallback((transcript: string) => {
        const newUrl = window.URL.createObjectURL(new Blob([transcript], {type: 'text/vtt'}));
        setTranscriptUrl(newUrl);
        setVideoOptions(prev => {
            return {
                ...prev,
                tracks: [{
                    src: newUrl,
                    kind: "captions",
                    srclang: "en",
                    label: "English",
                    default: true
                }]
            }
        })
    }, []);

    return {
        video: video,
        videoOptions: videoOptions,
        requestStatus: requestStatus,
        updateLocalTranscript: updateLocalTranscript
    }
}