import {useState, useEffect} from "react";
import {RequestStatus} from "../../data-types";
import {DataAccess} from "../../util";

interface UserMeta {
    userId: number;
    username: string;
    firstName: string;
    lastName: string;
}

export const useEventUserList = (competitionId: string | undefined) => {

    const [requestStatus, setRequestStatus] = useState<RequestStatus>("loading");
    const [eventUserList, setEventUserList] = useState<UserMeta[]>([]);

    useEffect(() => {
        const controller = new AbortController();

        const getEventUserList = async () => {
            const r = await DataAccess.get(`/api/competition/${competitionId}/getEventUserList.json`, {signal: controller.signal});
            setEventUserList(r.eventUserList);
        }

        getEventUserList()
            .then(_ => {
                setRequestStatus("complete");
            })
            .catch(e => {
                console.log(e);
                setRequestStatus("error");
            })

        return () => {
            controller.abort();
        }
    }, [competitionId])

    return {
        requestStatus: requestStatus,
        eventUserList: eventUserList
    }
}