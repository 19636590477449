import {useState, useEffect, useCallback} from "react";
import {RequestStatus, LeadersMeta} from "../../data-types";
import {Video} from "../../data-types";
import {DataFormatting} from "../../util";
import {DataAccess} from "../../util";
import {VideoJsPlayerOptions} from "video.js";

interface ResponseContent {
    videoPrompt: VideoJsPlayerOptions;
    videoResponse: VideoJsPlayerOptions | null;
}

export const useLeaders = (competitionId: string | undefined) => {

    const [requestStatus, setRequestStatus] = useState<RequestStatus>("loading");
    const [eventName, setEventName] = useState<string>("");
    const [leadersMeta, setLeadersMeta] = useState<LeadersMeta[]>([]);
    const [selectedLeaderIndex, setSelectedLeaderIndex] = useState<number>(NaN);
    const [selectedRoundIndex, setSelectedRoundIndex] = useState<number>(NaN);
    const [selectedResponseIndex, setSelectedResponseIndex] = useState<number>(NaN);

    const [responseRequestStatus, setResponseRequestStatus] = useState<RequestStatus>("loading");
    const [responseContent, setResponseContent] = useState<ResponseContent>();

    useEffect(() => {

        const controller = new AbortController();

        const getLeaders = async () => {
            const r = await DataAccess.get(`/api/response/${competitionId}/getTopResponsesMeta.json`, {signal: controller.signal});
            setEventName(r.eventName)
            setLeadersMeta(r.content);
        }

        getLeaders()
            .then(_ => setRequestStatus("complete"))
            .catch(e => {
                console.log(e);
                setRequestStatus("error");
            });

        return () => {
            controller.abort();
        }
    }, [competitionId]);

    const handleRoundChange = useCallback((roundIndex: number) => {
        setSelectedRoundIndex(roundIndex);
    }, []);

    const handleResponseChange = useCallback((responseIndex: number) => {
        setSelectedResponseIndex(responseIndex);

        const controller = new AbortController();

        const getVideos = async () => {
            setResponseRequestStatus("loading");
            const r = await DataAccess.get(`/api/response/${competitionId}/${responseIndex}/getTopResponsesContent.json`, {signal: controller.signal});
            const {videoPrompt, videoResponse}: {videoPrompt: Video, videoResponse: Video | null} = r.responseContent;
            const promptOptions = DataFormatting.videoToVideoJSOptions(videoPrompt);
            const responseOptions = (videoResponse) ? DataFormatting.videoToVideoJSOptions(videoResponse) : null;
            setResponseContent({videoPrompt: promptOptions, videoResponse: responseOptions});
            setResponseRequestStatus("complete");
        }

        getVideos()
            .then(_ => setRequestStatus("complete"))
            .catch(e => {
                console.log(e);
                setRequestStatus("error");
            });
    }, [competitionId]);

    const handleLeaderChange = useCallback((leaderIndex: number) => {
        setSelectedLeaderIndex(leaderIndex);
        setSelectedResponseIndex(NaN);
    }, []);

    return {
        requestStatus: requestStatus,
        responseRequestStatus: responseRequestStatus,
        eventName: eventName,
        leadersMeta: leadersMeta,
        selectedLeaderIndex: selectedLeaderIndex,
        selectedRoundIndex: selectedRoundIndex,
        selectedResponseIndex: selectedResponseIndex,
        handleLeaderChange: handleLeaderChange,
        handleRoundChange: handleRoundChange,
        handleResponseChange: handleResponseChange,
        responseContent: responseContent
    }
}