import {useState, useEffect} from "react";
import {RequestStatus, Competition} from "../../data-types";
import {DataAccess} from "../../util";
import {parseISO, isAfter} from "date-fns";

export const useCompletedCompetitions = (competitionId: string | undefined) => {
    const [requestStatus, setRequestStatus] = useState<RequestStatus>("loading");
    const [completedCompetitions, setCompletedCompetitions] = useState<Competition[]>([]);
    const [selectedCompetitionId, setSelectedCompetitionId] = useState<number>(NaN);

    useEffect(() => {

        const getMeta = async () => {
            const r = await DataAccess.get("/api/competition/listCompletedMetaUser.json");
            const competitions: Competition[] = r.competitions;
            competitions.sort((i, j) => {
                const iEnd = i.competitionMeta?.endDate;
                const jEnd = j.competitionMeta?.endDate;
                if (iEnd && jEnd) {
                    const iDate = parseISO(iEnd + "Z");
                    const jDate = parseISO(jEnd + "Z");
                    return isAfter(iDate, jDate) ? -1 : 1;
                } else {
                    if (iEnd) {
                        return -1;
                    } else if (jEnd) {
                        return 1;
                    } else {
                        return 0;
                    }
                }
            });
            const idMostRecentlyCompleted = competitions.length > 0 ? competitions[0].competitionMeta?.competitionId : NaN;
            const parsedCompetitionId = parseInt(competitionId ?? "");
            setCompletedCompetitions(competitions);
            setSelectedCompetitionId(parsedCompetitionId || idMostRecentlyCompleted || NaN);
        }

        getMeta()
            .then(_ => setRequestStatus("complete"))
            .catch(e => {
                console.log(e);
                setRequestStatus("error");
            })
    }, [competitionId]);

    return {
        requestStatus: requestStatus,
        completedCompetitions: completedCompetitions,
        selectedCompetitionId: selectedCompetitionId,
        setSelectedCompetitionId: setSelectedCompetitionId
    }
}