import React from "react";
import {Tooltip, Box, Grid2 as Grid, Typography, Paper, Divider, IconButton} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import {DeletableAdminScriptCardProps} from "./AdminScriptCardProps";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import IosShareIcon from '@mui/icons-material/IosShare';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export function CreatedScriptCard({script, handleShareClick, handleDeleteClick, handleCopyClick}: DeletableAdminScriptCardProps) {

    const navigate = useNavigate();

    const cantShare = script.isPublic || !script.canShare;

    return (
        <Grid size={{xs: 12, sm: 6, md: 4}}>
            <Paper elevation={1} sx={{p: 3}}>
                <Typography variant="h6" noWrap sx={{mb: 3}}>
                    {script.scriptName}
                </Typography>
                <Box component="div" sx={{display: "flex", alignItems: "center", mb: 2}}>
                    {script.isFinalized ? <LockIcon /> : <LockOpenIcon />}
                    <Typography variant="subtitle2" sx={{ml: 2}}>
                        {`Closed for modification:  ${script.isFinalized ? "Yes" : "No"}`}
                    </Typography>
                </Box>
                <Box component="div" sx={{display: "flex", alignItems: "center", mb: 2}}>
                    <CheckCircleIcon color={script.isPublic ? "primary" : "inherit"} />
                    <Typography variant="subtitle2" sx={{ml: 2}}>
                        {`Is Public:  ${script.isPublic ? "Yes" : "No"}`}
                    </Typography>
                </Box>
                <Box component="div" sx={{display: "flex", alignItems: "center", mb: 2}}>
                    <SlideshowIcon />
                    <Typography variant="subtitle2" sx={{ml: 2}}>
                        {`Number prompts:  ${script.countVideos}`}
                    </Typography>
                </Box>
                <Divider sx={{my: 3}} />
                <Grid container spacing={2}>
                    <Grid size={{xs: 6}} sx={{textAlign: "center"}}>
                        <Tooltip title="View Details" describeChild>
                            <IconButton
                                component={Link}
                                to={`/admin/scripts/detail/${script.scriptId}`}
                                aria-label="view-script-details"
                            >
                                <OpenInNewIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid size={{xs: 6}} sx={{textAlign: "center"}}>
                        <Tooltip title="Copy" describeChild>
                            <IconButton
                                onClick={() => handleCopyClick(script.scriptId, script.scriptName)}
                                aria-label="copy-script"
                            >
                                <ContentCopyIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid size={{xs: 4}} sx={{textAlign: "center"}}>
                        <Tooltip
                            title={cantShare ? "You are unable to share this script because you lack permission, or lack access to all prompts." : "Share"}
                            describeChild
                        >
                            <span>
                                <IconButton
                                    onClick={() => handleShareClick(script.scriptId, script.isFinalized)}
                                    disabled={cantShare}
                                    aria-label="share-script"
                                >
                                    <IosShareIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Grid>
                    <Grid size={{xs: 4}} sx={{textAlign: "center"}}>
                        <Tooltip
                            title={!script.canEdit ?
                                "You cannot edit this script because it is closed for modification."  :
                                "Edit"
                            }
                        >
                            <span>
                                <IconButton
                                    onClick={() => {navigate(`/admin/scripts/edit/${script.scriptId}`); window.scrollTo(0, 0);}}
                                    disabled={!script.canEdit}
                                    aria-label="edit-script"
                                >
                                    <EditIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Grid>
                    <Grid size={{xs: 4}} sx={{textAlign: "center"}}>
                        <Tooltip title="Delete" describeChild>
                            <span>
                                <IconButton
                                    onClick={() => handleDeleteClick(script.scriptId)}
                                    aria-label="delete-script"
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
}