import * as React from "react";
import { SVGProps } from "react";

const SvgGold3 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={61.92}
    height={61.92}
    xmlSpace="preserve"
    {...props}
  >
    <path
      fill="#FED700"
      d="m11.688 39.694 3.61 7.315 8.07 1.172-5.84 5.693 1.379 8.039-7.219-3.797-7.219 3.797 1.379-8.039-5.841-5.693 8.072-1.172zM50.231.008l3.61 7.314 8.07 1.173-5.84 5.693 1.379 8.038-7.219-3.796-7.218 3.796 1.379-8.038-5.841-5.693 8.071-1.173zM30.96 19.851l3.609 7.314 8.072 1.173L36.8 34.03l1.379 8.039-7.219-3.796-7.219 3.796 1.379-8.039-5.841-5.692 8.072-1.173z"
    />
  </svg>
);

export default SvgGold3;
