import {useState, useEffect, ChangeEvent} from "react";
import {DataAccess} from "../../util";
import {BaseDatum} from "@visx/wordcloud/lib/types";

interface UserSelect {
    id: number;
    email: string;
    username: string;
    firstName: string;
    lastName: string;
}

interface RoundSelect {
    roundId: number;
    roundNumber: number;
}

interface PromptSelect {
    videoId: number;
    position: number;
    promptName: string;
}

interface WordcloudMeta {
    competitionId: number;
    institutionId: number;
    institutionLogoUuid: string;
    roundIds: RoundSelect[];
    promptIds: PromptSelect[];
    userIds: UserSelect[];
}

interface WordcloudDatum extends BaseDatum {
    value: number;
}

export const useWordcloudService = (competitionId?: string) => {

    const [wordcloudMeta, setWordcloudMeta] = useState<WordcloudMeta>({
        competitionId: NaN,
        institutionId: NaN,
        institutionLogoUuid: "",
        roundIds: [],
        promptIds: [],
        userIds: []
    });

    const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
    const [selectedRounds, setSelectedRounds] = useState<number[]>([]);
    const [selectedPrompts, setSelectedPrompts] = useState<number[]>([]);
    const [selectedQuintile, setSelectedQuintile] = useState<number[]>([]);
    const [words, setWords] = useState<WordcloudDatum[]>([]);

    const handleSelectionChange = (e: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        // value is a string, but comes as array of numbers.  cast to unknown
        // then to number[] as per TS2352
        const valueAsArray = value as unknown as number[];
        switch (name) {
            case "userSelect":
                setSelectedUsers(valueAsArray);
                break;
            case "roundSelect":
                setSelectedRounds(valueAsArray);
                break;
            case "promptSelect":
                setSelectedPrompts(valueAsArray);
                break;
            case "quintileSelect":
                setSelectedQuintile(valueAsArray);
                break;
            default:
                break;
        }
    }

    useEffect(() => {

        const controller = new AbortController();

        const getMeta = async () => {
            if (competitionId) {
                const r = await DataAccess.get(`/api/competition/${competitionId}/getWordcloudMeta.json`, {signal: controller.signal});
                const meta: WordcloudMeta = r.transcriptMeta;
                setWordcloudMeta(meta);
            }
        }

        getMeta()
            .catch(e => {
                console.log(e);
            })

        return () => {
            controller.abort();
        }
    }, [competitionId]);

    useEffect(() => {
        const controller = new AbortController();

        const getWordcloud = async () => {
            // only get if competitionId is defined and one of the selections is made
            if (competitionId) {
                let data;
                if (selectedUsers.length > 0 || selectedQuintile.length > 0 || selectedRounds.length > 0 || selectedUsers.length > 0) {
                    data = {
                        competitionId: parseInt(competitionId),
                        promptIds: selectedPrompts,
                        userIds: selectedUsers,
                        roundIds: selectedRounds,
                        quintileBrackets: selectedQuintile
                    }
                } else {
                    data = {
                        competitionId: parseInt(competitionId),
                        promptIds: [] as number[],
                        userIds: [] as number[],
                        roundIds: wordcloudMeta.roundIds.map(c => c.roundId),
                        quintileBrackets: [] as number[]
                    };
                }
                const r = await DataAccess.post(`/api/competition/getTranscriptWordcloud.json`, {data: data}, {signal: controller.signal});
                // marshall wordCounts to WordcloudDatum
                const words: WordcloudDatum[] = [];
                for (const [k, v] of Object.entries(r.transcriptList.wordCounts)) {
                    words.push({text: k, value: v as number})
                }
                words.sort((a, b) => b.value - a.value);
                setWords(words);
            }
        }

        getWordcloud()
            .catch(e => {
                console.log(e);
            })

        return () => {
            controller.abort();
        }
    }, [wordcloudMeta, selectedRounds, selectedPrompts, selectedUsers, selectedQuintile, competitionId]);

    return {
        selectedUsers: selectedUsers,
        selectedRounds: selectedRounds,
        selectedPrompts: selectedPrompts,
        selectedQuintile: selectedQuintile,
        meta: wordcloudMeta,
        handleSelectionChange: handleSelectionChange,
        words: words
    }
}