import React from "react";
import {List, ListItem, Box, Typography, ListItemText, Checkbox} from "@mui/material";
import {SharedUserNode} from "../../data-types";
import {DataFormatting} from "../../util";

interface AccessNodeProps {
    node: SharedUserNode;
    depth: number;
    handleSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
    selectedUsers: number[];
    parentChecked: boolean;
}

export function AccessNode({node, depth, handleSelect, selectedUsers, parentChecked}: AccessNodeProps) {

    const checked = selectedUsers.includes(node.userId) || parentChecked;

    return (
        <>
            <ListItem
                secondaryAction={
                    <Box component="div" sx={{textAlign: "right"}}>
                        <Typography variant="subtitle2">
                            {DataFormatting.roleIdToString(node.roleId)}
                        </Typography>
                        <Checkbox
                            name={node.userId.toString()}
                            inputProps={{ 'aria-label': `Select ${node.username}` }}
                            checked={checked}
                            onChange={handleSelect}
                        />
                    </Box>
                }
            >
                <ListItemText
                    sx={{pl: depth * 2}}
                    primary={`${node.firstName} ${node.lastName}`}
                    secondary={
                        <>
                            {node.username} <br /> {node.email}
                        </>
                    }
                />
            </ListItem>
            {node.children.length > 0 ?
                <List dense>
                    {node.children.map(c =>
                        <AccessNode
                            key={c.userId}
                            node={c}
                            depth={depth + 1}
                            handleSelect={handleSelect}
                            selectedUsers={selectedUsers}
                            parentChecked={checked}
                        />
                    )}
                </List> :
                null
            }
        </>
    )
}