import React from "react";
import {Button, Grid2 as Grid, TextField, Typography, Divider} from "@mui/material";
import {TranscriptEntryProps} from "../../data-types";
import {DataFormatting} from "../../util";

export function TranscriptEntry({entry, handleChange, handleDeleteEntry}: TranscriptEntryProps) {

    return (
        <Grid container justifyContent="center">
            <Grid size={{xs: 12}}>
                <Divider sx={{mt: 2, mb: 2}} />
            </Grid>
            <Grid size={{xs: 6}} sx={{mb: 3}}>
                <Typography variant="body1">
                    {`From:  ${DataFormatting.millisecondsToTimeString(entry.from)}`}
                </Typography>
            </Grid>
            <Grid size={{xs: 6}} sx={{textAlign: "right"}}>
                <Typography variant="body1">
                    {`To:  ${DataFormatting.millisecondsToTimeString(entry.to)}`}
                </Typography>
            </Grid>
            <Grid size={{xs: 12}}>
                <TextField
                    label={`Entry ${entry.id}`}
                    fullWidth={true}
                    name={entry.id}
                    value={entry.text}
                    onChange={(e) => handleChange(e, entry.id)}
                />
            </Grid>
            <Grid size={{xs: 12}} sx={{textAlign: "center", mt: 2}}>
                <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={() => handleDeleteEntry(entry.id)}
                >
                    Delete Entry
                </Button>
            </Grid>
        </Grid>
    )
}