import {useCallback} from "react";
import {DataAccess} from "../../util";

export const useScriptDelete = () => {

    const submitDelete = useCallback(async (scriptId: number) => {
        await DataAccess.delete(`/api/script/${scriptId}/delete.json`);
    }, []);

    return {
        submitDelete: submitDelete
    }
}