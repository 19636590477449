import React from "react";
import {CreateScriptStepState} from "../../data-types";
import {Box, IconButton, Typography, Paper, Grid2 as Grid, Tooltip, FormControlLabel, Checkbox} from "@mui/material";
import {
    TimelineItem,
    TimelineSeparator,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineOppositeContent,
} from "@mui/lab";
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import {DataFormatting} from "../../util";

interface ScriptBuilderStepProps {
    step: CreateScriptStepState;
    index: number;
    totalSteps: number;
    remove: (index: number) => void;
    toggleSkippable: (index: number) => void;
    moveByIndex?: (index: number, delta: number) => void;
}

export function ScriptBuilderStep({step, index, totalSteps, remove, toggleSkippable, moveByIndex}: ScriptBuilderStepProps) {

    let img = "";
    if (step.video) {
        let imageSrc = step.video.sources.find(s => s.type === "IMAGE/JPG");
        img = imageSrc?.src ?? "";
    }

    const isLast = index + 1 === totalSteps;
    const isFirst = index === 0;

    const handleSkippableChange = () => {
        toggleSkippable(index);
    }

    return (
        <TimelineItem position={index % 2 === 0 ? "left" : "right"}>
            <TimelineOppositeContent>
                <Typography variant="h6" sx={{mb: 5}}>
                    {DataFormatting.intToOrdinal(index + 1)}
                </Typography>
                <Grid container>
                    <Grid size={{xs: 12}}>
                        <Tooltip
                            title="Remove"
                        >
                            <IconButton
                                onClick={() => remove(index)}
                            >
                                <CancelIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Move Up">
                            <span>
                                <IconButton
                                    disabled={isFirst}
                                    onClick={() => moveByIndex ? moveByIndex(index, -1) : {}}
                                >
                                    <ArrowUpwardIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title="Move Down">
                            <span>
                                <IconButton
                                    disabled={isLast}
                                    onClick={() => moveByIndex ? moveByIndex(index, 1) : {}}
                                >
                                    <ArrowDownwardIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Grid>
                    <Grid size={{xs: 12}}>
                        <Tooltip
                            title="Checking this will make this prompt optional to complete."
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={!step.isSkippable}
                                        disabled={!step.isOptional}
                                        onChange={handleSkippableChange}
                                        name="isSkippable"
                                    />
                                }
                                label="Required?"
                            />
                        </Tooltip>
                    </Grid>
                </Grid>
            </TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{my: 5}}>
                <Paper elevation={1} sx={{p: 2}}>
                    <Grid container direction={index % 2 === 0 ? "row" : "row-reverse"}>
                        {step.isOptional ?
                            <>
                                <Grid size={{xs: 12}}>
                                    <Typography variant="subtitle1" noWrap sx={{mb: 1}}>
                                        Optional Prompt
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        Users will be able to select their own video prompt at this stage.
                                    </Typography>
                                </Grid>
                            </> :
                            <>
                                <Grid size={{xs: 12, sm: 6}} order={{xs: 2, sm: 1}}>
                                    <Typography variant="subtitle1" noWrap sx={{mb: 1}}>
                                        {step.video?.name ?? "Optional Prompt"}
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        {`Created by:  ${step.video?.createdBy ?? "N/A"}`}
                                    </Typography>
                                </Grid>
                                <Grid size={{xs: 12, sm: 6}} order={{xs: 1, sm: 2}}>
                                    <Box
                                        sx={{width: "80%"}}
                                        component="img"
                                        alt="video-placeholder"
                                        src={img}
                                    />
                                </Grid>
                            </>
                        }
                    </Grid>
                </Paper>
            </TimelineContent>
        </TimelineItem>
    )
}
