import {ChangeEvent, useCallback, useState} from "react";
import {DataAccess} from "../../util";

export const useFeedback = () => {

    const [feedback, setFeedback] = useState<string>("");

    const handleTextChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const v = e.target.value;
        setFeedback(v);
    }, []);

    const submitFeedback = useCallback(async (feedback: string, referralUrl: string) => {
        await DataAccess.post("/api/user/createFeedback.json", {data: {feedback: feedback, referralUrl: referralUrl}});
    }, []);

    return {
        feedback: feedback,
        handleTextChange: handleTextChange,
        submitFeedback: submitFeedback
    }
}