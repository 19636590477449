import {useCallback} from "react";
import {DataAccess} from "../../util";

export const useRevokeShare = () => {

    const revokeShareVideoPrompt = useCallback((userId: number, videoId: number) => {
        return DataAccess.post("/api/videoPrompt/revokeAccess.json", {data: {videoPromptId: videoId, revokeFromUserId: userId}});
    }, []);

    const revokeShareScript = useCallback((userId: number, scriptId: number) => {
        return DataAccess.post("/api/script/revokeAccess.json", {data: {scriptId: scriptId, revokeFromUserId: userId}});
    }, []);

    const revokeShareCompetition = useCallback((userId: number,  competitionId: number) => {
        return DataAccess.post("/api/competition/revokeAccess.json", {data: {competitionId: competitionId, revokeFromUserId: userId}});
    }, []);

    return {
        revokeShareVideoPrompt: revokeShareVideoPrompt,
        revokeShareScript: revokeShareScript,
        revokeShareCompetition: revokeShareCompetition
    }
}