import {useState, useEffect} from "react";
import {RequestStatus, UserRole} from "../../data-types";
import {DataAccess} from "../../util";

interface UserDetail {
    userId: number;
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    createDate: string;
    // isAdmin: boolean;
    role: UserRole;
    isEmailConfirmed: boolean;
    ageBracket?: number;
    regionOrCountry?: string;
    primaryLanguage?: string;
    secondaryLanguage?: string;
    highSchoolZip?: string;
    mailingZip?: string;
    usResident?: boolean;
    applywebAccountId?: number;
    avatar?: string;
}

export const useAdminUserDetail = (userId: string | undefined) => {

    const [userDetail, setUserDetail] = useState<UserDetail>({
        userId: -1,
        username: "",
        firstName: "",
        lastName: "",
        email: "",
        createDate: "",
        role: "user",
        isEmailConfirmed: false
    });
    const [requestStatus, setRequestStatus] = useState<RequestStatus>("loading");

    useEffect(() => {

        const controller = new AbortController();
        const getUserDetail = async () => {
            if (userId) {
                const r = await DataAccess.get(`/api/user/${userId}/getDetailAdmin.json`, {signal: controller.signal});
                setUserDetail(r.userDetail);
            }
        }

        getUserDetail()
            .then(_ => {
                setRequestStatus("complete");
            })
            .catch(e => {
                console.log(e);
                setRequestStatus("error");
            });

        return () => {
            controller.abort();
        }

    }, [userId]);

    return {
        requestStatus: requestStatus,
        userDetail: userDetail
    };
}