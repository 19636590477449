import React from "react";
import {Container, Typography} from "@mui/material";
import {LoadingSpinner} from "../../components";
import {useWorklist} from "../../hooks";
import {WorklistTimeline} from "./WorklistTimeline";

export function Worklist() {

    const {requestStatus, todos} = useWorklist();

    if (requestStatus === "loading") {
        return <LoadingSpinner />
    } else if (requestStatus === "error") {
        return <Container />
    } else {
        return (
            <Container sx={{mb: 10}}>
                <Typography variant="h3" component="h1" sx={{mb: 4}}>
                    Worklist
                </Typography>
                <WorklistTimeline todos={todos} />
            </Container>
        )
    }
}