import React, {useEffect, useState} from "react";
import {Box, Button, Container, Grid2 as Grid, TextField, FormGroup, FormControlLabel, Checkbox, Tooltip, Tabs, Tab, Typography} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {Video} from "../../data-types";
import {VideoAccessType} from "../../data-types";
import {ScriptBuilder, VideoLibrary} from "../../components";
import {usePagination, useScriptCreate} from "../../hooks";
import {toast} from "react-toastify";
import {formatError} from "../../util";
import AddIcon from '@mui/icons-material/Add';

export function AdminCreateScript() {

    const navigate = useNavigate();
    const {scriptId} = useParams();
    const [videoAccessType, setVideoAccessType] = useState<VideoAccessType>("created");
    const {
        scriptState,
        handleChange,
        createScript,
        toggleStepSkippable,
        toggleVideoSelection,
        scriptSequence,
        addOptionalStep,
        removeStepByIndex,
        moveByIndex
    } = useScriptCreate(scriptId);

    // myPrompts
    const {
        currentPageNumber: myPromptsPageNumber,
        pages: myPromptsPages,
        totalRecords: myPromptsTotalRecords,
        changePage: myPromptsChangePage,
        loading: myPromptsLoading,
        requestStatus: myPromptsRequestStatus
    } = usePagination<Video>("/api/videoPrompt/PAGE_NUMBER/getMyPrompts.json");

    // sharedPrompts
    const {
        currentPageNumber: sharedPromptsPageNumber,
        pages: sharedPromptsPages,
        totalRecords: sharedPromptsTotalRecords,
        changePage: sharedPromptsChangePage,
        loading: sharedPromptsLoading,
        requestStatus: sharedPromptsRequestStatus
    } = usePagination<Video>("/api/videoPrompt/PAGE_NUMBER/getUsableSharedPrompts.json");

    // publicPrompts
    const {
        currentPageNumber: publicPromptsPageNumber,
        pages: publicPromptsPages,
        totalRecords: publicPromptsTotalRecords,
        changePage: publicPromptsChangePage,
        loading: publicPromptsLoading,
        requestStatus: publicPromptsRequestStatus
    } = usePagination<Video>("/api/videoPrompt/PAGE_NUMBER/getPublicPrompts.json");

    // use effect only runs if all initial pagination requests succeed, else default to created tab
    useEffect(() => {
        if (myPromptsRequestStatus === "complete" && sharedPromptsRequestStatus === "complete" && publicPromptsRequestStatus === "complete") {
            if (myPromptsTotalRecords > 0) {
                setVideoAccessType("created");
            } else if (sharedPromptsTotalRecords > 0) {
                setVideoAccessType("shared");
            } else if (publicPromptsTotalRecords > 0) {
                setVideoAccessType("public");
            } else {
                setVideoAccessType("created");
            }
        }
    }, [myPromptsRequestStatus, myPromptsTotalRecords, sharedPromptsRequestStatus, sharedPromptsTotalRecords, publicPromptsTotalRecords, publicPromptsRequestStatus]);

    const submit = async () => {
        try {
            await createScript(scriptState, scriptSequence);
            toast.success("Script successfully created");
            navigate(`/admin/scripts`);
        } catch (e) {
            console.log(e);
            toast.error(formatError(e));
        }
    }

    // handler for the 3 item tab bar (My Scripts, Shared With Me, Public Templates
    // so we know what to render
    const handleTabChange = (_: React.SyntheticEvent, newValue: string) => {
        setVideoAccessType(newValue as VideoAccessType);
    }

    const isSelected = (video: Video): boolean => {
        return scriptSequence.some(c => c.video?.videoId === video.videoId);
    }

    return (
        <Container sx={{mb: 10}}>
            <Grid container spacing={2} alignItems="center">
                <Grid size={{xs: 12, sm: 12, md: 9}}>
                    <Typography variant="h4">
                        Create Script
                    </Typography>
                    <ScriptBuilder
                        sequence={scriptSequence}
                        removeStep={removeStepByIndex}
                        toggleStepSkippable={toggleStepSkippable}
                        moveByIndex={moveByIndex}
                    />
                    <Box sx={{textAlign: "center", my: 2}} component="div">
                        <Button
                            onClick={() => addOptionalStep()}
                            variant="contained"
                            color="primary"
                            startIcon={<AddIcon />}
                        >
                            Add Optional Prompt
                        </Button>
                    </Box>
                </Grid>
                <Grid size={{xs: 12, sm: 12, md: 3}} order={{xs: 3, sm: 3, md: 2}}>
                    <TextField
                        required
                        label="Script Name"
                        fullWidth
                        name="scriptName"
                        value={scriptState.scriptName}
                        onChange={handleChange}
                    />
                    <FormGroup>
                        <Tooltip
                            title="You won't be able to modify this Script after you click submit if this box is checked."
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={scriptState.isFinalized}
                                        onChange={handleChange}
                                        name="isFinalized"
                                    />
                                }
                                label="Closed for modification?"
                            />
                        </Tooltip>
                        <Tooltip
                            title="If you make this Script public, all other admins will be able to view it and make copies of it after you click submit."
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={scriptState.isPublic}
                                        onChange={handleChange}
                                        name="isPublic"
                                    />
                                }
                                label="Make Script public?"
                            />

                        </Tooltip>
                        {scriptState.isPublic ?
                            <Tooltip
                                title="This will automatically update all video prompts used in this Script to be public."
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={scriptState.makePublic}
                                            onChange={handleChange}
                                            name="makePublic"
                                        />
                                    }
                                    label="Make all videos public?"
                                />

                            </Tooltip> : null
                        }
                    </FormGroup>
                    <Box sx={{textAlign: "center", mt: 5}} component="div">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={submit}
                        >
                            Submit Script
                        </Button>
                    </Box>
                </Grid>
                <Grid size={{xs: 12}} order={{xs: 2, sm: 2, md: 3}}>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column"
                        }}
                        component="div"
                    >
                        <Tabs
                            value={videoAccessType}
                            onChange={handleTabChange}
                            sx={{mb: 5}}
                            centered
                        >
                            <Tab value="created" label={myPromptsTotalRecords ? `My Videos (${myPromptsTotalRecords})` : "My Videos"} />
                            <Tab value="shared" label={sharedPromptsTotalRecords ? `Shared With Me (${sharedPromptsTotalRecords})` : "Shared With Me"} />
                            <Tab value="public" label={publicPromptsTotalRecords ? `Public Videos (${publicPromptsTotalRecords})` : "Public Videos"} />
                        </Tabs>
                    </Box>
                    {videoAccessType === "created" ?
                        <VideoLibrary
                            videosPerPage={6}
                            totalVideos={myPromptsTotalRecords}
                            pageNumber={myPromptsPageNumber}
                            videos={myPromptsPages[myPromptsPageNumber]}
                            loading={myPromptsLoading}
                            changePage={myPromptsChangePage}
                            toggleSelection={toggleVideoSelection}
                            isSelected={isSelected}
                        /> : null
                    }
                    {videoAccessType === "shared" ?
                        <VideoLibrary
                            videosPerPage={6}
                            totalVideos={sharedPromptsTotalRecords}
                            pageNumber={sharedPromptsPageNumber}
                            videos={sharedPromptsPages[sharedPromptsPageNumber]}
                            loading={sharedPromptsLoading}
                            changePage={sharedPromptsChangePage}
                            toggleSelection={toggleVideoSelection}
                            isSelected={isSelected}
                        /> : null
                    }
                    {videoAccessType === "public" ?
                        <VideoLibrary
                            videosPerPage={6}
                            totalVideos={publicPromptsTotalRecords}
                            pageNumber={publicPromptsPageNumber}
                            videos={publicPromptsPages[publicPromptsPageNumber]}
                            loading={publicPromptsLoading}
                            changePage={publicPromptsChangePage}
                            toggleSelection={toggleVideoSelection}
                            isSelected={isSelected}
                        /> : null
                    }
                </Grid>
            </Grid>
        </Container>
    )
}
