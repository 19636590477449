import {useState, useEffect} from "react";
import {RequestStatus} from "../../data-types";
import {ReceiptData} from "./ReceiptData";
import {DataAccess} from "../../util";

export const useReceipt = (competitionId: string | undefined) => {

    const [requestStatus, setRequestStatus] = useState<RequestStatus>("loading");
    const [receiptData, setReceiptData] = useState<ReceiptData>({
        purchaseDate: "",
        firstName: "",
        lastName: "",
        amount: NaN,
        lastFourDigits:  NaN,
        confirmationId: NaN
    })

    useEffect(() => {
        const controller = new AbortController();

        const getReceipt = async () => {
            const r = await DataAccess.get(`/api/competition/${competitionId}/getReceipt.json`);
            setReceiptData(r.receiptData);
        }

        getReceipt()
            .then(_ => setRequestStatus("complete"))
            .catch(e => {
                console.log(e);
                setRequestStatus("error");
            })

        return () => {
            controller.abort();
        }
    }, [competitionId]);

    return {
        requestStatus: requestStatus,
        receiptData: receiptData
    }
}