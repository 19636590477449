import React, {useContext} from "react";
import {Outlet} from "react-router-dom";
import {Unauthorized} from "./Unauthorized";
import {UserContext} from "../../context";
import {UserRole} from "../../data-types";

export const PromptScriptAdminRouter = () => {
    const {user} = useContext(UserContext);

    const isUserPsAdmin = (role: UserRole) => {
        return role === "admin" || role === "ps_admin";
    }
    return (user && isUserPsAdmin(user.role)) ? <Outlet /> : <Unauthorized />
}
