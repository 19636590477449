import React from "react";
import {Competition}  from "../../data-types";
import {Paper, List, ListItem, ListItemText, ListItemButton, ListSubheader} from "@mui/material";

interface ResultsDrawerContentProps {
    completedCompetitions: Competition[];
    competitionId?: string;
    handleCompetitionChange: (id: number) => void;
}

export function CompetitionSelectList({completedCompetitions, competitionId, handleCompetitionChange}: ResultsDrawerContentProps) {
    return (
        <Paper elevation={1} sx={{maxHeight: 600, overflowY: "auto"}}>
            <List dense subheader={<ListSubheader>Events</ListSubheader>}>
                {completedCompetitions.length === 0 ?
                    <ListItem>
                        <ListItemText primary="You don't have any results to review yet." />
                    </ListItem> :
                    null
                }
                {completedCompetitions.map((c, i) => c.competitionMeta ?
                    <ListItem key={i} disableGutters>
                        <ListItemButton
                            selected={c.competitionMeta.competitionId.toString() === competitionId}
                            onClick={() => handleCompetitionChange(c.competitionMeta!.competitionId)}
                        >
                            <ListItemText primary={c.competitionMeta.name} />
                        </ListItemButton>
                    </ListItem> :
                    null
                )}
            </List>
        </Paper>
    )
}