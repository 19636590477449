import {useState, useEffect, useCallback, ChangeEvent} from "react";
import {ReportDetail, RequestStatus} from "../../data-types";
import {DataAccess} from "../../util";

export const useReportDetail = (responseId: string | undefined) => {

    const [requestStatus, setRequestStatus] = useState<RequestStatus>("loading");
    const [reportDetail, setReportDetail] = useState<ReportDetail>({
        videoId: -1,
        videoUuid: "",
        size: "0",
        responseSources: [],
        responderUsername: "",
        reportedDockScore: 0.5,
        numberReports: 0,
        numberHandledReports: 0,
        report: [],
        canHandle: false
    })

    const [editedDockScore, setEditedDockScore] = useState<number>(0.5);

    useEffect(() => {

        const controller = new AbortController();
        const getReportDetail = async () => {
            if (responseId) {
                const r = await DataAccess.get(`/api/report/${responseId}/getDetail.json`, {signal: controller.signal});
                setReportDetail(r.reportDetail);
                if (r.reportDetail.reportedDockScore) {
                    setEditedDockScore(r.reportDetail.reportedDockScore);
                }
            }
        }

        getReportDetail()
            .then(_ => {
                setRequestStatus("complete");
            })
            .catch(e => {
                console.log(e);
                setRequestStatus("error");
            })

        return () => {
            controller.abort();
        }

    }, [responseId]);

    const handleDockScoreChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const {valueAsNumber} = e.target;
        if (valueAsNumber >= 0.5 && valueAsNumber <= 9) {
            setEditedDockScore(valueAsNumber);
        }
    }, []);

    const handleReport = useCallback(async (action: number, responseId: string, reportDetail: ReportDetail, editedDockScore: number) => {
        const reviewIds = reportDetail.report.filter(c => !c.isProxy && !c.isReportHandled).map(c => c.reviewId);
        const proxyReviewIds = reportDetail.report.filter(c => c.isProxy && !c.isReportHandled).map(c => c.reviewId);

        const data = {
            reviewIds: reviewIds,
            proxyReviewIds: proxyReviewIds,
            action: action,
            penalty: (action === 2) ? editedDockScore : null
        }

        await DataAccess.post(`/api/report/${responseId}/handle.json`, {data: data});
        setReportDetail(prev => {
            return {
                ...prev,
                reportedAction: (action === 0) ? prev.reportedAction : action,
                reportedDockScore: editedDockScore,
                report: prev.report.map(c => { return {...c, isReportHandled: true}})
            }
        });
        setEditedDockScore(editedDockScore);
    }, []);

    return {
        requestStatus: requestStatus,
        reportDetail: reportDetail,
        handleReport: handleReport,
        editedDockScore: editedDockScore,
        handleDockScoreChange: handleDockScoreChange
    }
}