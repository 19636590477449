import {useState, useEffect} from "react";
import {RequestStatus, ToDo} from "../../data-types";
import {DataAccess} from "../../util";

export const useWorklist = () => {

    const [requestStatus, setRequestStatus] = useState<RequestStatus>("loading");
    const [todos, setTodos] = useState<ToDo[]>([]);

    useEffect(() => {

        const controller = new AbortController();

        const getToDos = async () => {
            const r = await DataAccess.get("/api/todo/get.json");
            setTodos(r.todos);
        }

        getToDos()
            .then(_ => setRequestStatus("complete"))
            .catch(e => {
                console.log(e);
                setRequestStatus("error")
            })

        return () => {
            controller.abort();
        }

    }, [])

    return {
        todos: todos,
        requestStatus: requestStatus
    }
}