import React from "react";
import {Card, CardContent, Typography, Button} from "@mui/material";
import {NoDataCardProps} from "../../data-types";
import {Link} from "react-router-dom";

export function NoDataCard({message, hideHomeButton}: NoDataCardProps) {

    return (
        <Card>
            <CardContent sx={{p: 4, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center"}}>
                <Typography variant="h6">
                    {message}
                </Typography>
                {hideHomeButton ?
                    null :
                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        component={Link}
                        to={"/"}
                        sx={{mt: 5}}
                    >
                        Return to Home
                    </Button>
                }
            </CardContent>
        </Card>
    )
}
