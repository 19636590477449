import React from "react";
import {
    Avatar,
    Box,
    Grid2 as Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    ListSubheader,
    Typography
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HelpIcon from "@mui/icons-material/Help";
import CancelIcon from "@mui/icons-material/Cancel";
import PendingIcon from "@mui/icons-material/Pending";
import {InvitationDetail} from "../../data-types";

interface InvitationStatusProps {
    invitationDetail: InvitationDetail;
}

export function InvitationStatus({invitationDetail}: InvitationStatusProps) {

    return (
        <Grid container size={{xs: 12}} spacing={1}>
            <Grid size={{xs: 12, sm: 3}}>
                {/* Accepted */}
                <List
                    sx={{width: '100%', maxHeight: 500, overflowY: 'auto'}}
                    subheader={
                        <ListSubheader>
                            <Box component="div" sx={{verticalAlign: 'middle', display: 'inline-flex'}}>
                                <CheckCircleIcon color="primary" />
                                <Typography variant="subtitle2" sx={{ml: 2}}>
                                    Accepted
                                </Typography>
                            </Box>
                        </ListSubheader>
                    }
                    dense={true}
                >
                    {invitationDetail.users.filter(c => c.status === "accepted").length === 0 ?
                        <ListItem>
                            <ListItemText
                                primary="No users have accepted yet."
                            />
                        </ListItem> :
                        null
                    }
                    {invitationDetail.users.filter(c => c.status === "accepted").map((c, i) =>
                        <ListItem
                            key={i}
                        >
                            <ListItemAvatar>
                                <Avatar
                                    src={c.avatar ? `data:image/jpeg;base64,${c.avatar}` : ""}
                                    alt={`${c.username}'s Avatar`}
                                />
                            </ListItemAvatar>
                            <ListItemText
                                primary={c.username}
                                secondary={
                                    <>
                                        {c.firstName} {c.lastName} <br />
                                        {c.email}
                                    </>
                                }
                            />
                        </ListItem>
                    )}
                </List>
            </Grid>
            <Grid size={{xs: 12, sm: 3}}>
                {/* Pending */}
                <List
                    sx={{width: '100%', maxHeight: 500, overflowY: 'auto'}}
                    subheader={
                        <ListSubheader>
                            <Box component="div" sx={{verticalAlign: 'middle', display: 'inline-flex'}}>
                                <HelpIcon color="warning" />
                                <Typography variant="subtitle2" sx={{ml: 2}}>
                                    Pending
                                </Typography>
                            </Box>
                        </ListSubheader>
                    }
                    dense={true}
                >
                    {invitationDetail.users.filter(c => c.status === "pending").length === 0 ?
                        <ListItem>
                            <ListItemText
                                primary="No users pending."
                            />
                        </ListItem> :
                        null
                    }
                    {invitationDetail.users.filter(c => c.status === "pending").map((c, i) =>
                        <ListItem key={i}>
                            <ListItemAvatar>
                                <Avatar
                                    src={c.avatar ? `data:image/jpeg;base64,${c.avatar}` : ""}
                                    alt={`${c.username}'s Avatar`}
                                />
                            </ListItemAvatar>
                            <ListItemText
                                primary={c.username}
                                secondary={
                                    <>
                                        {c.firstName} {c.lastName} <br />
                                        {c.email}
                                    </>
                                }
                            />
                        </ListItem>
                    )}
                </List>
            </Grid>
            <Grid size={{xs: 12, sm: 3}}>
                {/* Rejected */}
                <List
                    sx={{width: '100%', maxHeight: 500, overflowY: 'auto'}}
                    subheader={
                        <ListSubheader>
                            <Box component="div" sx={{verticalAlign: 'middle', display: 'inline-flex'}}>
                                <CancelIcon color="secondary" />
                                <Typography variant="subtitle2" sx={{ml: 2}}>
                                    Rejected
                                </Typography>
                            </Box>
                        </ListSubheader>
                    }
                    dense={true}
                >
                    {invitationDetail.users.filter(c => c.status === "rejected" || c.status === "expired").length === 0 ?
                        <ListItem>
                            <ListItemText
                                primary="No users have rejected the invitation."
                            />
                        </ListItem> :
                        null
                    }
                    {invitationDetail.users.filter(c => c.status === "rejected" || c.status === "expired").map((c, i) =>
                        <ListItem key={i}>
                            <ListItemAvatar>
                                <Avatar
                                    src={c.avatar ? `data:image/jpeg;base64,${c.avatar}` : ""}
                                    alt={`${c.username}'s Avatar`}
                                />
                            </ListItemAvatar>
                            <ListItemText
                                primary={`${c.username} (${c.status})`}
                                secondary={
                                    <>
                                        {c.firstName} {c.lastName} <br />
                                        {c.email}
                                    </>
                                }
                            />
                        </ListItem>
                    )}
                </List>
            </Grid>
            <Grid size={{xs: 12, sm: 3}}>
                {/* Not created */}
                <List
                    sx={{width: '100%', maxHeight: 500, overflowY: 'auto'}}
                    subheader={
                        <ListSubheader>
                            <Box component="div" sx={{verticalAlign: 'middle', display: 'inline-flex'}}>
                                <PendingIcon />
                                <Typography variant="subtitle2" sx={{ml: 2}}>
                                    Account Required
                                </Typography>
                            </Box>
                        </ListSubheader>
                    }
                    dense={true}
                >
                    {invitationDetail.candidates.length === 0 ?
                        <ListItem>
                            <ListItemText
                                primary="All invitees have an account."
                            />
                        </ListItem> :
                        null
                    }
                    {invitationDetail.candidates.map((c, i) =>
                        <ListItem key={i}>
                            <ListItemText
                                primary={c.email}
                            />
                        </ListItem>
                    )}
                </List>
            </Grid>
        </Grid>
    )
}