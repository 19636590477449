import {createContext} from "react";
import {UserProfileState} from "../data-types";

interface IUserContext {
    user: UserProfileState | null;
    setUser: (u: UserProfileState | null) => void;
}
export const UserContext = createContext<IUserContext>({
    user: null,
    setUser: (u: UserProfileState | null) => {}
});