import {useEffect, useState} from "react";
import {RequestStatus} from "../../data-types";
import {DataAccess} from "../../util";

export const useCompetitionLegalAgreement = (legalAgreementUuid: string | null) => {
    const [requestStatus, setRequestStatus] = useState<RequestStatus>("loading");
    const [agreementUrl, setAgreementUrl] = useState<string>("");

    useEffect(() => {

        const getLegalAgreementUrl = async () => {
            return await DataAccess.get(`/api/competition/${legalAgreementUuid}/getLegalAgreement.json`) as string;
        }

        if (legalAgreementUuid) {
            getLegalAgreementUrl()
                .then(link => {
                    setAgreementUrl(link);
                    setRequestStatus("complete");
                })
                .catch(e => {
                    console.log(e);
                    setRequestStatus("error");
                })
        }

        return () => {
            setAgreementUrl("");
        }
    }, [legalAgreementUuid]);

    return {
        requestStatus: requestStatus,
        agreementUrl: agreementUrl,
    }
}