import React, {ChangeEvent} from "react";
import {Box, Grid2 as Grid, Typography, TextField, MenuItem} from "@mui/material";

interface DeviceSelectProps {
    disabled: boolean;
    audioDevices: MediaDeviceInfo[];
    videoDevices: MediaDeviceInfo[];
    selectedAudioDevice: string | null;
    selectedVideoDevice: string | null;
    changeDevice: (audioDeviceId: string | null, videoDeviceId: string | null) => void;
}

export function DeviceSelect({disabled, audioDevices, videoDevices, selectedAudioDevice, selectedVideoDevice, changeDevice}: DeviceSelectProps) {

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        if (name === "audioDeviceSelect") {
            changeDevice(value, selectedVideoDevice);
        } else {
            changeDevice(selectedAudioDevice, value);
        }
    }

    return (
        <Box
            sx={{mt: 3}}
            component="div"
        >
            <Grid container spacing={2}>
                <Grid size={{xs: 12, sm: 12, md: 6}}>
                    <Typography variant="subtitle2">
                        Select Audio Source
                    </Typography>
                    <TextField
                        select
                        id="audioDeviceSelect"
                        name="audioDeviceSelect"
                        fullWidth
                        size="small"
                        value={selectedAudioDevice ?? "auto"}
                        disabled={disabled}
                        onChange={handleChange}
                    >
                        <MenuItem value="auto" disabled>
                            (Auto)
                        </MenuItem>
                        {audioDevices.map((c, i) =>
                            <MenuItem value={c.deviceId} key={i}>
                                {c.label}
                            </MenuItem>
                        )}
                    </TextField>
                </Grid>
                <Grid size={{xs: 12, sm: 12, md: 6}}>
                    <Typography variant="subtitle2">
                        Select Video Source
                    </Typography>
                    <TextField
                        select
                        id="videoDeviceSelect"
                        name="videoDeviceSelect"
                        value={selectedVideoDevice ?? "auto"}
                        fullWidth
                        size="small"
                        disabled={disabled}
                        onChange={handleChange}
                    >
                        <MenuItem value="auto" disabled>
                            (Auto)
                        </MenuItem>
                        {videoDevices.map((c, i) =>
                            <MenuItem value={c.deviceId} key={i}>
                                {c.label}
                            </MenuItem>
                        )}
                    </TextField>
                </Grid>
            </Grid>
        </Box>
    )
}