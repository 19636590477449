import React, {useState, useEffect} from "react";
import {Tabs, Tab, Grid2 as Grid} from "@mui/material";
import {useParams, useNavigate, Outlet, useLocation} from "react-router-dom";
import {useCompletedCompetitions} from "./useCompletedCompetitions";
import {CompetitionSelectList} from "./CompetitionSelectList";
import {NoDataCard, LoadingSpinner, ErrorComponent} from "../../components";

type ResultsTab = "reviewsOfMe" | "rankings" | "results";

export function MyResults() {

    const {competitionId}  = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const {
        requestStatus,
        completedCompetitions,
        selectedCompetitionId,
        setSelectedCompetitionId
    } = useCompletedCompetitions(competitionId);

    const [resultsTab, setResultsTab] = useState<ResultsTab>(() => {
        const startingPage = location.pathname.split("/");
        return (startingPage[3]) ? startingPage[3] as ResultsTab : "rankings";
    });

    useEffect(() => {

        if (!competitionId && !isNaN(selectedCompetitionId)) {
            navigate(`/myResults/${selectedCompetitionId}/rankings`);
        }
    }, [competitionId, selectedCompetitionId, navigate]);

    useEffect(() => {
        const currentPage = location.pathname.split("/");
        const currentTab = (currentPage[3]) ? currentPage[3] as ResultsTab : "rankings";
        setResultsTab(currentTab);
    }, [location.pathname]);

    const handleTabChange = (_: React.SyntheticEvent, newValue: string) => {
        setResultsTab(newValue as ResultsTab);
        navigate(newValue);
    }

    const handleCompetitionChange = (id: number) => {
        setSelectedCompetitionId(id);
        navigate(`/myResults/${id}/${resultsTab}`);
    }

    const parsedCompetitionIdParam = parseInt(competitionId || "");
    const renderSelectList = completedCompetitions.some(c => c.competitionMeta?.competitionId === parsedCompetitionIdParam);

    if (requestStatus === "loading") {
        return <LoadingSpinner />;
    } else if (requestStatus === "error") {
        return <ErrorComponent />;
    } else {
        return (
            <Grid container sx={{mt: -7}}>
                {renderSelectList ?
                    <Grid size={{xs: 12, sm: 12, md: 2}}>
                        <CompetitionSelectList
                            completedCompetitions={completedCompetitions}
                            competitionId={competitionId}
                            handleCompetitionChange={handleCompetitionChange}
                        />
                    </Grid> :
                    null
                }
                <Grid container size={{xs: 12, sm: 12, md: renderSelectList ? 10 : 12}}>
                    {competitionId ?
                        <Grid size={{xs: 12}} sx={{my: 3}}>
                            <Tabs
                                value={resultsTab}
                                onChange={handleTabChange}
                                centered
                                textColor="inherit"
                            >
                                <Tab label="Rankings" value="rankings" />
                                <Tab label="Results" value="results" />
                                <Tab label="Reviews of Me" value="reviewsOfMe" />
                            </Tabs>
                        </Grid>:
                        <Grid size={{xs: 12}} sx={{mx: "10%", my: 7}}>
                            <NoDataCard message={"Results will be displayed here after you have participated in an event that has completed."} />
                        </Grid>
                    }
                    <Grid size={{xs: 12}}>
                        <Outlet />
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}