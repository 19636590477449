import {useState, useEffect, useCallback} from "react";
import {RequestStatus, Promotion} from "../../data-types";
import {DataAccess} from "../../util";

export const usePromotions = () => {

    const [promotions, setPromotions] = useState<Promotion[]>([]);
    const [requestStatus, setRequestStatus] = useState<RequestStatus>("loading");

    useEffect(() => {

        const controller = new AbortController();

        const getPromotions = async () => {
            const r = await DataAccess.get("/api/promotionCode/getListUser.json", {signal: controller.signal});
            setPromotions(r.promotionList.promotions);
        }

        getPromotions()
            .then(_ => {
                setRequestStatus("complete");
            })
            .catch(e => {
                console.log(e);
                setRequestStatus("error");
            })

        return () => {
            controller.abort();
        }

    }, []);


    const removePromotion = useCallback((idx: number) => {
        setPromotions(prev => prev.filter((_, i) => i !== idx));
    }, []);

    return {
        requestStatus: requestStatus,
        promotions: promotions,
        removePromotion: removePromotion
    }
}