import React, {useState} from "react";
import {Paper, IconButton, Divider, Typography, Container, Grid2 as Grid, Tooltip, Button} from "@mui/material";
import {SxProps} from "@mui/material/styles";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useScriptDetail, useShareAccess, useRevokeShare} from "../../hooks";
import {ErrorComponent, LoadingSpinner, ShareAccessModal} from "../../components";
import {DataFormatting, formatError} from "../../util";
import EditIcon from '@mui/icons-material/Edit';
import {parseISO} from "date-fns";
import {ScriptStepCard} from "./ScriptStepCard";
import PeopleIcon from '@mui/icons-material/People';
import {toast} from "react-toastify";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

export function AdminScriptDetail() {

    const navigate = useNavigate();
    const {scriptId} = useParams();
    const {scriptDetail, requestStatus} = useScriptDetail(scriptId);
    const {sharedUserNodes, getSharedUsers, removeSharedUser} = useShareAccess("SCRIPT", scriptId);
    const {revokeShareScript} = useRevokeShare();
    const [shareAccessModalOpen, setShareAccessModalOpen] = useState<boolean>(false);

    const scriptLength = DataFormatting.secondsToTimeString(
        scriptDetail.scriptSteps.reduce((p, c) => {
            const parsed = parseInt(c.size);
            return (isNaN(parsed)) ? p : p + parsed;
        }, 0)) ;
    const metaStyle: SxProps = {py: 3, textAlign: "center", minHeight: 110};

    const handleOpenShareAccessModal = () => {
        getSharedUsers()
            .then(_ => {
                setShareAccessModalOpen(true);
            })
            .catch(e => {
                console.log(e);
                toast.error("There was a problem with this request");
            })
    }

    const handleShareRevoke = (ids: number[]) => {
        const script = Number.parseInt(scriptId as string);
        Promise.all(ids.map(c => revokeShareScript(c, script)))
            .then(_ => {
                setShareAccessModalOpen(false);
                removeSharedUser(ids);
                toast.success("Share access revoked for all selected users.");
            })
            .catch(e => {
                console.log(e);
                toast.error(formatError(e));
            })
    }

    const handleCloseShareAccessModal = () => {
        setShareAccessModalOpen(false);
    }

    if (requestStatus === "loading") {
        return <LoadingSpinner />;
    } else if (requestStatus === "error") {
        return <ErrorComponent />;
    } else {

        return (
            <Container sx={{mb: 10}}>
                <Grid container spacing={2}>
                    <Grid size={{xs: 12}}>
                        <Button
                            component={Link}
                            to={"/admin/scripts"}
                            variant="outlined"
                            size="small"
                            color="info"
                            sx={{mb: 3}}
                            startIcon={<KeyboardBackspaceIcon />}
                        >
                            Back to List
                        </Button>
                        <Typography variant="h4">
                            Script Detail
                        </Typography>
                        <Divider sx={{my: 3}} />
                    </Grid>
                    <Grid container spacing={2} size={{xs: 12}} sx={{mb: 3}} alignItems="center" justifyContent="space-between">
                        <Grid>
                            <Typography variant="h6" sx={{mr: 2}}>
                                {`Script name:  ${scriptDetail.scriptName}`}
                            </Typography>
                        </Grid>
                        {scriptDetail.isPublic ?
                            null :
                            <Grid>
                                <Tooltip title="See who has been shared this script.">
                                    <IconButton
                                        onClick={handleOpenShareAccessModal}
                                    >
                                        <PeopleIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        }
                    </Grid>
                    <Grid container size={{xs: 12}} spacing={2} alignItems="center" sx={{mb: 5}}>
                        <Grid size={{xs: 12, sm: 4}}>
                            <Paper elevation={1} sx={metaStyle}>
                                <Typography variant="h6">
                                    {scriptDetail.scriptSteps.length}
                                </Typography>
                                <Typography variant="subtitle2">
                                    Prompts
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid size={{xs: 12, sm: 4}}>
                            <Paper elevation={1} sx={metaStyle}>
                                <Typography variant="h6">
                                    {scriptLength}
                                </Typography>
                                <Typography variant="subtitle2">
                                    Length
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid size={{xs: 12, sm: 4}}>
                            <Paper elevation={1} sx={metaStyle}>
                                <Typography variant="subtitle1">
                                    {parseISO(scriptDetail.createDate + "Z").toLocaleString()}
                                </Typography>
                                <Typography variant="subtitle2">
                                    Create Date
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid size={{xs: 12}}>
                        <Grid container spacing={1}>
                            <Grid>
                                <Typography variant="h4">
                                    Prompts
                                </Typography>
                            </Grid>
                            <Grid>
                                <IconButton
                                    onClick={() => {navigate(`/admin/scripts/edit/${scriptId}`); window.scrollTo(0, 0);}}
                                    disabled={!scriptDetail.canEdit}
                                >
                                    <EditIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Divider sx={{my: 3}} />
                    </Grid>
                    <Grid size={{xs: 12}}>
                        {scriptDetail.scriptSteps.map((c, i) =>
                            <ScriptStepCard key={i} scriptStep={c} />
                        )}
                    </Grid>
                    <Grid size={{xs: 12}}>
                        <Typography variant="h4">
                            Usage
                        </Typography>
                        <Divider sx={{my: 3}} />
                    </Grid>
                    <Grid size={{xs: 12}}>
                        {scriptDetail.usage && scriptDetail.usage.length > 0 ?
                            <Grid container spacing={3}>
                                {scriptDetail.usage.map((c, i) =>
                                    <Grid key={i} size={{xs: 12, sm: 6, md: 4}}>
                                        <Paper elevation={1} sx={{p: 3}}>
                                            <Typography variant="h6" noWrap sx={{mb: 3}}>
                                                {c.competitionName}
                                            </Typography>
                                            <Typography variant="subtitle1" sx={{mb: 1}}>
                                                {`Round #${c.roundNumber}`}
                                            </Typography>
                                        </Paper>
                                    </Grid>
                                )}
                            </Grid> :
                            <Paper elevation={1} sx={{p: 3}}>
                                <Typography variant="subtitle1">
                                    This Script is not currently in use.
                                </Typography>
                            </Paper> 
                        }
                    </Grid>
                </Grid>
                <ShareAccessModal
                    open={shareAccessModalOpen}
                    sharedUserNodes={sharedUserNodes}
                    onClose={handleCloseShareAccessModal}
                    handleRevoke={handleShareRevoke}
                />
            </Container>
        )
    }
}
