import {useState, useEffect} from "react";
import {RequestStatus, CompetitionResults} from "../../data-types";
import {DataAccess} from "../../util";

export const useAdminCompetitionResults = (competitionId: string | undefined) => {

    const [requestStatus, setRequestStatus] = useState<RequestStatus>("loading");
    const [competitionResults, setCompetitionResults] = useState<CompetitionResults | null>(null);

    useEffect(() => {

        const controller = new AbortController();
        const getStatistics = async () => {
            if (competitionId) {
                const r = await DataAccess.get(`/api/competition/${competitionId}/statistics.json`, {signal: controller.signal});
                setCompetitionResults(r);
                setRequestStatus("complete");
            }
        }

        getStatistics()
            .catch(e => {
                console.log(e);
                setRequestStatus("error");
            });

        return () => {
            controller.abort();
        }
    }, [competitionId]);

    return {
        competitionResults: competitionResults,
        requestStatus: requestStatus
    }
}