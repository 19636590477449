import {useState, useEffect, useCallback} from "react";
import {RequestStatus, RoundRanks} from "../../data-types";
import {DataAccess} from "../../util";

export const useRankings = (competitionId: string | undefined) => {

    const [requestStatus, setRequestStatus] = useState<RequestStatus>("loading");
    const [roundRanks, setRoundRanks] = useState<RoundRanks[]>([]);
    const [selectedRoundIndex, setSelectedRoundIndex] = useState<number>(NaN);
    const [competitionName, setCompetitionName] = useState<string>("");

    useEffect(() => {

        const controller = new AbortController();

        const getRankings = async () => {
            const r = await DataAccess.get(`/api/rankings/${competitionId}/rankings.json`, {signal: controller.signal});
            const {roundRanks, competitionMeta} = r;
            setRoundRanks(roundRanks ?? []);
            setCompetitionName(competitionMeta?.name || "");
            setSelectedRoundIndex(roundRanks && roundRanks.length > 0 ? roundRanks.length - 1 : NaN);
        }

        getRankings()
            .then(_ => setRequestStatus("complete"))
            .catch(e => {
                console.log(e);
                setRequestStatus("error");
            });

        return () => {
            controller.abort();
        }
    }, [competitionId]);

    const handleRoundChange = useCallback((roundIndex: number) => {
        setSelectedRoundIndex(roundIndex);
    }, []);

    return {
        requestStatus: requestStatus,
        roundRanks: roundRanks,
        competitionName: competitionName,
        selectedRoundIndex: selectedRoundIndex,
        handleRoundChange: handleRoundChange
    }
}