import React from "react";
import {Button, Container, Grid2 as Grid, Typography, useTheme} from "@mui/material";
import {LoadingSpinner} from "../../components";
import {Link, useParams} from "react-router-dom";
import {useConfirmEmail} from "../../hooks/services/useConfirmEmail";
import {RequestStatus} from "../../data-types";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

export function ConfirmEmail() {

    const theme = useTheme();
    const {confirmEmailToken} = useParams();
    const status = useConfirmEmail(confirmEmailToken);

    const statusComponent = (status: RequestStatus) => {
        switch(status) {
            case "loading":
                return <LoadingSpinner />
            case "complete":
                return (
                    <Grid size={{xs: 12}} sx={{textAlign: "center"}}>
                        <CheckCircleOutlineIcon color="primary" fontSize="large" />
                        <Typography variant="h6" sx={{mt: 5}}>
                            Thank you for confirming your email address!
                        </Typography>
                        <Button
                            component={Link}
                            to="/login"
                            sx={{mt: 5, mb: 5}}
                            variant="contained"
                        >
                            Return to Login Page
                        </Button>
                    </Grid>
                )
            case "error":
                return (
                    <Grid size={{xs: 12}} sx={{textAlign: "center"}}>
                        <Typography variant="h6" sx={{mt: 5}}>
                            There was an error with this request.
                        </Typography>
                        <Button
                            component={Link}
                            to="/login"
                            sx={{mt: 5, mb: 5}}
                            variant="contained"
                            color="secondary"
                        >
                            Return to Login Page
                        </Button>
                    </Grid>
                )
        }
    }

    return (
        <Container sx={{
            bgcolor: theme.palette.background.paper,
            width: "100%",
            minWidth: "350px",
            maxWidth: "500px",
            mt: {xs: "25%", sm: "17%"},
            pt: 3
        }}>
            {statusComponent(status)}
        </Container>
    )
}