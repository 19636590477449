import React from "react";
import {Divider, Button, Container, Grid2 as Grid, Typography} from "@mui/material";
import {LoadingSpinner, ReviewGroupCard} from "../../components";
import {ReviewGroupList} from "./ReviewGroupList";
import {Link} from "react-router-dom";
import {ReviewGroup} from "../../data-types";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {useReviewGroups} from "../../hooks";

export function AdminReviewGroups() {
    const {loading, reviewGroups, totalUserCount, totalCandidateCount} = useReviewGroups();
    const ALL_REVIEW_GROUP: ReviewGroup = {
        groupId: NaN,
        groupName: "All Reviewers",
        countUsers: 0,
        countCandidates: 0,
        groupAvatar: ""
    };

    if (loading) {
        return <LoadingSpinner />
    }

    return (
        <Container sx={{mb: 3}}>
            <Grid container spacing={2}>
                <Grid size={{xs: 12}}>
                    <Grid container spacing={2} alignItems="center" justifyContent="center">
                        <Grid size={{xs: 6}}>
                            <Typography variant="h4">
                                Review Groups
                            </Typography>
                        </Grid>
                        <Grid size={{xs: 6}} sx={{textAlign: "right"}}>
                            <Button
                                component={Link}
                                to={"/admin/reviewGroups/create"}
                                variant="contained"
                                color="primary"
                                startIcon={<AddCircleIcon />}
                            >
                                Create New Group
                            </Button>
                        </Grid>
                    </Grid>
                    <Divider sx={{mt: 3, mb: 3}} />
                </Grid>
                {/* Make a card for the 'all' users group */}
                <Grid size={{xs: 12, sm: 4}}>
                    <ReviewGroupCard
                        group={{...ALL_REVIEW_GROUP, countUsers: totalUserCount, countCandidates: totalCandidateCount}}
                    />
                </Grid>
                {/* Send review groups to ReviewGroupList,
                    which handles copy, delete, etc.   */}
                <ReviewGroupList reviewGroups={reviewGroups} />
            </Grid>
        </Container>
    )
}