import React, {useContext} from "react";
import {Outlet} from "react-router-dom";
import {Unauthorized} from "./Unauthorized";
import {UserContext} from "../../context";
import {UserRole} from "../../data-types";

export const ViewerRouter = () => {
    const {user} = useContext(UserContext);

    const isUserViewer = (role: UserRole) => {
        return role === "admin" || role === "p_admin" || role === "ps_admin" || role === "view";
    }
    return (user && isUserViewer(user.role)) ? <Outlet /> : <Unauthorized />
}