import React from "react";
import {Grid2 as Grid, Typography, Paper} from "@mui/material";
import {ProxyReviewStatsProps} from "../../data-types";

export function ProxyReviewStats({proxyPercent, proxyReviewCount, proxyReviewAverage}: ProxyReviewStatsProps) {

    const average = parseFloat(proxyReviewAverage);

    return (
        <Grid container justifyContent="space-evenly" alignItems="center" sx={{p: 3 ,textAlign: "center"}}>
            <Grid size={{xs: 12, sm: 4}}>
                <Paper elevation={1} sx={{p: 2, m: 2}}>
                    <Typography variant="h5">
                        {`${proxyPercent} %`}
                    </Typography>
                    <Typography variant="subtitle2">
                        Proxy %
                    </Typography>
                </Paper>
            </Grid>
            <Grid size={{xs: 12, sm: 4}}>
                <Paper elevation={1} sx={{p: 2, m: 2}}>
                    <Typography variant="h5">
                        {proxyReviewCount}
                    </Typography>
                    <Typography variant="subtitle2">
                        Proxy Reviews
                    </Typography>
                </Paper>
            </Grid>
            <Grid size={{xs: 12, sm: 4}}>
                <Paper elevation={1} sx={{p: 2, m: 2}}>
                    <Typography variant="h5">
                        {isNaN(average) ? "0.0" : average.toFixed(2)}
                    </Typography>
                    <Typography variant="subtitle2">
                        Avg. Score
                    </Typography>
                </Paper>
            </Grid>
        </Grid>
    )
}