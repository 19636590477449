import React, {PropsWithChildren} from "react";
import {Box, Button, Grid2 as Grid, Modal, Typography, SxProps, useMediaQuery} from "@mui/material";
import {VideoPlayer} from "../video-player";

interface TutorialModalProps {
    title: string;
    video: string;
    open: boolean;
    setOpen: (open: boolean) => void;
}

export function TutorialModal({title, video, open, setOpen, children}: PropsWithChildren<TutorialModalProps>) {

    const dangerZone = useMediaQuery("(min-width:450px) and (max-width:600px)");
    const style: SxProps = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {xs: "100%", sm: "100%", md: "80%", lg: "60%"},
        bgcolor: 'background.paper',
        border: '2px solid #000',
        height: {xs: 600, sm: 450, md: 450, lg: 480},
        overflowY: "auto",
        boxShadow: 24,
        p: 3,
    };

    const videoOptions = {
        autoplay: false,
        controls: true,
        userActions: {hotkeys: true},
        fill: true,
        responsive: true,
        aspectRatio: "16:9",
        sources: [{src: video, type: "VIDEO/MP4"}]
    }

    return (
        <Modal
            open={open}
        >
            <Box sx={style} component="div">
                <Grid container spacing={2} sx={{mb: 3}} >
                    <Grid size={{xs: 12}}>
                        <Typography variant="h5" sx={{pl: 3}}>
                            {title}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} justifyContent="center">
                    <Grid size={{xs: dangerZone ? 6 : 9, sm: 6}} sx={{mb: 5}}>
                        <VideoPlayer
                            options={videoOptions}
                            height={120}
                        />
                    </Grid>
                    <Grid size={{xs: 12, sm: 6}} sx={{pl: {xs: 0, sm: 2, md: 3}}}>
                        {children}
                        <Box sx={{textAlign: "center", mt: 3}} component="div">
                            <Button
                                color={"secondary"}
                                variant="contained"
                                onClick={() => setOpen(false)}
                            >
                                Close
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}
