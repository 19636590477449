import React from "react";
import {Typography, Box, Button} from "@mui/material";
import {Link} from "react-router-dom";

export const Unauthorized = () => {

        return (
            <Box component="div"
                sx={{
                    height: "50%",
                    width: "100%",
                    zIndex: 3,
                    backgroundColor: "#202020",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "absolute"
                }}
            >
                <Typography variant="h3" sx={{fontWeight: 'bold', textAlign: 'center'}}>
                    403: Access Forbidden.
                </Typography>
                <Typography variant="subtitle1" sx={{textAlign: 'center'}}>
                    You don't have the privileges (administrator or reviewer) to access this page.
                </Typography>
                <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    component={Link}
                    to={"/"}
                    sx={{mt: 5}}
                >
                    Return to Home
                </Button>
            </Box>
        )
}