import React, {useState, useEffect, useCallback} from "react";
import Resizer from "react-image-file-resizer";
import {DataAccess} from "../../util";

export const useAvatarUpload = (avatarCreateUrl: string, avatarType: string, ) => {
    const [avatarUrl, setAvatarUrl] = useState<string>("");

    useEffect(() => {
        return () => {
            URL.revokeObjectURL(avatarUrl);
        }
    }, [avatarUrl]);

    const resizeFile = (file: File): Promise<File> =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                150, // maxWidth
                150, // maxHeight
                "JPEG",
                100, // no compression
                0, // no rotation
                (resizedFile) => {
                    resolve(resizedFile as File); // as File required for Typescript
                },
                "file", // give us a file to create url on
                150, // minWidth
                150 // maxWidth
            )
        });

    const handleAvatarChange = useCallback(async (e: React.ChangeEvent<HTMLInputElement>)=> {
        let avatarImage: File;
        if (e.target.files) {
            avatarImage = await resizeFile(e.target.files[0]);
        } else {
            return;
        }

        try {
            const formData = new FormData();
            formData.append("file", avatarImage);
            formData.append("size", avatarImage.size.toString());
            formData.append("type", avatarType);
            await DataAccess.post(avatarCreateUrl, {data: formData});
            if (avatarUrl !== "") {
                URL.revokeObjectURL(avatarUrl);
            }
            const url = URL.createObjectURL(avatarImage);
            setAvatarUrl(url);
        } catch (e) {
            console.log(e);
        }
    }, [avatarType, avatarCreateUrl, avatarUrl]);



    return {
        avatarUrl: avatarUrl,
        handleAvatarChange: handleAvatarChange
    }
}