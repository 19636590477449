import React, {useState} from "react";
import {
    Box,
    Button,
    Container,
    Grid2 as Grid,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Tooltip,
    Tabs,
    Tab,
    Typography
} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {Video} from "../../data-types";
import {VideoAccessType} from "../../data-types";
import {VideoLibrary, LoadingSpinner, ErrorComponent, EditScriptBuilder} from "../../components";
import {usePagination, useScriptEdit} from "../../hooks";
import {toast} from "react-toastify";
import {formatError} from "../../util";
import AddIcon from "@mui/icons-material/Add";

export function AdminEditScript() {

    const navigate = useNavigate();
    const {scriptId} = useParams();
    const [videoAccessType, setVideoAccessType] = useState<VideoAccessType>("created");
    const {
        isPublic,
        makePublic,
        shareVideos,
        handleChange,
        toggleVideoSelection,
        editScript,
        requestStatus,
        toggleStepSkippable,
        editedSequence,
        removeByPosition,
        addOptionalStep,
        swapPosition
    } = useScriptEdit(scriptId);

    // myPrompts
    const {
        currentPageNumber: myPromptsPageNumber,
        pages: myPromptsPages,
        totalRecords: myPromptsTotalRecords,
        changePage: myPromptsChangePage,
        loading: myPromptsLoading
    } = usePagination<Video>("/api/videoPrompt/PAGE_NUMBER/getMyPrompts.json");

    // sharedPrompts
    const {
        currentPageNumber: sharedPromptsPageNumber,
        pages: sharedPromptsPages,
        totalRecords: sharedPromptsTotalRecords,
        changePage: sharedPromptsChangePage,
        loading: sharedPromptsLoading
    } = usePagination<Video>("/api/videoPrompt/PAGE_NUMBER/getUsableSharedPrompts.json");

    // publicPrompts
    const {
        currentPageNumber: publicPromptsPageNumber,
        pages: publicPromptsPages,
        totalRecords: publicPromptsTotalRecords,
        changePage: publicPromptsChangePage,
        loading: publicPromptsLoading
    } = usePagination<Video>("/api/videoPrompt/PAGE_NUMBER/getPublicPrompts.json");

    const submit = async () => {
        try {
            await editScript(+scriptId!, makePublic, shareVideos, editedSequence);
            toast.success("Script successfully updated");
            navigate(-1);
        } catch (e) {
            console.log(e);
            toast.error(formatError(e));
        }
    }

    const handleTabChange = (_: React.SyntheticEvent, newValue: string) => {
        setVideoAccessType(newValue as VideoAccessType);
    }

    const isSelected = (video: Video): boolean => {
        return editedSequence.some(c => c.newPosition !== null && c.videoId === video.videoId);
    }

    if (requestStatus === "loading") {
        return <LoadingSpinner />;
    } else if (requestStatus === "error") {
        return <ErrorComponent />;
    } else {
        return (
            <Container sx={{mb: 10}}>
                <Grid container spacing={2} alignItems="center">
                    <Grid size={{xs: 12, sm: 12, md: 9}}>
                        <Typography variant="h4">
                            Edit Script
                        </Typography>
                        <EditScriptBuilder
                            sequence={editedSequence}
                            removeByPosition={removeByPosition}
                            swapPosition={swapPosition}
                            toggleStepSkippable={toggleStepSkippable}
                        />
                        <Box sx={{textAlign: "center", my: 2}} component="div">
                            <Button
                                onClick={() => addOptionalStep()}
                                variant="contained"
                                color="primary"
                                startIcon={<AddIcon />}
                            >
                                Add Optional Prompt
                            </Button>
                        </Box>
                    </Grid>
                    <Grid size={{xs: 12, sm: 3}} order={{xs: 3, sm: 2}}>
                        <FormGroup>
                            {isPublic ?
                                <Tooltip
                                    title="This will automatically update all video prompts used in this Script to be public."
                                >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={makePublic}
                                                onChange={handleChange}
                                                name="makePublic"
                                            />
                                        }
                                        label="Make all videos public?"
                                    />

                                </Tooltip> :
                                <Tooltip
                                    title="This will automatically share all video prompts to the users with access to this Script."
                                >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={shareVideos}
                                                onChange={handleChange}
                                                name="shareVideos"
                                            />
                                        }
                                        label="Share all videos?"
                                    />

                                </Tooltip>
                            }
                        </FormGroup>
                        <Box component="div" sx={{textAlign: "center", mt: 5}}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={submit}
                            >
                                Update Script
                            </Button>
                        </Box>
                    </Grid>
                    <Grid size={{xs: 12}} order={{xs: 2, sm: 3}}>
                        <Box component="div"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column"
                            }}
                        >
                            <Tabs
                                value={videoAccessType}
                                onChange={handleTabChange}
                                sx={{mb: 5}}
                                centered
                            >
                                <Tab value="created" label="My Videos" />
                                <Tab value="shared" label="Shared With Me" />
                                <Tab value="public" label="Public Videos" />
                            </Tabs>
                        </Box>
                        {videoAccessType === "created" ?
                            <VideoLibrary
                                videosPerPage={6}
                                totalVideos={myPromptsTotalRecords}
                                pageNumber={myPromptsPageNumber}
                                videos={myPromptsPages[myPromptsPageNumber]}
                                loading={myPromptsLoading}
                                changePage={myPromptsChangePage}
                                toggleSelection={toggleVideoSelection}
                                isSelected={isSelected}
                            /> : null
                        }
                        {videoAccessType === "shared" ?
                            <VideoLibrary
                                videosPerPage={6}
                                totalVideos={sharedPromptsTotalRecords}
                                pageNumber={sharedPromptsPageNumber}
                                videos={sharedPromptsPages[sharedPromptsPageNumber]}
                                loading={sharedPromptsLoading}
                                changePage={sharedPromptsChangePage}
                                toggleSelection={toggleVideoSelection}
                                isSelected={isSelected}
                            /> : null
                        }
                        {videoAccessType === "public" ?
                            <VideoLibrary
                                videosPerPage={6}
                                totalVideos={publicPromptsTotalRecords}
                                pageNumber={publicPromptsPageNumber}
                                videos={publicPromptsPages[publicPromptsPageNumber]}
                                loading={publicPromptsLoading}
                                changePage={publicPromptsChangePage}
                                toggleSelection={toggleVideoSelection}
                                isSelected={isSelected}
                            /> : null
                        }
                    </Grid>
                </Grid>
            </Container>
        )
    }
}
