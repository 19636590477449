import * as React from "react";
import { SVGProps } from "react";

const SvgSilver1 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={61.92}
    height={61.92}
    xmlSpace="preserve"
    {...props}
  >
    <path
      fill="silver"
      d="m30.96 14.409 5.377 10.898 12.025 1.745-8.7 8.482 2.054 11.977-10.756-5.657-10.756 5.657 2.054-11.977-8.7-8.482 12.025-1.745z"
    />
  </svg>
);

export default SvgSilver1;
