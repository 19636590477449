import React from "react";
import {Modal, Box, Grid2 as Grid, Typography, Divider, Paper, Button} from "@mui/material";
import {OptionalScriptStepConfig} from "../../data-types";
import {InstitutionSelectCard} from "./InstitutionSelectCard";

interface OptionalStepCompetitionModalProps {
    open: boolean;
    confirmAction: () => void;
    optionalScriptSteps: OptionalScriptStepConfig[];
    handleInstitutionSelect: (id: number | null) => void;
    selected: number | null;
    handleCancel: () => void;
}

export function OptionalStepCompetitionModal({open, confirmAction, optionalScriptSteps, handleInstitutionSelect, selected, handleCancel}: OptionalStepCompetitionModalProps) {

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {xs: "100%", sm: "100%", md: "50%"},
        maxHeight: 500,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflowY: 'auto'
    }

    // reduce list of optional script steps to list of institutions
    const institutions = (optionalScriptSteps.length) > 0 ? optionalScriptSteps[0].institutions : [];

    // default isSkippable
    const isSkippable = (optionalScriptSteps.length) > 0 ? optionalScriptSteps[0].isSkippable : true;


    return (
        <Modal
            open={open}
        >
            <Box sx={style} component="div">
                <Grid container>
                    <Grid size={{xs: 12}}>
                        <Typography variant="h6">
                            Institution Selection
                        </Typography>
                        <Divider sx={{my: 3}} />
                    </Grid>
                    <Grid size={{xs: 12}}>
                        <Paper elevation={1} sx={{p: 2, mb: 2}}>
                            <Typography variant="subtitle2">
                                Please select an institution from the list below.  These institutions have provided video prompts for you to respond to.
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid container size={{xs: 12}} spacing={1}>
                        {institutions.map((c, i) =>
                            <Grid size={{xs: 12, sm: 4}} key={i}>
                                <InstitutionSelectCard
                                    institutionName={c.institutionName}
                                    institutionAvatar={null}
                                    institutionId={c.institutionId}
                                    handleSelect={handleInstitutionSelect}
                                    selected={selected === c.institutionId}
                                />
                            </Grid>
                        )}
                        {isSkippable ?
                            <Grid size={{xs: 12, sm: 4}}>
                                <InstitutionSelectCard
                                    institutionName="Skip All"
                                    institutionAvatar={null}
                                    institutionId={null}
                                    handleSelect={handleInstitutionSelect}
                                    selected={selected === null}
                                />
                            </Grid> :
                            null
                        }
                    </Grid>
                    <Grid container size={{xs: 12}} sx={{mt: 5}}>
                        <Grid size={{xs: 12, sm: 6}} sx={{textAlign: "center"}}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={confirmAction}
                                disabled={Number.isNaN(selected)}
                            >
                                Submit
                            </Button>
                        </Grid>
                        <Grid size={{xs: 12, sm: 6}} sx={{textAlign: "center"}}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}