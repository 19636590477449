import React from "react";
import {Box, LinearProgress} from "@mui/material";
import FocusRingLogo from "../../img/focus_ring_logo.png";

export const LoadingScreen = () => {

        return (
            <Box component="div"
                sx={{
                    height: "100%",
                    width: "100%",
                    zIndex: 3,
                    backgroundColor: "#202020",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "absolute"
                }}
            >
                <Box
                    component="img"
                    sx={{
                        width:190
                    }}
                    alt="FocusRing"
                    src={FocusRingLogo}
                />
                <Box component="div" sx={{width: 300, mt: 5}}>
                    <LinearProgress color="primary" sx={{height: 8}} />
                </Box>
            </Box>
        )
}