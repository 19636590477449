import React, {useContext} from "react";
import {Grid2 as Grid, Container, Card, CardContent, CardActionArea, Typography, CardMedia} from "@mui/material";
import {Link} from "react-router-dom";
import people_collaborating from "../../img/people-collaborating-remotely.png";
import video_conferencing from "../../img/video-conferencing.png";
import virtual_office from "../../img/virtual-office-girl.png";
import contest from "../../img/contest.png";
import {UserContext} from "../../context";

export function AdminDashboard() {

    const {user} = useContext(UserContext);

    return (
        <Container sx={{mb: 10}}>
            <Typography variant="h4" sx={{mb: 8}}>
                Admin Dashboard
            </Typography>
            <Grid container spacing={4} alignItems="stretch">
                <Grid size={{xs: 12, sm: 6, md: 4}}>
                    <Card sx={{height: "100%"}}>
                        <CardActionArea component={Link} to={'/admin/events'}>
                            <CardMedia
                                sx={{height: 300}}
                                image={contest}
                            />
                            <CardContent>
                                <Typography variant="h4">
                                    Events
                                </Typography>
                                <Typography variant="body1">
                                    Review, edit, and create new Events.
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid size={{xs: 12, sm: 6, md: 4}}>
                    <Card sx={{height: "100%"}}>
                        <CardActionArea component={Link} to={'/admin/videoPrompts'}>
                            <CardMedia
                                sx={{height: 300}}
                                image={video_conferencing}
                            />
                            <CardContent>
                                <Typography variant="h4">
                                    Video Prompts
                                </Typography>
                                <Typography variant="body1">
                                    Record Video Prompts to use in Response Scripts.
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid size={{xs: 12, sm: 6, md: 4}}>
                    <Card sx={{height: "100%"}}>
                        <CardActionArea component={Link} to={'/admin/scripts'}>
                            <CardMedia
                                sx={{height: 300}}
                                image={virtual_office}
                            />
                            <CardContent>
                                <Typography variant="h4">
                                    Response Scripts
                                </Typography>
                                <Typography variant="body1">
                                    Build Response Scripts from Prompts in your Video Prompt Library.
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                {user && user.role === "admin" ?
                    <Grid size={{xs: 12, sm: 6, md: 4}}>
                        <Card sx={{height: "100%"}}>
                            <CardActionArea component={Link} to={'/admin/reviewGroups'}>
                                <CardMedia
                                    sx={{height: 300}}
                                    image={people_collaborating}
                                />
                                <CardContent>
                                    <Typography variant="h4">
                                        Review Groups
                                    </Typography>
                                    <Typography variant="body1">
                                        Create groups and invite users to participate as a 3rd party reviewer.
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid> :
                    null
                }
                {user && user.role === "admin" ?
                    <Grid size={{xs: 12, sm: 6, md: 4}}>
                        <Card sx={{height: "100%"}}>
                            <CardActionArea component={Link} to={'/admin/users'}>
                                <CardMedia
                                    sx={{height: 300}}
                                    image={people_collaborating}
                                />
                                <CardContent>
                                    <Typography variant="h4">
                                        User Permissions
                                    </Typography>
                                    <Typography variant="body1">
                                        Elevate Users to Admin Role
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid> :
                    null
                }
            </Grid>
        </Container>
    )
}
