import React from "react";
import {Card, Tooltip, IconButton, CardActions, CardHeader, CardContent, Avatar, Typography} from "@mui/material";
import {ReviewGroupCardProps} from "../../data-types";
import {Link} from "react-router-dom";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export function ReviewGroupCard({group, handleCopyClick, handleDeleteClick}: ReviewGroupCardProps) {

    const detailPageLink = isNaN(group.groupId) ? `/admin/reviewGroups/detail/` : `/admin/reviewGroups/detail/${group.groupId}`;
    return (
        <Card>
            <CardHeader
                avatar={
                    <Avatar
                        src={group.groupAvatar ? `data:image/jpeg;base64,${group.groupAvatar}` : ""}
                        imgProps={{alt: "group avatar"}}
                    />
                }
                title={group.groupName}
            />
            <CardContent>
                <Typography variant="body1">
                    {`Users:  ${group.countUsers}`}
                </Typography>
                <Typography variant="body1">
                    {`Pending:  ${group.countCandidates}`}
                </Typography>
            </CardContent>
            <CardActions sx={{display: "flex", justifyContent: "center"}}>
                <Tooltip title="View Details">
                    <IconButton
                        size="large"
                        component={Link}
                        to={detailPageLink}
                        rel="noopener noreferrer"
                    >
                        <OpenInNewIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Copy Group">
                    <span> {/* span so mui doesn't complain when button is disabled */}
                        <IconButton
                            size="large"
                            onClick={() => handleCopyClick?.(group)}
                            disabled={!handleCopyClick}
                        >
                            <ContentCopyIcon />
                        </IconButton>
                    </span>
                </Tooltip>
                <Tooltip title="Delete Group">
                    <span>
                        <IconButton
                            size="large"
                            onClick={() => handleDeleteClick?.(group)}
                            disabled={!handleDeleteClick}
                        >
                            <DeleteForeverIcon />
                        </IconButton>
                    </span>
                </Tooltip>
            </CardActions>
        </Card>
    )
}