import React, {useContext, useState} from "react";
import {Button, Container, Grid2 as Grid, List, ListItem, ListItemText, Typography, IconButton, Tooltip} from "@mui/material";
import {useParams} from "react-router-dom";
import {DataFormatting, formatError} from "../../util";
import {LoadingSpinner, VideoPlayer, EditTranscript, ShareAccessModal} from "../../components";
import {Link} from "react-router-dom";
import {parseISO} from "date-fns";
import {useVideoDetail, useShareAccess, useRevokeShare} from "../../hooks";
import {toast} from "react-toastify";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {UserContext} from "../../context";
import PeopleIcon from '@mui/icons-material/People';

export function AdminVideoPromptDetail() {

    const {user} = useContext(UserContext);
    const {videoId} = useParams();
    const {
        video: videoPrompt,
        videoOptions,
        requestStatus,
        updateLocalTranscript
    } = useVideoDetail(videoId, true);
    const {revokeShareVideoPrompt} = useRevokeShare();
    const [detailsToggle, setDetailsToggle] = useState<boolean>(true);
    const [shareAccessModalOpen, setShareAccessModalOpen] = useState<boolean>(false);
    const {sharedUserNodes, getSharedUsers, removeSharedUser} = useShareAccess("VIDEO_PROMPT", videoId);

    const handleShareAccessModalOpen = () => {
        getSharedUsers()
            .then(_ => {
                setShareAccessModalOpen(true);
            })
            .catch(e => {
                console.log(e);
                toast.error("There was a problem with this request");
            })
    }

    const handleShareAccessModalClose = () => {
        setShareAccessModalOpen(false);
    }

    const handleShareRevoke = (ids: number[]) => {
        const video = Number.parseInt(videoId as string);
        Promise.all(ids.map(c => revokeShareVideoPrompt(c, video)))
            .then(_ => {
                setShareAccessModalOpen(false);
                removeSharedUser(ids);
                toast.success("Share access revoked for all selected users.");
            })
            .catch(e => {
                console.log(e);
                toast.error(formatError(e));
            })
    }

    if (requestStatus === "loading") {
        return <LoadingSpinner />
    } else if (requestStatus === "error" || videoPrompt === null) {
        return <Container />
    } else {

        const canEditTranscript = !!user && (user.username === videoPrompt.createdBy);
        const canSeeShareAccess = !!user && user.role !== "view";

        return (
            <Container sx={{mb: 10}}>
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                    <Grid size={{xs: 12, sm: 12, md: 6}}>
                        <Button
                            component={Link}
                            to={"/admin/videoPrompts"}
                            variant="outlined"
                            size="small"
                            color="info"
                            sx={{ml: 3, mb: 3}}
                            startIcon={<KeyboardBackspaceIcon />}
                        >
                            Back to List
                        </Button>
                        <VideoPlayer
                            options={videoOptions}
                            height={400}
                        />
                    </Grid>
                    <Grid container size={{xs: 12, sm: 12, md: 6}}>
                        <Grid container size={{xs: 12}} justifyContent="flex-end">
                            <Grid size={{xs: 6}}>
                                <Typography variant="h4" sx={{mb: 3}}>
                                    {detailsToggle ? "Prompt Details" : "Edit Transcript"}
                                </Typography>
                            </Grid>
                            <Grid size={{xs: 6}} sx={{textAlign: "right"}}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={!canEditTranscript}
                                    onClick={() => setDetailsToggle(prev => !prev)}
                                >
                                    {detailsToggle ? "Edit Transcript" : "Back to Details"}
                                </Button>
                            </Grid>

                        </Grid>
                        <Grid size={{xs: 12}}>
                            {detailsToggle ?
                                <List
                                    sx={{
                                        width: "100%",
                                        bgcolor: "background.paper"
                                    }}
                                >
                                    <ListItem
                                        secondaryAction={
                                            <Tooltip title="See who has been shared this video.">
                                                <IconButton
                                                    onClick={handleShareAccessModalOpen}
                                                    disabled={!canSeeShareAccess}
                                                >
                                                    <PeopleIcon />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                    >
                                        <ListItemText primary={`Name:  ${videoPrompt.name}`} />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary={`Size:  ${DataFormatting.secondsToTimeString(parseInt(videoPrompt.size))}`} />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary={`Create Date:  ${parseISO(videoPrompt.createDate + "Z").toLocaleString()}`} />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary={`Created By:  ${videoPrompt.createdBy}`} />
                                    </ListItem>
                                </List> :
                                <EditTranscript video={videoPrompt} updateLocalTranscript={updateLocalTranscript} />
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <ShareAccessModal
                    open={shareAccessModalOpen}
                    sharedUserNodes={sharedUserNodes}
                    onClose={handleShareAccessModalClose}
                    handleRevoke={handleShareRevoke}
                />
            </Container>
        )
    }
}
