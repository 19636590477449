import React from "react";
import {Container, Grid2 as Grid, Typography, Avatar, Box, Card, CardContent, CardActionArea, Button, Paper} from "@mui/material";
import {VideoPlayer, LoadingSpinner, ErrorComponent, NoDataCard} from "../../components";
import {DataFormatting} from "../../util";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import {useParams, useNavigate} from "react-router-dom";
import {useLeaders} from "./useLeaders";
import PersonIcon from '@mui/icons-material/Person';
import ReplyIcon from '@mui/icons-material/Reply';

export function Leaders() {
    const navigate = useNavigate();
    const {competitionId} = useParams();
    const {
        requestStatus,
        responseRequestStatus,
        eventName,
        leadersMeta,
        selectedLeaderIndex,
        selectedResponseIndex,
        handleLeaderChange,
        handleResponseChange,
        responseContent
    } = useLeaders(competitionId);

    const getColor = function(place: number) {
        let color;
        switch (place) {
            case 1:
                color = "#FFD700";
                break;
            case 2:
                color = "#c0c0c0";
                break;
            case 3:
                color = "#967444";
                break;
            default:
                color = "#FFFFFF";
        }
        return color;
    }

    const getOrder = function(place: number) {
        if (place === 1) {
            return {xs: 1, sm: 1, md: 2};
        } else if (place === 2) {
            return {xs: 2, sm: 2, md: 1};
        } else {
            return {xs: 3, sm: 3, md: 3}
        }
    }

    if (requestStatus === "loading") {
        return <LoadingSpinner />;
    } else if (requestStatus === "error") {
        return <ErrorComponent />;
    } else {
        return (
            <Container sx={{mb: 10}}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid>
                        <Typography variant="h4">
                            {`Leaders for '${eventName}'`}
                        </Typography>
                    </Grid>
                    <Grid>
                        <Button
                            onClick={() => navigate(-1)}
                            color="secondary"
                            variant="contained"
                            startIcon={<ReplyIcon />}
                        >
                            Go Back
                        </Button>
                    </Grid>
                </Grid>
                <Grid container sx={{mt: 1}}>
                    {/* Leaders */}
                    <Grid size={{xs: 12}} sx={{mb: 5}}>
                        {leadersMeta ?
                            <Grid container spacing={1} alignItems="center" justifyContent="center">
                                {leadersMeta.map((c, i) =>
                                    <Grid size={{xs: 12, sm: 4}} key={c.user.userId} order={getOrder(i + 1)}>
                                        <Card
                                            sx={(i === selectedLeaderIndex) ? {border: 2, borderColor: '#ffd802', mt: 2} : {mt: 2, border: "2px solid rgba(0, 0, 0, 0)"}}
                                        >
                                            <CardActionArea
                                                sx={(i === 0) ? {py: 2} : {}}
                                                onClick={() => handleLeaderChange(i)}
                                            >
                                                <CardContent>
                                                    <Box component="div" sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: -5}}>
                                                        <EmojiEventsIcon sx={{mx: 1, color: getColor(i + 1)}} fontSize="large" />
                                                        <Typography variant="h6" component="h2">
                                                            {DataFormatting.intToOrdinal(i + 1)} Place
                                                        </Typography>
                                                    </Box>
                                                    <Box component="div" sx={{display: 'flex', flexDirection: "column", alignItems: 'center', justifyContent: 'center', mt: 5, mb: 3}}>
                                                        <Avatar
                                                            src={c.user.avatar ? `data:image/jpeg;base64,${c.user.avatar}` : ""}
                                                            sx={{width: 56, height: 56, mb: 2}}
                                                            imgProps={{alt: c.user.username+"'s avatar"}}
                                                        >
                                                            <PersonIcon titleAccess={`${c.user.username}'s avatar`} sx={{width: 42, height: 42}}/>
                                                        </Avatar>
                                                        <Box component="div" sx={{display: 'inline-flex', alignItems: "center"}}>
                                                            <Typography variant="h6" component="h2">
                                                                {c.user.username}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                )}
                            </Grid> :
                            <NoDataCard message={"Leader responses are currently unavailable."} />
                        }
                    </Grid>
                    {/* Round Select */}
                    {/* only shown if more than one round */}
                    <Grid container sx={{mt: 1}} size={{xs: 12}}>
                        {(!Number.isNaN(selectedLeaderIndex)) ?
                            <Grid container spacing={4} alignItems="center">
                                <Grid>
                                    <Typography variant="subtitle2">
                                        Responses for {leadersMeta[selectedLeaderIndex].user.username}
                                    </Typography>
                                </Grid>
                                {leadersMeta[selectedLeaderIndex].data.map((c, i) =>
                                    <Grid key={c.responseId}>
                                        <Button
                                            variant={selectedResponseIndex === c.responseId ? "contained" : "outlined"}
                                            color="secondary"
                                            size="small"
                                            onClick={() => handleResponseChange(c.responseId)}
                                        >
                                            {i + 1}
                                        </Button>
                                    </Grid>
                                )}
                            </Grid> :
                            null
                        }
                    </Grid>
                    <Grid container sx={{mt: 10}} size={{xs: 12}}>
                        {((!Number.isNaN(selectedResponseIndex)) && (responseRequestStatus === "complete")) ?
                            <Grid container size={{xs: 12}} spacing={2}>
                                <Grid size={{xs: 12, sm: 12, md: 6}}>
                                    <Paper elevation={2} sx={{p: 2}}>
                                        <Typography variant="h6" sx={{mb: 2}}>
                                            Video Prompt
                                        </Typography>
                                        <VideoPlayer
                                            options={(responseContent) ? responseContent.videoPrompt : null}
                                        />
                                    </Paper>
                                </Grid>
                                <Grid size={{xs: 12, sm: 12, md: 6}}>
                                    <Paper elevation={2} sx={{p: 2}}>
                                        <Typography variant="h6" sx={{mb: 2}}>
                                            Video Response
                                        </Typography>
                                        {responseContent?.videoResponse ?
                                            <VideoPlayer
                                                options={responseContent.videoResponse}
                                            /> :
                                            <Typography variant="subtitle2">
                                                This response is unavailable.
                                            </Typography>
                                        }
                                    </Paper>
                                </Grid>
                            </Grid> :
                            null
                        }
                    </Grid>
                </Grid>
            </Container>
        )
    }
}