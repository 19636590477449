import React, {ChangeEvent, useState} from "react";
import {Button, Container, Divider, Grid2 as Grid, Paper, Typography, TextField, MenuItem} from "@mui/material";
import {useChangeInstitution, useChangeRoles, useInstitutions} from "../../hooks";
import {toast} from "react-toastify";
import {formatError} from "../../util";
import {LoadingSpinner, ErrorComponent} from "../../components";

export function AdminUsers() {

    const [toInvite, setToInvite] = useState<string>("");
    const [adminLevel, setAdminLevel] = useState<number>(2);

    const {
        inviteAdmin
    } = useChangeRoles();
    const {
        institutionState,
        assignInstitution,
        handleInstitutionChange,
        handleAssignedEmailChange
    } = useChangeInstitution();

    const {
        requestStatus,
        institutions
    } = useInstitutions();

    const handleSubmit = () => {
        inviteAdmin(toInvite, adminLevel)
            .then(_ => {
                toast.success(`${toInvite} has had their permissions elevated.`);
                setToInvite("");
            })
            .catch(e => {
                toast.error(formatError(e));
            })
    }

    const handleSubmitInstitution = () => {
        assignInstitution(institutionState)
            .then(_ => {
                toast.success("User successfully assigned to institution.");
            })
            .catch(e => {
                toast.error(formatError(e));
            })
    }

    const handleRoleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;
        setAdminLevel(Number.parseInt(value));
    };

    if (requestStatus === "loading") {
        return <LoadingSpinner />;
    } else if (requestStatus === "error") {
        return <ErrorComponent />;
    } else {
        return (
            <Container sx={{mb: 10}}>
                <Grid container sx={{mb: 3}} spacing={2}>
                    <Grid size={{xs: 12}}>
                        <Typography variant="h4">
                            Admin User Configuration
                        </Typography>
                        <Divider sx={{my: 3}} />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid container size={{xs: 12, sm: 12, md: 6}}>
                        <Paper elevation={2} sx={{p: 2, width: "100%"}}>
                            <Grid size={{xs: 12}} sx={{mb: 2}}>
                                <Typography variant="h6">
                                    Make User Admin
                                </Typography>
                            </Grid>
                            <Grid size={{xs: 12}} sx={{mb: 2}}>
                                <Typography variant="subtitle2">
                                    Enter a user's email address to promote them to an admin if their account exists.
                                </Typography>
                            </Grid>
                            <Grid size={{xs: 12, sm: 9}} sx={{p: 2}}>
                                <TextField
                                    label="Email address"
                                    fullWidth
                                    value={toInvite}
                                    onChange={(e) => setToInvite(e.target.value)}
                                />
                                <TextField
                                    label="Admin Level"
                                    fullWidth
                                    select
                                    value={adminLevel}
                                    onChange={handleRoleChange}
                                    sx={{mt: 3}}
                                >
                                    <MenuItem value={2}>
                                        Viewer
                                    </MenuItem>
                                    <MenuItem value={5}>
                                        Prompt Admin
                                    </MenuItem>
                                    <MenuItem value={4}>
                                        Prompt + Script Admin
                                    </MenuItem>
                                    <MenuItem value={3}>
                                        Admin
                                    </MenuItem>
                                </TextField>
                            </Grid>
                            <Grid size={{xs: 12, sm: 3}} sx={{p: 2}}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{width: "100%"}}
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </Button>
                            </Grid>

                        </Paper>
                    </Grid>
                    <Grid container size={{xs: 12, sm: 12, md: 6}}>
                        <Paper elevation={2} sx={{p: 2, width: "100%"}}>
                            <Grid size={{xs: 12}} sx={{mb: 2}}>
                                <Typography variant="h6">
                                    Associate Admin with Institution
                                </Typography>
                            </Grid>
                            <Grid size={{xs: 12}} sx={{mb: 2}}>
                                <Typography variant="subtitle2">
                                    Enter an admin's email address to assign them to an institution if either exists.
                                </Typography>
                            </Grid>
                            <Grid size={{xs: 12, sm: 9}} sx={{p: 2}}>
                                <TextField
                                    label="Email address"
                                    fullWidth
                                    value={institutionState.email}
                                    onChange={handleAssignedEmailChange}
                                />
                                <TextField
                                    label="Insitution"
                                    fullWidth
                                    select
                                    value={institutionState.institutionId}
                                    onChange={handleInstitutionChange}
                                    sx={{mt: 3}}
                                >
                                    <MenuItem
                                        value={-1}
                                        disabled
                                    >
                                        Select Institution
                                    </MenuItem>
                                    {institutions.map((c, i) =>
                                        <MenuItem
                                            key={i}
                                            value={c.institutionId}
                                        >
                                            {c.institutionName}
                                        </MenuItem>
                                    )}
                                </TextField>
                            </Grid>
                            <Grid size={{xs: 12, sm: 3}} sx={{p: 2}}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{width: "100%"}}
                                    disabled={institutions.length === 0 || institutionState.institutionId === -1 || institutionState.email === ""}
                                    onClick={handleSubmitInstitution}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        )
    }
}