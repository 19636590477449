import React from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Box,
    Typography,
} from "@mui/material";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import {RankingsTableProps} from "../../data-types";
import {RankIcon} from "../../icons";

export function RankingsTable({ranks, showTrend}: RankingsTableProps) {
    const trendIcon = function(trend: number) {
        if (trend > 0) {
            return <TrendingUpIcon color="primary" />
        } else if (trend < 0) {
            return <TrendingDownIcon color="secondary" />
        } else {
            return <TrendingFlatIcon />
        }
    }

    return (
        <TableContainer>
            <Table sx={{ borderCollapse: 'separate', borderSpacing: "0px 6px", "& td": {border: 0, px: 2}, "& th": {border: 0, px: 2}}}>
                <TableHead>
                    <TableRow sx={{bgcolor: "#333333"}}>
                        <TableCell align="left" sx={{width: "25%"}}>Rank</TableCell>
                        <TableCell align="left" sx={{width: "40%"}}>Name</TableCell>
                        <TableCell align="right">
                            {showTrend ?
                                "Rank Change" :
                                ""
                            }
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {ranks.map((c, i) => (
                        <TableRow key={i} sx={(c.eliminated) ? {color: "#aaaaaa", bgcolor: "#262626"} : {bgcolor: "#333333", color: "#ffffff"} }>
                            <TableCell align="left" sx={{color: "inherit", fontSize: 24, width: "25%"}}>
                                <RankIcon rank={c.percentile} />
                            </TableCell>
                            <TableCell align="left" sx={{color: "inherit", width: "40%"}}>
                                <Box sx={{maxWidth: 130}} component="div">
                                    <Typography variant="body1" noWrap>
                                        {c.username}
                                    </Typography>
                                    <Typography variant="subtitle2" noWrap sx={{color: "rgb(255, 255, 255, 0.7)", fontSize: 12}}>
                                        {`${c.firstName} ${c.lastName}`}
                                    </Typography>
                                </Box>
                            </TableCell>
                            <TableCell align="right" >
                                {showTrend ?
                                    trendIcon(c.rankTrend) :
                                    null
                                }
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
