import {useState, useCallback, ChangeEvent} from "react";
import {UserProfileState} from "../../data-types";
import {DataAccess, DataValidation} from "../../util";

export const useProfileEdit = (user: UserProfileState) => {

    const [editedProfileState, setEditedProfileState] = useState<UserProfileState>(() => {
        let usResidentValue = user.usResident === null ? null : user.usResident;
        return {
            ...user,
            ageBracket: user.ageBracket,
            regionOrCountry: user.regionOrCountry,
            primaryLanguage: user.primaryLanguage,
            secondaryLanguage: user.secondaryLanguage,
            highSchoolZip: user.highSchoolZip || null,
            mailingZip: user.mailingZip || null,
            usResident: usResidentValue
        }
    });
    const [isModified, setIsModified] = useState<boolean>(false);

    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        let {name, value}: {name: string, value: string | number | null | boolean} = e.target;

        if (name === "ageBracket") {
            let parsed = parseInt(value);
            value = (parsed === -1) ? null : parsed;
        } else if (name === "usResident") {
            value = !!value;
        } else {
            value = (value === "Not Specified") ? null : value;
        }

        setEditedProfileState(prev => {
            return {...prev, [name]: value};
        });

        setIsModified(true);

    }, []);

    const submitProfileEdits = useCallback(async () => {

        // SCI-219 validate only US zips
        const validate = async () => {
            if (editedProfileState.regionOrCountry === "US") {
                if (editedProfileState.highSchoolZip !== null && !DataValidation.isValidZipCode(editedProfileState.highSchoolZip)) {
                    throw new Error("Invalid High School Zip Code.  Zip Code must be in form XXXXX or XXXXX-XXXX");
                }
                if (editedProfileState.mailingZip !== null && !DataValidation.isValidZipCode(editedProfileState.mailingZip)) {
                    throw new Error("Invalid Mailing Zip Code.  Zip Code must be in form XXXXX or XXXXX-XXXX");
                }
            }
        }

        let data = {
            ageBracket: editedProfileState.ageBracket,
            regionOrCountry: editedProfileState.regionOrCountry,
            primaryLanguage: editedProfileState.primaryLanguage,
            secondaryLanguage: editedProfileState.secondaryLanguage,
            highSchoolZip: editedProfileState.highSchoolZip || null,
            mailingZip: editedProfileState.mailingZip || null,
            usResident: editedProfileState.usResident
        };

        await validate();
        await DataAccess.post("/api/user/storeDemographicData.json", {data: data});
        setIsModified(false);

    }, [editedProfileState]);

    return {
        editedProfileState: editedProfileState,
        handleChange: handleChange,
        submitProfileEdits: submitProfileEdits,
        isModified: isModified
    }
}