import {useState, useEffect} from "react";
import {ReviewGroupDetail} from "../../data-types";
import {DataAccess} from "../../util";

export const useReviewGroupDetail = (reviewGroupId: string | undefined) => {

    const [reviewGroupDetail, setReviewGroupDetail] = useState<ReviewGroupDetail>({
        groupId: NaN,
        groupName: "",
        countUsers: NaN,
        countCandidates: NaN,
        groupAvatar: "",
        groupUsers: [],
        groupCandidates: []
    });
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const getDetail = async () => {
            const url = (reviewGroupId) ?
                `/api/reviewGroup/${reviewGroupId}/getDetail.json` :
                `/api/reviewGroup/getAll.json`;

            let r = await DataAccess.get(url);
            setReviewGroupDetail(r.reviewGroup);
            setLoading(false);
        }

        getDetail();
    }, [reviewGroupId]);

    return {
        loading: loading,
        reviewGroupDetail: reviewGroupDetail
    }
}