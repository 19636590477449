import {useState, useEffect, useCallback} from "react";
import {Competition, RequestStatus} from "../../data-types";
import {DataAccess} from "../../util";

export const useCompetitions = (admin: boolean = false) => {
    const [requestStatus, setRequestStatus] = useState<RequestStatus>("loading");
    const [competitions, setCompetitions] = useState<Competition[]>([]);

    useEffect(() => {

        const controller = new AbortController();
        const getCompetitions = async () => {
            let url = (admin) ? `/api/competition/listAdmin.json` : `/api/competition/listUser.json`;
            const response = await DataAccess.get(url, {signal: controller.signal});
            setCompetitions(response.competitions);
        }

        getCompetitions()
            .then(_ => {
                setRequestStatus("complete");
            })
            .catch(e => {
                setRequestStatus("error");
                console.log(e);
            });

        return () => {
            controller.abort();
        }

    }, [admin]);

    const removeCompetition = useCallback((id: number) => {
        setCompetitions(prev => prev.filter(c => c.competitionMeta?.competitionId !== id));
    }, []);

    return {
        requestStatus: requestStatus,
        competitions: competitions,
        removeCompetition: removeCompetition
    }
}