import React, {useContext} from 'react';
import {Grid2 as Grid, Typography, Container} from "@mui/material";
import {RankingSummary} from "./RankingSummary";
import {UserContext} from "../../context";
import {FindCompetitionsHomepage} from "./FindCompetitionsHomepage";
import {ActiveEvents} from "./ActiveEvents";
import {useMyEvents} from "../my-events/useMyEvents";

export function Home() {

    const {user} = useContext(UserContext);
    const {
        requestStatus,
        activeEvents,
        completedEvents
    } = useMyEvents();

    return (
        <Container sx={{mb: 10}}>
            <Grid container spacing={6}>
                <Grid size={{xs: 12, md: 8}}>
                    <Typography variant="h4">
                        Welcome back,
                        <Typography  variant="inherit" color="secondary" display="inline">
                            {user ? " " + user.username : ""}
                        </Typography>
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={4} sx={{mt: 4}}>
                <Grid size={{xs: 12, md: 6}}>
                    <FindCompetitionsHomepage />
                    <RankingSummary
                        competitions={completedEvents}
                        requestStatus={requestStatus}
                    />
                </Grid>
                <Grid size={{xs: 12, md: 6}}>
                    <ActiveEvents
                        activeEvents={activeEvents}
                        requestStatus={requestStatus}
                    />
                </Grid>
            </Grid>
        </Container>
    )
}
