import React from "react";
import {Modal, Grid2 as Grid, LinearProgress, Typography, Button} from "@mui/material";
import {ModalBox} from "../modal-box";
import {Link} from "react-router-dom";
import {RequestStatus} from "../../data-types";

interface VideoUploadingModalProps {
    open: boolean;
    handleClose: () => void;
    uploadStatus: RequestStatus;
    videoUploadProgress: number;
    transcriptStatus: RequestStatus;
    retryTranscript: () => void;
    link: string;
}

export function VideoUploadingModal({open, uploadStatus, videoUploadProgress, handleClose, transcriptStatus, link, retryTranscript}: VideoUploadingModalProps) {

    return (
        <Modal
            open={open}
        >
            <ModalBox>
                {(uploadStatus === "complete") ?
                    <Grid container spacing={2}>
                        <Grid size={{xs: 12}}>
                            <Typography variant="h6" sx={{mb: 3}}>
                                Video upload complete
                            </Typography>
                        </Grid>
                        {(transcriptStatus === "complete") ?
                            <>
                                <Grid size={{xs: 12}}>
                                    <Typography variant="subtitle2">
                                        Would you like to review this video's transcript?
                                    </Typography>
                                </Grid>
                                <Grid size={{xs: 12, sm: 6}}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        component={Link}
                                        to={link}
                                    >
                                        Review Transcript
                                    </Button>
                                </Grid>
                                <Grid size={{xs: 12, sm: 6}}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        fullWidth
                                        onClick={handleClose}
                                    >
                                        Close
                                    </Button>
                                </Grid>
                            </> :
                            (transcriptStatus === "loading") ?
                                <>
                                    <Grid size={{xs: 12}}>
                                        <Typography variant="subtitle2">
                                            Creating transcript...
                                        </Typography>
                                    </Grid>
                                    <Grid size={{xs: 12}}>
                                        <LinearProgress
                                            color="primary"
                                            variant="indeterminate"
                                        />
                                    </Grid>
                                </> :
                                <>
                                    <Grid size={{xs: 12}}>
                                        <Typography variant="subtitle2">
                                            An error occurred when creating the transcript.
                                        </Typography>
                                    </Grid>
                                    <Grid size={{xs: 12, sm: 6}}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={retryTranscript}
                                        >
                                            Retry Transcript
                                        </Button>
                                    </Grid>
                                    <Grid size={{xs: 12, sm: 6}}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            fullWidth
                                            onClick={handleClose}
                                        >
                                            Close
                                        </Button>
                                    </Grid>
                                </>
                        }
                    </Grid> :
                    (uploadStatus === "loading") ?
                        <Grid container spacing={2}>
                            <Grid size={{xs: 12}}>
                                <LinearProgress
                                    value={isNaN(videoUploadProgress) ? 0 : videoUploadProgress}
                                    variant={(isNaN(videoUploadProgress) || videoUploadProgress === 100) ? "indeterminate" : "determinate"}
                                    color="primary"
                                />
                            </Grid>
                            <Grid size={{xs: 12}}>
                                <Typography variant="subtitle2">
                                    {(isNaN(videoUploadProgress) || videoUploadProgress === 100) ? "Processing..." : `${videoUploadProgress}% Uploaded`}
                                </Typography>
                            </Grid>
                        </Grid> :
                        <Grid container spacing={2}>
                            <Grid size={{xs: 12}}>
                                <Typography variant="subtitle2">
                                    There was an error in submitting your video.
                                </Typography>
                            </Grid>
                            <Grid size={{xs: 12, sm: 6}}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    fullWidth
                                    onClick={handleClose}
                                >
                                    Close
                                </Button>
                            </Grid>
                        </Grid>
                }
            </ModalBox>
        </Modal>
    )
}