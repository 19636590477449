import {useCallback} from "react";
import {DataAccess} from "../../util";

export const useDeactivateAccount = () => {

    const handleDeactivation = useCallback(async () => {
        await DataAccess.post("/api/user/archive.json", {data: null})
        await DataAccess.postFormString("/logout", "");
    }, []);

    return {
        handleDeactivation: handleDeactivation
    }
}