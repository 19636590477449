import React from "react";
import {Grid2 as Grid, Typography, Button} from "@mui/material";
import {Promotion} from "../../data-types";
import {Link} from "react-router-dom";

interface PromotionBannerProps {
    promotion: Promotion;
}

export function PromotionBanner({promotion}: PromotionBannerProps) {

    return (
        <Grid container size={{xs: 12}} columnSpacing={2} rowSpacing={0} alignItems="center" sx={{px: 5, background: "#EEEEEE"}}>
            <Grid size={{xs: 8, sm: 9}}>
                {/*   Text   */}
                <Typography variant="subtitle2" sx={{color: "#222222", lineHeight: 1.25}}>
                    {promotion.description}
                </Typography>
                {promotion.promoCode ?
                    <Typography variant="subtitle2" sx={{color: "#222222", mt: 2}}>
                        Use Code:
                        <Typography variant="subtitle2" component="span" sx={{fontWeight: "bold"}}>
                            {`   ${promotion.promoCode}`}
                        </Typography>
                    </Typography> :
                    null
                }
                {/*   Button if image exists   */}
                {promotion.logo ?
                    <Button
                        variant="contained"
                        component={Link}
                        to={promotion.url}
                        sx={{mt: 3}}
                    >
                        Click Here
                    </Button> :
                    null
                }
            </Grid>
            <Grid size={{xs: 4, sm: 3}} sx={{textAlign: "center"}}>
                {/*   Image, if exists   */}
                {promotion.logo ?
                    <img
                        src={promotion.logo}
                        alt="promotion"
                        style={{width: "100%", height: "auto"}}
                    /> :
                    <Button
                        variant="contained"
                        component={Link}
                        to={promotion.url}
                    >
                        Click Here
                    </Button>
                }
            </Grid>
        </Grid>
    )
}