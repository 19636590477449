import {useState, useEffect, useCallback} from "react";
import {RoundConfig, RequestStatus} from "../../data-types";
import {DataAccess} from "../../util";
import {parseISO} from "date-fns";

export const useRoundConfig = (roundId: string | undefined) => {

    const [roundConfig, setRoundConfig] = useState<RoundConfig>({
        rankingsReleaseDate: null,
        roundId: null
    });
    const [requestStatus, setRequestStatus] = useState<RequestStatus>("loading");

    useEffect(() => {

        const controller = new AbortController();
        const getConfig = async () => {
            if (roundId) {
                const {roundConfig} = await DataAccess.get(`/api/round/${roundId}/getRoundConfig.json`, {signal: controller.signal});
                setRoundConfig({
                    roundId: roundConfig.roundId,
                    rankingsReleaseDate: parseISO(roundConfig.rankingsReleaseDate + "Z")
                });
            }
        }

        getConfig()
            .then(_ => setRequestStatus("complete"))
            .catch(e => {
                console.log(e);
                setRequestStatus("error");
            });

        return () => {controller.abort()};

    }, [roundId]);

    const handleDateChange = useCallback((dateKey: string, dateValue: Date | null) => {
        if (dateValue) {
            dateValue.setSeconds(0);
            dateValue.setMilliseconds(0);
            setRoundConfig(prev => {
                return {
                    ...prev,
                    [dateKey]: dateValue
                }
            });
        }
    }, []);

    const submitRoundConfig = useCallback(async (roundConfig: RoundConfig) => {
        const data = {roundConfig: roundConfig};
        await DataAccess.put("/api/round/putRoundConfig.json", {data: data});
    }, []);

    return {
        requestStatus: requestStatus,
        roundConfig: roundConfig,
        submitRoundConfig: submitRoundConfig,
        handleDateChange: handleDateChange
    };
}