import React, {useState} from "react";
import {Grid2 as Grid, Typography, Button, TextField} from "@mui/material";
import {PasswordResetProps, PasswordResetState} from "../../data-types";
import {DataAccess, DataValidation, formatError} from "../../util";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";

export function PasswordReset({resetToken}: PasswordResetProps) {

    const [passwordState, setPasswordState] = useState<PasswordResetState>({
        newPassword: "",
        confirmPassword: ""
    });
    const [passwordChanged, setPasswordChanged] = useState<boolean>(false);

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        if (passwordState.newPassword !== passwordState.confirmPassword) {
            toast.error("Passwords do not match");
        } else if (!DataValidation.isValidPassword(passwordState.confirmPassword)) {
            toast.error("Password does not meet requirements.");
        } else {
            const data = {
                passwordReset: {
                    ...(resetToken && {passwordResetToken: resetToken})
                    , newPassword: passwordState.confirmPassword}};

            DataAccess.post("/api/user/resetPassword.json", {data: data})
                .then(_ => {
                    setPasswordChanged(true);
                })
                .catch(e => {
                    toast.error(formatError(e));
                })
        }
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setPasswordState(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    if (passwordChanged) {
        return (
            <Grid container spacing={2} sx={{mt: 3}}>
                <Grid size={{xs: 12}} sx={{textAlign: "center"}}>
                    <CheckCircleOutlineIcon color="primary" fontSize="large" />
                    <Typography variant="h6" sx={{mt: 5}}>
                        Success!  Your password has been changed.
                    </Typography>
                    {resetToken ?
                        <Button
                            component={Link}
                            to="/login"
                            sx={{mt: 5, mb: 5}}
                        >
                            Return to Login Page
                        </Button> :
                        null
                    }
                </Grid>
            </Grid>
        )
    } else {
        return (
            <Grid container spacing={2} justifyContent="space-around" alignItems="center">
                <Grid size={{xs: 12}}>
                    <Typography variant="h6">
                        Change Your Password
                    </Typography>
                </Grid>
                <Grid size={{xs: 12, sm: 12, md: 6}}>
                    <Typography variant="subtitle1">
                        Passwords must:
                    </Typography>
                    <Typography variant="subtitle1" sx={{pl: 2}}>
                        - contain at least one uppercase letter
                    </Typography>
                    <Typography variant="subtitle1" sx={{pl: 2}}>
                        - contain at least one lowercase letter
                    </Typography>
                    <Typography variant="subtitle1" sx={{pl: 2}}>
                        - contain at least one special character
                    </Typography>
                    <Typography variant="subtitle1" sx={{pl: 2}}>
                        - contain at least one number
                    </Typography>
                    <Typography variant="subtitle1" sx={{pl: 2}}>
                        - be at least 12 characters in length
                    </Typography>
                </Grid>
                <Grid size={{xs: 12, sm: 12, md: 6}} sx={{textAlign: "center"}}>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            required
                            label="Enter your new password"
                            type="password"
                            name="newPassword"
                            value={passwordState.newPassword}
                            slotProps={{
                                htmlInput: {size: 40}
                            }}
                            onChange={handleChange}
                            sx={{mb: 3}}
                        />
                        <TextField
                            required
                            label="Confirm your new password"
                            type="password"
                            name="confirmPassword"
                            value={passwordState.confirmPassword}
                            slotProps={{
                                htmlInput: {size: 40}
                            }}
                            onChange={handleChange}
                            sx={{mb: 3}}
                        />
                    </form>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={passwordState.newPassword === "" || passwordState.newPassword !== passwordState.confirmPassword}
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>
        )
    }

}