import React, {useCallback, useState} from "react";
import {
    Box,
    Button,
    Grid2 as Grid,
    List,
    ListItem,
    ListItemText,
    TextField,
    Typography,
    IconButton
} from "@mui/material";
import {useDropzone} from "react-dropzone";
import DownloadIcon from '@mui/icons-material/Download';
import {EmailEntryProps} from "../../data-types";
import {UrlConfig} from "../../util";
import CancelIcon from '@mui/icons-material/Cancel';
import {toast} from "react-toastify";

export function EmailEntry({emails, addEmails, removeEmail, disabled}: EmailEntryProps) {

    const [manualEmail, setManualEmail] = useState<string>("");
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const onDrop = useCallback((acceptedFiles: File[]) => {
        acceptedFiles[0].text()
            .then(input => {
                const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                let parsedEmails = input.split(/\r?\n/);
                let validEmails: string[] = parsedEmails
                    .map(c => c.trim())
                    .filter(v => emailRegex.test(v))
                    .filter(i => !emails.includes(i));
                addEmails(validEmails);
            })
            .catch(e => {
                toast.error(e);
            });
    }, [emails, addEmails]);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, accept: {"text/csv": [".csv", ".txt"]}, maxFiles: 1, disabled: disabled});

    const handleManualEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;
        setManualEmail(value);
    }

    const removeEmailManual = (email: string) => {
        removeEmail(email);
    }

    const addManualEmail = () => {
        // check if entered email is a valid email address
        if (emailRegex.test(manualEmail)) {
            addEmails([manualEmail]);
            setManualEmail("");
        } else {
            toast.error("This is not a valid email address.");
        }
    }

    return (
        <Grid container spacing={2}>
            {/* Add manually or drag and drop section */}
            <Grid size={{xs: 12, sm: 6}}>
                <Typography variant="body1" sx={{mb: 3}}>
                    1)  Enter email addresses manually
                </Typography>
                <TextField
                    label="Enter an email address"
                    name="invitation"
                    disabled={disabled}
                    fullWidth={true}
                    onChange={handleManualEmailChange}
                    value={manualEmail}
                    sx={{mb: 3}}
                />
                <Button
                    color="primary"
                    variant="contained"
                    onClick={addManualEmail}
                    disabled={manualEmail.length === 0}
                >
                    Add Email
                </Button>
                <Typography variant="body1" align="center" sx={{mb: 3}}>
                    - OR -
                </Typography>
                <Typography variant="body1" sx={{mb: 3}}>
                    2)  Upload emails via .csv or .txt with one email per line.
                </Typography>
                <Button
                    component="a"
                    href={UrlConfig.getResourceUrl() + "/sample_email_upload.csv"}
                    variant="outlined"
                    color="primary"
                    startIcon={<DownloadIcon />}
                    sx={{mb: 3}}
                    size="small"
                >
                    Sample
                </Button>
                <Box {...getRootProps()} sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '20px',
                    borderWidth: 2,
                    borderRadius: 2,
                    borderColor: '#eeeeee',
                    borderStyle: 'dashed',
                    backgroundColor: '#202020',
                    color: '#ffffff',
                    outline: 'none',
                    transition: 'border .24s ease-in-out'
                }}
                     component="div"
                >
                    <input {...getInputProps()} />
                    {
                        isDragActive ?
                            <p>Drop the files here</p> :
                            <p>Drag and drop some files here, or click to select files.</p>
                    }
                </Box>
            </Grid>
            {/* List of the emails to invite */}
            <Grid size={{xs: 12, sm: 6}}>
                <Typography variant="body1" sx={{mb: 2, ml: 2}}>
                    {`Emails  (${emails.length})`}
                </Typography>
                {emails.length === 0 ?
                    <Typography variant="body1" sx={{textAlign: "center"}}>
                        Enter emails to see them reflected here
                    </Typography> :
                    <List style={{maxHeight: '370px', overflow: 'auto'}}>
                        {emails.map((c, i) =>
                            <ListItem
                                key={i}
                                divider
                                secondaryAction={
                                    <IconButton
                                        edge="end"
                                        aria-label="delete"
                                        onClick={() => removeEmailManual(c)}
                                        disabled={disabled}
                                    >
                                        <CancelIcon />
                                    </IconButton>
                                }
                            >
                                <ListItemText>
                                    {c}
                                </ListItemText>
                            </ListItem>
                        )}
                    </List>
                }
            </Grid>
        </Grid>
    )
}