import React from "react";
import {Container, Grid2 as Grid, Typography} from "@mui/material";
import {useParams} from "react-router-dom";
import {useReviewsOfMe} from "./useReviewsOfMe";
import {LoadingSpinner, NoDataCard, ErrorComponent} from "../../components";
import {ReviewSection} from "./ReviewSection";

interface ReviewsProps {
    userId?: string;
}

export function Reviews({userId}: ReviewsProps) {

    const {competitionId} = useParams();
    const {
        reviews,
        requestStatus,
        competitionName
    } = useReviewsOfMe(competitionId, userId);

    if (requestStatus === "loading") {
        return <LoadingSpinner />;
    } else if (requestStatus === "error") {
        return <ErrorComponent />;
    } else {
        return (
            <Container sx={{mb: 10}}>
                <Grid size={{xs: 12}} sx={{my: 2}}>
                    <Typography variant="h4">
                        {competitionName}
                    </Typography>
                </Grid>
                <Grid container spacing={2}>
                    {reviews.map((c, i) => {
                        return (
                            <Grid size={{xs: 12}} key={i}>
                                <ReviewSection review={c} />
                            </Grid>
                        )
                    })}
                    {reviews.length === 0 ?
                        <Grid size={{xs: 12}}>
                            <NoDataCard hideHomeButton={true} message={"No reviews found."} />
                        </Grid> :
                        null
                    }
                </Grid>
            </Container>
        )
    }
}